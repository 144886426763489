import { Box, CircularProgress } from '@mui/material';

function DataLoader(props) {
  const { thickness, size } = props;
  return (
    <Box sx={{ display: 'flex', align: 'center' }}>
      <CircularProgress thickness={thickness} size={size} />
    </Box>
  );
}

export default DataLoader;
