import React, { useState } from 'react';

import { AppBar, Tab, Tabs, Typography } from '@mui/material';
import { Box } from '@mui/system';
import EducatorSignInPage from './EducatorSignInPage';
import EducatorSignUpPage from './EducatorSignUpPage';
import TabPanel from '../../../components/authentication/TabPanel';

function EducatorRegistrationTabs() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" color="transparent">
          <Typography sx={{ padding: 2, color: '#233c8d' }} align="center">
            Educator
          </Typography>
        </AppBar>
        <AppBar position="static" color="transparent">
          <Tabs
            value={value}
            onChange={(e, newval) => handleChange(e, newval)}
            centered
            textColor="primary"
            indicatorColor="primary"
            aria-label="Account forms"
          >
            <Tab
              label="SIGN IN"
              id="simple-tab-0"
              aria-controls="simple-tabpanel-0"
            />
            <Tab
              label="REGISTER"
              id="simple-tab-1"
              aria-controls="simple-tabpanel-1"
            />
          </Tabs>
        </AppBar>
      </Box>
      <TabPanel value={value} index={0}>
        <EducatorSignInPage changeView={handleChange} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <EducatorSignUpPage changeView={handleChange} />
      </TabPanel>
    </div>
  );
}

export default EducatorRegistrationTabs;
