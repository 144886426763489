import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  List,
  ListItem,
} from '@mui/material';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import DataArrayOutlinedIcon from '@mui/icons-material/DataArrayOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import DirectionsRunOutlinedIcon from '@mui/icons-material/DirectionsRunOutlined';
// import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import CastForEducationOutlinedIcon from '@mui/icons-material/CastForEducationOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import SmartDisplayOutlinedIcon from '@mui/icons-material/SmartDisplayOutlined';
import CardMembershipOutlinedIcon from '@mui/icons-material/CardMembershipOutlined';
import GradingOutlinedIcon from '@mui/icons-material/GradingOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import KeyboardOutlinedIcon from '@mui/icons-material/KeyboardOutlined';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';

function SideBarList() {
  const { isSignedIn, user } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  const onProfileClick = () => {
    navigate('/student/profile');
  };
  const onEducatoeProfileClick = () => {
    navigate('/educator/profile');
  };
  const onMyAdminDashboardClick = () => {
    navigate('/admin/dashboard');
  };
  const onMyInstitutionDashboardClick = () => {
    navigate('/institution/dashboard');
  };
  const onMyEducatorDashboardClick = () => {
    navigate('/educator/dashboard');
  };
  const onMyStudentDashboardClick = () => {
    navigate('/student/dashboard');
  };
  // const onSettingsClick = () => {
  //   console.log('settings click');
  // };

  // const onMyTestsClick = () => {
  //   console.log('tests click');
  // };
  const onFollowersClick = () => {
    navigate('/followers');
  };
  const onFollowingClick = () => {
    navigate('/following');
  };
  const onReviewsClick = () => {
    navigate('/reviews');
  };
  const onAdminStudentsClick = () => {
    navigate('/students');
  };
  const onAdminEducatorsClick = () => {
    navigate('/educators');
  };
  const onAdminInstitutionsClick = () => {
    navigate('/institutions');
  };
  const onAdminCoursesClick = () => {
    navigate('/courses');
  };
  const onEducatorCoursesClick = () => {
    navigate('/courses');
  };
  const onInstitutionCoursesClick = () => {
    navigate('/courses');
  };
  const onStudentCoursesClick = () => {
    navigate('/enrols/course');
  };

  const onAdminExamsClick = () => {
    navigate('/exams');
  };
  const onEducatorExamsClick = () => {
    navigate('/exams');
  };
  const onInstitutionExamsClick = () => {
    navigate('/exams');
  };
  const onStudentExamsClick = () => {
    navigate('/enrols/exam');
  };
  const onStudentPracticeTestsClick = () => {
    navigate('/practice/tests');
  };

  const onAdminTestsClick = () => {
    navigate('/tests');
  };
  const onEducatorTestsClick = () => {
    navigate('/tests');
  };
  const onInstitutionTestsClick = () => {
    navigate('/tests');
  };
  const onCategoriesClick = () => {
    navigate('/categories');
  };
  const onAdminVideosClick = () => {
    navigate('/videos');
  };
  const onAdminSubscriptionPlansClick = () => {
    navigate('/subscriptionplans');
  };
  const onGradesClick = () => {
    navigate('/grades');
  };
  const onPublicationsClick = () => {
    navigate('/publications');
  };
  const onAdminKeywordsClick = () => {
    navigate('/keywords');
  };

  const onLearningObjectivesClick = () => {
    navigate('/learning/objectives');
  };
  const onAdminCourseViolationsClick = () => {
    navigate('/course/violations');
  };
  const onAdminVideoViolationsClick = () => {
    navigate('/video/violations');
  };
  return (
    <>
      {isSignedIn && user.role === 'admin' ? (
        <>
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={onMyAdminDashboardClick}>
                <ListItemIcon>
                  <DashboardOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Dashboard </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={onAdminStudentsClick}>
                <ListItemIcon>
                  <PeopleAltOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Manage Students</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={onAdminEducatorsClick}>
                <ListItemIcon>
                  <CastForEducationOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Manage Educators</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={onAdminInstitutionsClick}>
                <ListItemIcon>
                  <SchoolOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Manage Institutions</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={onCategoriesClick}>
                <ListItemIcon>
                  <CategoryOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Manage Categories</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={onGradesClick}>
                <ListItemIcon>
                  <GradingOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Manage Grades</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={onPublicationsClick}>
                <ListItemIcon>
                  <MenuBookOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Manage Publications</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={onAdminCoursesClick}>
                <ListItemIcon>
                  <DataArrayOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Manage Courses </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={onAdminCourseViolationsClick}>
                <ListItemIcon>
                  <SmartDisplayOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Manage Course Violation</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={onAdminExamsClick}>
                <ListItemIcon>
                  <QuizOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Manage Exams </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={onAdminTestsClick}>
                <ListItemIcon>
                  <AccountTreeOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Manage Tests </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={onAdminVideosClick}>
                <ListItemIcon>
                  <SmartDisplayOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Manage Videos</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={onAdminVideoViolationsClick}>
                <ListItemIcon>
                  <SmartDisplayOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Manage Video Violations</ListItemText>
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton onClick={onAdminSubscriptionPlansClick}>
                <ListItemIcon>
                  <CardMembershipOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Manage Subscription Plans</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={onAdminKeywordsClick}>
                <ListItemIcon>
                  <KeyboardOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Manage Keywords</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={onLearningObjectivesClick}>
                <ListItemIcon>
                  <LocalLibraryOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Manage Learning Objectives</ListItemText>
              </ListItemButton>
            </ListItem>
          </List>

          <Divider />
          <List>
            {/* <ListItem disablePadding>
              <ListItemButton onClick={onProfileClick}>
                <ListItemIcon>
                  <AccountBoxOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Profile </ListItemText>
              </ListItemButton>
            </ListItem> */}
            {/* <ListItem disablePadding>
              <ListItemButton onClick={onSettingsClick}>
                <ListItemIcon>
                  <SettingsOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Settings </ListItemText>
              </ListItemButton>
            </ListItem> */}
          </List>
        </>
      ) : null}
      {isSignedIn && user.role === 'institution' ? (
        <>
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={onMyInstitutionDashboardClick}>
                <ListItemIcon>
                  <DashboardOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Dashboard </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={onAdminEducatorsClick}>
                <ListItemIcon>
                  <CastForEducationOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Manage Educators</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={onInstitutionCoursesClick}>
                <ListItemIcon>
                  <DataArrayOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Manage Courses </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={onInstitutionExamsClick}>
                <ListItemIcon>
                  <QuizOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Manage Exams </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={onInstitutionTestsClick}>
                <ListItemIcon>
                  <AccountTreeOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Manage Tests </ListItemText>
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={onProfileClick}>
                <ListItemIcon>
                  <AccountBoxOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Profile </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={onFollowingClick}>
                <ListItemIcon>
                  <DirectionsRunOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Following </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={onFollowersClick}>
                <ListItemIcon>
                  <PeopleAltOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Followers </ListItemText>
              </ListItemButton>
            </ListItem>
            {/* <ListItem disablePadding>
              <ListItemButton onClick={onReviewsClick}>
                <ListItemIcon>
                  <RateReviewOutlinedIcon />
                </ListItemIcon>
                <ListItemText>My Reviews </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={onSettingsClick}>
                <ListItemIcon>
                  <SettingsOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Settings </ListItemText>
              </ListItemButton>
            </ListItem> */}
          </List>
        </>
      ) : null}
      {isSignedIn && user.role === 'educator' ? (
        <>
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={onMyEducatorDashboardClick}>
                <ListItemIcon>
                  <DashboardOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Dashboard </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={onEducatorCoursesClick}>
                <ListItemIcon>
                  <DataArrayOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Manage Courses </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={onEducatorExamsClick}>
                <ListItemIcon>
                  <QuizOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Manage Exams </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={onEducatorTestsClick}>
                <ListItemIcon>
                  <AccountTreeOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Manage Tests </ListItemText>
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={onEducatoeProfileClick}>
                <ListItemIcon>
                  <AccountBoxOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Profile </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={onFollowingClick}>
                <ListItemIcon>
                  <DirectionsRunOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Following </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={onFollowersClick}>
                <ListItemIcon>
                  <PeopleAltOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Followers </ListItemText>
              </ListItemButton>
            </ListItem>
            {/* <ListItem disablePadding>
              <ListItemButton onClick={onReviewsClick}>
                <ListItemIcon>
                  <RateReviewOutlinedIcon />
                </ListItemIcon>
                <ListItemText>My Reviews </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={onSettingsClick}>
                <ListItemIcon>
                  <SettingsOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Settings </ListItemText>
              </ListItemButton>
            </ListItem> */}
          </List>
        </>
      ) : null}
      {isSignedIn && user.role === 'student' ? (
        <>
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={onMyStudentDashboardClick}>
                <ListItemIcon>
                  <DashboardOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Dashboard </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={onStudentCoursesClick}>
                <ListItemIcon>
                  <DataArrayOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Enroled Courses </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={onStudentExamsClick}>
                <ListItemIcon>
                  <QuizOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Enroled Exams </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={onStudentPracticeTestsClick}>
                <ListItemIcon>
                  <AccountTreeOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Practice Tests </ListItemText>
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={onProfileClick}>
                <ListItemIcon>
                  <AccountBoxOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Profile </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={onFollowingClick}>
                <ListItemIcon>
                  <DirectionsRunOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Following </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={onFollowersClick}>
                <ListItemIcon>
                  <PeopleAltOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Followers </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={onReviewsClick}>
                <ListItemIcon>
                  <RateReviewOutlinedIcon />
                </ListItemIcon>
                <ListItemText>My Reviews </ListItemText>
              </ListItemButton>
            </ListItem>
            {/* <ListItem disablePadding>
              <ListItemButton onClick={onSettingsClick}>
                <ListItemIcon>
                  <SettingsOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Settings </ListItemText>
              </ListItemButton>
            </ListItem> */}
          </List>
        </>
      ) : null}
    </>
  );
}

export default SideBarList;
