import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Rating,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

function DisplaySearchTestResults(props) {
  const { tests } = props;

  const navigate = useNavigate();

  const onTestClick = (selected) => {
    navigate(
      `/${selected.sub_category.category.title}/${selected.sub_category.title}/test/${selected.id}`
    );
  };

  return tests.length >= 1 ? (
    tests.map((singleTest, index) => (
      <CardActionArea
        component="a"
        onClick={() => onTestClick(singleTest)}
        key={index}
        sx={{ mt: 1 }}
      >
        <Card sx={{ display: 'flex' }}>
          <CardMedia
            component="img"
            sx={{
              width: 340,
              display: { xs: 'none', sm: 'block' },
            }}
            image={
              singleTest.image_url ||
              `https://images.unsplash.com/photo-1516397281156-ca07cf9746fc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80`
            }
          />
          <CardContent sx={{ flex: 1, overflowWrap: 'anywhere' }}>
            <Typography component="h2" variant="h5">
              <strong>{singleTest.title}</strong>
            </Typography>

            <Typography variant="subtitle1" color="text.secondary">
              In&nbsp;
              {singleTest.language}
            </Typography>
            <Typography variant="subtitle1" paragraph>
              {singleTest.description
                ? singleTest.description.substring(0, 118)
                : null}
            </Typography>
            <Box sx={{ display: 'flex' }}>
              <Box>
                <Typography>
                  {Math.round(singleTest.test_reviews_avg_rating * 10) / 10}
                  &nbsp;
                </Typography>
              </Box>
              <Rating
                name="read-only"
                value={Math.round(singleTest.test_reviews_avg_rating * 10) / 10}
                readOnly
                precision={0.5}
              />

              {singleTest.test_reviews_count ? (
                <Typography>
                  &#40;{singleTest.test_reviews_count}
                  &nbsp;reviews&#41;
                </Typography>
              ) : (
                <Typography>&#40;no reviews&#41;</Typography>
              )}
            </Box>
            {singleTest.cost ? (
              <Typography component="h2" color="text.primary">
                <strong>₹{singleTest.cost}</strong>
              </Typography>
            ) : (
              <Typography>Free Practice Test</Typography>
            )}
            <Typography variant="subtitle1" color="primary">
              View Practice Test ...
            </Typography>
          </CardContent>
        </Card>
      </CardActionArea>
    ))
  ) : (
    <p>No results</p>
  );
}
export default DisplaySearchTestResults;
