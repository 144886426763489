/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BaseApi } from './BaseApi';

const API_URL = `${process.env.REACT_APP_BACKEND_API_PATH}/api`;
const LOCALSTORAGE_APP_KEY = `${process.env.REACT_APP_LOCALSTORAGE_KEY}`;

export const adminUsersApi = BaseApi.injectEndpoints({
  reducerPath: 'adminUsersApi',
  endpoints: (builder) => ({
    students: builder.query({
      query: (token) => ({
        url: '/students',
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    educators: builder.query({
      query: (token) => ({
        url: '/educators',
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    institutions: builder.query({
      query: (token) => ({
        url: '/institutions',
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
  }),
});

export const { useStudentsQuery, useEducatorsQuery, useInstitutionsQuery } =
  adminUsersApi;
