import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router';

function UserSelect() {
  const navigate = useNavigate();

  const onStudentClick = () => {
    navigate('/student-account');
  };

  const onEducatorClick = () => {
    navigate('/educator-account');
  };
  const onInstitutionClick = () => {
    navigate('/institution-account');
  };

  return (
    <>
      <Grid container align="center">
        <Grid item xs={12}>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{ m: 5, paddingTop: 10, color: '#233c8d' }}
          >
            Please select what describes you best.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <Card sx={{ maxWidth: 345 }}>
            <CardActionArea onClick={onStudentClick}>
              <CardMedia
                sx={{ objectFit: 'cover', width: '100%', height: 200 }}
                component="img"
                // height="140"
                image="https://images.unsplash.com/photo-1491308056676-205b7c9a7dc1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1173&q=80"
                alt="student"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  I am a
                  <br />
                  Student
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <Card sx={{ maxWidth: 345 }}>
            <CardActionArea onClick={onEducatorClick}>
              <CardMedia
                sx={{ objectFit: 'cover', width: '100%', height: 200 }}
                component="img"
                // height="140"
                image="https://images.unsplash.com/photo-1558541966-d1071f7329bd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1333&q=80"
                alt="teacher"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  I am a
                  <br />
                  Teacher
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <Card sx={{ maxWidth: 345 }}>
            <CardActionArea onClick={onInstitutionClick}>
              <CardMedia
                sx={{ objectFit: 'cover', width: '100%', height: 200 }}
                component="img"
                // height="140"
                image="https://images.unsplash.com/photo-1542843895-1b55d9f8ece8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1173&q=80"
                alt="Educational Institute"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  I represent an
                  <br />
                  Educational Institute
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default UserSelect;
