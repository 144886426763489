import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import React from 'react';
import { useCategoriesQuery } from '../../../app/services/CategoriesApi';
import DataLoader from '../../../components/DataLoader';
import CategoriesList from './CategoriesList';
import CreateNewCategory from './CreateNewCategory';

function CategoriesManager() {
  const [open, setOpen] = React.useState(false);

  const { data, isLoading, isSuccess } = useCategoriesQuery();

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <Button variant="contained" onClick={handleClickOpen}>
          Create New Category
        </Button>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle> Create New Category</DialogTitle>
          <DialogContent>
            <CreateNewCategory />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
      {isSuccess && data.categories.length ? (
        <CategoriesList categories={data.categories} />
      ) : (
        <p>No categories found</p>
      )}
    </>
  );
}

export default CategoriesManager;
