import { Typography } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router';
import { usePublicationEditionQuery } from '../../../app/services/CategoriesApi';
import DataLoader from '../../../components/DataLoader';

function SinglePublicationEdition() {
  const params = useParams();

  const { data, isLoading, isSuccess } = usePublicationEditionQuery({
    id: parseInt(params.publicationEditionId, 10),
  });

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  return (
    <>
      {isSuccess && data.publicationEdition ? (
        <>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{ padding: 4, color: '#233c8d' }}
          >
            Title: {data.publicationEdition.edition}
          </Typography>
        </>
      ) : (
        <p>No Publication Edition found</p>
      )}
    </>
  );
}

export default SinglePublicationEdition;
