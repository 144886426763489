/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BaseApi } from './BaseApi';

const API_URL = `${process.env.REACT_APP_BACKEND_API_PATH}/api`;
const LOCALSTORAGE_APP_KEY = `${process.env.REACT_APP_LOCALSTORAGE_KEY}`;

export const EducatorApi = BaseApi.injectEndpoints({
  reducerPath: 'EducatorApi',
  endpoints: (builder) => ({
    educatorProfile: builder.query({
      query: () => ({
        url: '/educator/profile',
        method: 'GET',
      }),
      providesTags: ['Educator Profile'],
    }),
    updateEducatorProfile: builder.mutation({
      query: (details) => ({
        url: `/educator/profile/update/${details.id}`,
        method: 'POST',

        body: {
          first_name: details.first_name,
          last_name: details.last_name,
          qualification: details.qualification,
          language: details.language,
          location: details.location,
          headline: details.headline,
          bio: details.bio,
          subject: details.subject,
          date_of_birth: details.dob,
          gender: details.gender,
          address: details.address,
          gst_number: details.gstno,
          pin_code: details.pincode,
        },
      }),
      invalidatesTags: ['Educator Profile'],
    }),
    educatorChannel: builder.query({
      query: (details) => ({
        url: `/educator/channel/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['Educator Channel'],
    }),
    updateTeachingPreference: builder.mutation({
      query: (details) => ({
        url: '/educator/teaching/preference/create',
        method: 'POST',

        body: {
          educator_id: details.educator_id,
          preferred_languages: details.preferred_languages,
          teaching_interests: details.teaching_interests,
          bio: details.bio,
        },
      }),
      invalidatesTags: ['Educator Profile'],
    }),
  }),
});

export const {
  useEducatorProfileQuery,
  useUpdateEducatorProfileMutation,
  useEducatorChannelQuery,
  useUpdateTeachingPreferenceMutation,
} = EducatorApi;
