/* eslint-disable no-unused-vars */
import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import moment from 'moment';

function DisplayEnroledCourseVideos(props) {
  const { videos, id, progressData } = props;
  const navigate = useNavigate();

  const viewVideo = (videoId) => {
    navigate(`/enrols/${id}/video/${videoId}`);
  };
  const findVideoProgress = (videoId, duration, videoProgressArr) => {
    const found =
      videoProgressArr &&
      videoProgressArr.find((singleProgress) => singleProgress.vid === videoId);
    if (found) {
      return (found.timeStamp * 100) / duration;
    } else {
      return 0;
    }
  };

  return (
    <>
      {videos.map((singleVideo, index) => (
        // <Accordion key={singleVideo.id}>
        //   <AccordionSummary
        //     // expandIcon={<KeyboardArrowDownIcon />}
        //     aria-controls="panel1a-content"
        //     id="panel1a-header"
        //   >
        //     <>
        //       <Button
        //         variant="contained"
        //         onClick={() => viewVideo(singleVideo.id)}
        //         endIcon={<PlayCircleOutlineIcon />}
        //       >
        //         {index + 1}. {singleVideo.title}
        //       </Button>
        //     </>
        //   </AccordionSummary>
        // </Accordion>

        <List
          // sx={style}
          component="nav"
          aria-label="mailbox folders"
          key={singleVideo.id}
        >
          <ListItem>
            {/* <ListItemButton onClick={() => viewVideo(singleVideo.id)}>
              <ListItemIcon>
                <OndemandVideoIcon />
              </ListItemIcon>
              <ListItemText primary={`${index + 1}. ${singleVideo.title}`} />
            </ListItemButton> */}
            <ListItemButton
              onClick={() => viewVideo(singleVideo.id)}
              sx={{ display: 'block' }}
            >
              <ListItemText primary={`${index + 1}. ${singleVideo.title}`} />
              <Box sx={{ width: '100%' }}>
                <ListItemIcon>
                  <OndemandVideoIcon />
                  &nbsp;
                  {/* {Math.abs(singleVideo.duration / 60).toFixed(2)}
                  min */}
                  {moment.utc(singleVideo.duration * 1000).format('HH:mm:ss')}
                </ListItemIcon>
                <LinearProgress
                  value={findVideoProgress(
                    singleVideo.id,
                    singleVideo.duration,
                    progressData
                  )}
                  color="secondary"
                  variant="determinate"
                />
              </Box>
            </ListItemButton>
          </ListItem>
        </List>
      ))}
    </>
  );
}

export default DisplayEnroledCourseVideos;
