/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BaseApi } from './BaseApi';

const API_URL = `${process.env.REACT_APP_BACKEND_API_PATH}/api`;
const LOCALSTORAGE_APP_KEY = `${process.env.REACT_APP_LOCALSTORAGE_KEY}`;

export const studentTestsApi = BaseApi.injectEndpoints({
  reducerPath: 'studentTestsApi',
  endpoints: (builder) => ({
    calculateQuestions: builder.mutation({
      query: (details) => ({
        url: '/test/questions/count',
        method: 'POST',
        body: {
          test_id: details.test_id,
          test_subject_topic_ids: details.test_subject_topic_ids,
          severity: details.severity,
          style: details.style,
        },
      }),
    }),
    createNewPracticeTest: builder.mutation({
      query: (details) => ({
        url: '/practicetest/create',
        method: 'POST',
        body: {
          title: details.title,
          style: details.style,
          test_id: details.test_id,
          severity: details.severity,
          test_subject_topic_ids: details.test_subject_topic_ids,
          no_of_questions: details.no_of_questions,
        },
      }),
    }),
    practiceTests: builder.query({
      query: (token) => ({
        url: '/practice/tests',
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    practiceTest: builder.query({
      query: (details) => ({
        url: `/practicetest/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['Practice Test'],
    }),
    getPracticeTestQuestions: builder.query({
      query: (details) => ({
        url: `/practicetest/questions/${details.id}`,
        method: 'GET',
      }),
    }),
    createPracticeTestResult: builder.mutation({
      query: (details) => ({
        url: `/practicetest/result/create`,
        method: 'POST',
        body: {
          answers: details.answers,
          practice_test_id: details.practiceTestId,
        },
      }),
    }),
    createTestReview: builder.mutation({
      query: (details) => ({
        url: '/test/review/create',
        method: 'POST',
        body: {
          rating: details.rating,
          review_text: details.reviewText,
          test_id: details.test_id,
        },
      }),
      invalidatesTags: ['Practice Test'],
    }),
  }),
});

export const {
  useCalculateQuestionsMutation,
  useCreateNewPracticeTestMutation,
  usePracticeTestQuery,
  usePracticeTestsQuery,
  useLazyGetPracticeTestQuestionsQuery,
  useCreatePracticeTestResultMutation,
  useCreateTestReviewMutation,
} = studentTestsApi;
