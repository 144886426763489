/* eslint-disable no-unused-vars */
import {
  Button,
  Box,
  TextField,
  MenuItem,
  InputAdornment,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { usePublishedCategoriesQuery } from '../../../app/services/CategoriesApi';
import { useCreateTestMutation } from '../../../app/services/TestsApi';

function CreateNewTest() {
  const { enqueueSnackbar } = useSnackbar();
  const { data, isLoading, isSuccess } = usePublishedCategoriesQuery();

  const [createTest, result] = useCreateTestMutation();
  const [categoryTitle, setCategoryTitle] = useState('');

  const [subCategoryTitle, setSubCategoryTitle] = useState('');

  const [selectedCategory, setSelectedCategory] = useState({
    title: '',
    id: null,
    published_sub_categories: [],
  });
  const [selectedSubCategory, setSelectedSubCategory] = useState({
    title: '',
    id: null,
  });

  const [level, setLevel] = useState('beginner');

  const handleLevelChange = (event) => {
    setLevel(event.target.value);
  };

  const validateData = (testData) => {
    let err = true;

    if (!testData.title || !testData.sub_category_id || !testData.language) {
      enqueueSnackbar('Please provide all the required fields', {
        variant: 'error',
      });
    } else {
      err = false;
    }

    return err;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const testData = {
      title: data.get('title'),
      description: data.get('description'),
      sub_category_id: selectedSubCategory.id,
      language: data.get('language'),
      cost: data.get('cost'),
      level: level,
    };

    const res = validateData(testData);
    if (!res) {
      createTest(testData);
    }
  };

  const handleCategoryChange = (event) => {
    const tempSelectedCategory = data.categories.find(
      (singleCategory) => singleCategory.id === event.target.value
    );
    setSelectedCategory(tempSelectedCategory);
    setCategoryTitle(event.target.value);
  };

  const handleSubCategoryChange = (event) => {
    const tempSelectedSubCategory = selectedCategory.published_sub_categories.find(
      (singleSubCategory) => singleSubCategory.id === event.target.value
    );

    setSelectedSubCategory(tempSelectedSubCategory);
    setSubCategoryTitle(event.target.value);
  };

  return result.data ? (
    result.data.message
  ) : (
    <>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="title"
          label=" Title"
          name="title"
          autoComplete="title"
          autoFocus
        />
        <TextField
          margin="normal"
          fullWidth
          id="description"
          label="Description"
          name="description"
          autoComplete="description"
          autoFocus
          multiline
          rows="2"
        />
        <TextField
          margin="normal"
          select
          required
          fullWidth
          id="category"
          label="Category"
          onChange={handleCategoryChange}
          name="category"
          autoComplete="category"
          autoFocus
          value={categoryTitle}
        >
          {data.categories && data.categories.length
            ? data.categories.map((singleCategory, index) => (
                <MenuItem value={singleCategory.id} key={singleCategory.id}>
                  {singleCategory.title}
                </MenuItem>
              ))
            : null}
        </TextField>
        <TextField
          margin="normal"
          select
          required
          fullWidth
          id="sub_category"
          label="Sub Category"
          onChange={handleSubCategoryChange}
          name="sub_category"
          autoComplete="sub_category"
          autoFocus
          value={subCategoryTitle}
        >
          {selectedCategory && selectedCategory.published_sub_categories.length
            ? selectedCategory.published_sub_categories.map(
                (singleSubCategory, index) => (
                  <MenuItem value={singleSubCategory.id} key={singleSubCategory.id}>
                    {singleSubCategory.title}
                  </MenuItem>
                )
              )
            : null}
        </TextField>

        <TextField
          margin="normal"
          required
          fullWidth
          id="language"
          label="Language"
          name="language"
          autoComplete="language"
          autoFocus
        />

        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">Learning Level</FormLabel>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={level}
            onChange={handleLevelChange}
          >
            <FormControlLabel
              value="beginner"
              control={<Radio />}
              label="Beginner"
            />
            <FormControlLabel
              value="intermediate"
              control={<Radio />}
              label="Intermediate"
            />
            <FormControlLabel value="expert" control={<Radio />} label="Expert" />
          </RadioGroup>
        </FormControl>

        <TextField
          margin="normal"
          // required
          fullWidth
          id="cost"
          label="Cost"
          name="cost"
          autoComplete="cost"
          type="number"
          autoFocus
          InputProps={{
            startAdornment: <InputAdornment position="start">₹</InputAdornment>,
          }}
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={result.isLoading || result.isSuccess}
        >
          Add Test
        </Button>
      </Box>
    </>
  );
}

export default CreateNewTest;
