/* eslint-disable no-unused-vars */
import {
  Button,
  Box,
  TextField,
  MenuItem,
  InputAdornment,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import {
  usePublicPublicationsQuery,
  usePublishedCategoriesQuery,
} from '../../../app/services/CategoriesApi';
import { useUpdateCourseMutation } from '../../../app/services/CoursesApi';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useSnackbar } from 'notistack';
import MomentUtils from '@date-io/moment';
function UpdateCourse(props) {
  const { course } = props;

  const { enqueueSnackbar } = useSnackbar();

  const { data, isLoading, isSuccess } = usePublishedCategoriesQuery();
  const [updateCourse, result] = useUpdateCourseMutation();
  const publicationsRes = usePublicPublicationsQuery();

  const [categoryTitle, setCategoryTitle] = useState(
    course.sub_category.category.id || ''
  );

  const [subCategoryTitle, setSubCategoryTitle] = useState(
    course.sub_category.id || ''
  );

  const [selectedCategory, setSelectedCategory] = useState(
    course.sub_category.category || {
      title: '',
      id: null,
      published_sub_categories: [],
    }
  );
  const [selectedSubCategory, setSelectedSubCategory] = useState(
    course.sub_category || {
      title: '',
      id: null,
    }
  );
  const [startDate, setStartDate] = useState(moment(course.start_date));
  const [endDate, setEndDate] = useState(moment(course.end_date));

  const [level, setLevel] = useState(course.level || 'beginner');
  const [gradeTitle, setGradeTitle] = useState(course.grade ? course.grade.id : '');
  const [gradeSubjectTitle, setGradeSubjectTitle] = useState(
    course.grade_subject ? course.grade_subject.id : ''
  );

  const [publicationTitle, setPublicationTitle] = useState(
    course.publication ? course.publication.id : ''
  );
  const [publicationEditionTitle, setPublicationEditionTitle] = useState(
    course.publication_edition ? course.publication_edition.id : ''
  );

  const [selectedGrade, setSelectedGrade] = useState(
    course.grade || {
      title: '',
      id: null,
    }
  );

  const [selectedGradeSubject, setSelectedGradeSubject] = useState(
    course.grade_subject || {
      title: '',
      id: null,
    }
  );

  const [selectedPublication, setSelectedPublication] = useState(
    course.publication || {
      title: '',
      id: null,
      publication_editions: [],
    }
  );

  const [selectedPublicationEdition, setSelectedPublicationEdition] = useState(
    course.publication_edition || {
      edition: '',
      id: null,
    }
  );

  const handleLevelChange = (event) => {
    setLevel(event.target.value);
  };

  const handleStartDateChange = (newValue) => {
    setStartDate(newValue);
  };
  const handleEndDateChange = (newValue) => {
    setEndDate(newValue);
  };

  const validateData = (courseData) => {
    let err = true;

    if (!courseData.title || !courseData.sub_category_id || !courseData.language) {
      enqueueSnackbar('Please provide all the required fields', {
        variant: 'error',
      });
    } else {
      err = false;
    }

    return err;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const courseData = {
      id: course.id,
      title: data.get('title'),
      description: data.get('description'),
      sub_category_id: selectedSubCategory.id,
      language: data.get('language'),
      // start_date: startDate.format(),
      // end_date: endDate.format(),
      educator_id: course.educator_id,
      institution_id: course.institution_id,
      cost: data.get('cost'),
      grade_id: selectedGrade.id,
      publication_id: selectedPublication.id,
      grade_subject_id: selectedGradeSubject.id,
      publication_edition_id: selectedPublicationEdition.id,
      level: level,
    };
    const res = validateData(courseData);
    if (!res) {
      updateCourse(courseData);
    }
  };

  const handleCategoryChange = (event) => {
    const tempSelectedCategory = data.categories.find(
      (singleCategory) => singleCategory.id === event.target.value
    );
    setSelectedCategory(tempSelectedCategory);
    setCategoryTitle(event.target.value);
  };

  const handleSubCategoryChange = (event) => {
    const tempSelectedSubCategory = selectedCategory.published_sub_categories.find(
      (singleSubCategory) => singleSubCategory.id === event.target.value
    );

    setSelectedSubCategory(tempSelectedSubCategory);
    setSubCategoryTitle(event.target.value);
  };

  const handleGradeChange = (event) => {
    const tempSelectedGrade = selectedSubCategory.grades.find(
      (singleGrade) => singleGrade.id === event.target.value
    );

    setSelectedGrade(tempSelectedGrade);
    setGradeTitle(event.target.value);
  };

  const handleGradeSubjectChange = (event) => {
    const tempSelectedGradeSubject = selectedGrade.grade_subjects.find(
      (singleGradeSubject) => singleGradeSubject.id === event.target.value
    );

    setSelectedGradeSubject(tempSelectedGradeSubject);
    setGradeSubjectTitle(event.target.value);
  };

  const handlePublicationChange = (event) => {
    const tempSelectedPublication = publicationsRes.data.publications.find(
      (singlePublication) => singlePublication.id === event.target.value
    );

    setSelectedPublication(tempSelectedPublication);
    setPublicationTitle(event.target.value);
  };

  const handlePublicationEditionChange = (event) => {
    const tempPublicationEdition = selectedPublication.publication_editions.find(
      (singlePublicationEdition) =>
        singlePublicationEdition.id === event.target.value
    );

    setSelectedPublicationEdition(tempPublicationEdition);
    setPublicationEditionTitle(event.target.value);
  };

  return result.data ? (
    result.data.message
  ) : (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="title"
        label=" Title"
        name="title"
        autoComplete="title"
        autoFocus
        defaultValue={course.title}
      />
      <TextField
        required
        margin="normal"
        fullWidth
        id="description"
        label="Description"
        name="description"
        autoComplete="description"
        autoFocus
        multiline
        rows="2"
        defaultValue={course.description}
      />

      <TextField
        margin="normal"
        select
        required
        fullWidth
        id="category"
        label="Category"
        onChange={handleCategoryChange}
        name="category"
        autoComplete="category"
        autoFocus
        value={categoryTitle}
      >
        {data.categories && data.categories.length
          ? data.categories.map((singleCategory) => (
              <MenuItem value={singleCategory.id} key={singleCategory.id}>
                {singleCategory.title}
              </MenuItem>
            ))
          : null}
      </TextField>
      <TextField
        margin="normal"
        select
        required
        fullWidth
        id="sub_category"
        label="Sub Category"
        onChange={handleSubCategoryChange}
        name="sub_category"
        autoComplete="sub_category"
        autoFocus
        value={subCategoryTitle}
      >
        {selectedCategory && selectedCategory.published_sub_categories.length
          ? selectedCategory.published_sub_categories.map((singleSubCategory) => (
              <MenuItem value={singleSubCategory.id} key={singleSubCategory.id}>
                {singleSubCategory.title}
              </MenuItem>
            ))
          : null}
      </TextField>

      {selectedCategory.id &&
      selectedSubCategory.id &&
      selectedSubCategory.grades?.length ? (
        <TextField
          margin="normal"
          select
          required
          fullWidth
          id="grade"
          label="Grade"
          onChange={handleGradeChange}
          name="grade"
          autoComplete="grade"
          autoFocus
          value={gradeTitle}
        >
          {selectedCategory &&
          selectedSubCategory &&
          selectedSubCategory.grades.length
            ? selectedSubCategory.grades.map((singleGrade, index) => (
                <MenuItem value={singleGrade.id} key={singleGrade.id}>
                  {singleGrade.title}
                </MenuItem>
              ))
            : null}
        </TextField>
      ) : null}

      {selectedCategory.id &&
      selectedSubCategory.id &&
      selectedGrade.id &&
      selectedGrade.grade_subjects?.length ? (
        <TextField
          margin="normal"
          select
          required
          fullWidth
          id="grade_subject"
          label="Grade Subject"
          onChange={handleGradeSubjectChange}
          name="grade subject"
          autoComplete="grade subject"
          autoFocus
          value={gradeSubjectTitle}
        >
          {selectedGrade.grade_subjects.length
            ? selectedGrade.grade_subjects.map((singleGradeSubject, index) => (
                <MenuItem value={singleGradeSubject.id} key={singleGradeSubject.id}>
                  {singleGradeSubject.title}
                </MenuItem>
              ))
            : null}
        </TextField>
      ) : null}

      {selectedCategory.id &&
      selectedSubCategory.id &&
      selectedGrade.id &&
      publicationsRes.data?.publications?.length ? (
        <TextField
          margin="normal"
          select
          required
          fullWidth
          id="publication"
          label="Publication"
          onChange={handlePublicationChange}
          name="publication"
          autoComplete="publication"
          autoFocus
          value={publicationTitle}
        >
          {publicationsRes.data.publications.length
            ? publicationsRes.data.publications.map((singlePublication, index) => (
                <MenuItem value={singlePublication.id} key={singlePublication.id}>
                  {singlePublication.title}
                </MenuItem>
              ))
            : null}
        </TextField>
      ) : null}

      {selectedCategory.id &&
      selectedSubCategory.id &&
      selectedGrade.id &&
      publicationsRes.data?.publications.length &&
      selectedPublication.id &&
      selectedPublication.publication_editions?.length ? (
        <TextField
          margin="normal"
          select
          required
          fullWidth
          id="publication_edition"
          label="Publication Edition"
          onChange={handlePublicationEditionChange}
          name="Publication Edition"
          autoComplete="Publication Edition"
          autoFocus
          value={publicationEditionTitle}
        >
          {selectedPublication.publication_editions.length
            ? selectedPublication.publication_editions.map(
                (singlePublicationEdition, index) => (
                  <MenuItem
                    value={singlePublicationEdition.id}
                    key={singlePublicationEdition.id}
                  >
                    {singlePublicationEdition.edition}
                  </MenuItem>
                )
              )
            : null}
        </TextField>
      ) : null}

      <TextField
        margin="normal"
        required
        fullWidth
        id="language"
        label="Language"
        name="language"
        autoComplete="language"
        defaultValue={course.language}
        autoFocus
      />
      {/* <Box sx={{ mt: 2 }}>
        <LocalizationProvider dateAdapter={MomentUtils}>
          <DateTimePicker
            label="Start Date and Time"
            value={startDate}
            onChange={handleStartDateChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>

        <LocalizationProvider dateAdapter={MomentUtils}>
          <DateTimePicker
            label="End Date and Time"
            value={endDate}
            onChange={handleEndDateChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Box> */}

      <FormControl>
        <FormLabel id="demo-radio-buttons-group-label">Learning Level</FormLabel>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={level}
          onChange={handleLevelChange}
        >
          <FormControlLabel value="beginner" control={<Radio />} label="Beginner" />
          <FormControlLabel
            value="intermediate"
            control={<Radio />}
            label="Intermediate"
          />
          <FormControlLabel value="expert" control={<Radio />} label="Expert" />
        </RadioGroup>
      </FormControl>

      <TextField
        margin="normal"
        // required
        fullWidth
        id="cost"
        label="Cost"
        name="cost"
        autoComplete="cost"
        type="number"
        autoFocus
        InputProps={{
          startAdornment: <InputAdornment position="start">₹</InputAdornment>,
        }}
        defaultValue={course.cost}
      />

      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        disabled={result.isLoading || result.isSuccess}
      >
        Update Course
      </Button>
    </Box>
  );
}

export default UpdateCourse;
