/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import {
  Button,
  Box,
  TextField,
  Grid,
  IconButton,
  Tabs,
  Tab,
  CircularProgress,
  InputAdornment,
  Icon,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Add, DeleteOutline, SignalCellularNull } from '@mui/icons-material';
import TabPanel from '../../../components/authentication/TabPanel';
import MinimizeIcon from '@mui/icons-material/Minimize';
import {
  useCreateSubjectMutation,
  useCreateTopicMutation,
  useDeleteSubjectMutation,
  useDeleteTopicMutation,
} from '../../../app/services/CoursesApi';
import axios from 'axios';
import DisplayAndUploadVideo from './DisplayAndUploadVideo';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const API_URL = `${process.env.REACT_APP_BACKEND_API_PATH}/api`;

function CreateCourseSubjectsAndTopics(props) {
  const { courseId, nextButton } = props;
  const [value, setValue] = useState(0);
  const { token } = useSelector((state) => state.auth);

  const [createSubject] = useCreateSubjectMutation();
  const [createTopic] = useCreateTopicMutation();
  const [deleteId] = useDeleteSubjectMutation();
  const [deleteTopicId] = useDeleteTopicMutation();

  const [allSubjects, setAllSubjects] = useState([
    {
      topics: [
        {
          title: '',
          addContent: false,
          status: false,
          id: null,
        },
      ],
      title: '',
      status: false,
      id: null,
    },
  ]);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (allSubjects.length >= 1 && allSubjects[0].id) {
      nextButton(true);
    } else {
      nextButton(false);
    }
  }, [allSubjects[0]]);

  const handlePlusButtonClick = (subjectIndex, topicIndex) => {
    const updated = allSubjects.map((singleSubject, index) => {
      if (index == subjectIndex) {
        const updatedTopics = singleSubject.topics.map((singleTopic, i) => {
          if (i === topicIndex) {
            return {
              ...singleTopic,
              addContent: true,
            };
          } else {
            return singleTopic;
          }
        });
        return { ...singleSubject, topics: updatedTopics };
      } else {
        return singleSubject;
      }
    });
    setAllSubjects(updated);
  };
  const handleMinusButtonClick = (subjectIndex, topicIndex) => {
    const updated = allSubjects.map((singleSubject, index) => {
      if (index === subjectIndex) {
        const updatedTopics = singleSubject.topics.map((singleTopic, i) => {
          if (i == topicIndex) {
            return {
              ...singleTopic,
              addContent: false,
            };
          } else {
            return singleTopic;
          }
        });
        return { ...singleSubject, topics: updatedTopics };
      } else {
        return singleSubject;
      }
    });
    setAllSubjects(updated);
  };

  const handleSelectVideo = (subjectIndex, topicIndex, vidFile) => {
    const updated = allSubjects.map((singleSubject, index) => {
      if (index === subjectIndex) {
        const updatedTopics = singleSubject.topics.map((singleTopic, i) => {
          if (i == topicIndex) {
            return {
              ...singleTopic,
              videoFile: vidFile,
            };
          } else {
            return singleTopic;
          }
        });
        return { ...singleSubject, topics: updatedTopics };
      } else {
        return singleSubject;
      }
    });
    setAllSubjects(updated);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const deleteSubject = (singleSubject) => {
    setAllSubjects(allSubjects.filter((objective) => objective !== singleSubject));
  };

  const deleteSubjectWithId = (singleSubject) => {
    setAllSubjects(allSubjects.filter((objective) => objective !== singleSubject));
    const res = deleteId({ id: singleSubject.id });
  };

  const deleteTopic = (subjectIndex, topic) => {
    const updated = allSubjects.map((singleSubject, index) => {
      if (index === subjectIndex) {
        const updatedTopics = singleSubject.topics.filter(
          (singleTopic) => singleTopic !== topic
        );
        return { ...singleSubject, topics: updatedTopics };
      } else {
        return singleSubject;
      }
    });
    setAllSubjects(updated);
  };

  const deleteTopicWithId = (subjectIndex, topic) => {
    const updated = allSubjects.map((singleSubject, index) => {
      if (index === subjectIndex) {
        const updatedTopics = singleSubject.topics.filter(
          (singleTopic) => singleTopic !== topic
        );
        return { ...singleSubject, topics: updatedTopics };
      } else {
        return singleSubject;
      }
    });
    setAllSubjects(updated);
    const res = deleteTopicId({ id: topic.id });
  };

  const handleSubjectTitleChange = (index, e) => {
    const updatedSubjects = allSubjects.map((value, i) => {
      if (i === index) {
        return { ...value, title: e.target.value };
      } else {
        return value;
      }
    });
    setAllSubjects(updatedSubjects);
  };

  const createChapter = (subjectIndex) => {
    const subject = allSubjects.find(
      (singleSubject, index) => index === subjectIndex
    );

    const updatedSubjects = allSubjects.map((value, i) => {
      if (i === subjectIndex) {
        return {
          ...value,
          status: 'progress',
        };
      } else {
        return value;
      }
    });
    setAllSubjects(updatedSubjects);
    if (subject) {
      const subjectData = {
        course_id: courseId,
        title: subject.title,
        description: subject.title,
      };

      createSubject(subjectData).then((res) => {
        if (res.data) {
          const updatedSubjects = allSubjects.map((value, i) => {
            if (i === subjectIndex) {
              return {
                ...value,
                title: res.data.subject.title,
                status: 'success',
                id: res.data.subject.id,
              };
            } else {
              return value;
            }
          });
          setAllSubjects(updatedSubjects);
        } else {
          const updatedSubjects = allSubjects.map((value, i) => {
            if (i === subjectIndex) {
              return {
                ...value,
                status: 'error',
              };
            } else {
              return value;
            }
          });
          setAllSubjects(updatedSubjects);
        }
      });
    }
  };

  const createLecture = (subjectIndex, topicIndex, subjectId) => {
    allSubjects[subjectIndex].topics[topicIndex] = {
      ...allSubjects[subjectIndex].topics[topicIndex],
      status: 'progress',
    };
    setAllSubjects([...allSubjects]);
    const subject = allSubjects.find(
      (singleSubject, index) => singleSubject.id === subjectId
    );

    if (subject) {
      const topic = subject.topics.find(
        (singleTopic, index) => index === topicIndex
      );
      if (topic) {
        const topicData = {
          subject_id: subjectId,
          title: topic.title,
          description: topic.title,
        };
        createTopic(topicData).then((res) => {
          if (res.data) {
            allSubjects[subjectIndex].topics[topicIndex] = {
              ...allSubjects[subjectIndex].topics[topicIndex],
              title: res.data.topic.title,
              status: 'success',
              id: res.data.topic.id,
            };
            setAllSubjects([...allSubjects]);
          } else {
            allSubjects[subjectIndex].topics[topicIndex] = {
              ...allSubjects[subjectIndex].topics[topicIndex],
              status: 'error',
            };
            setAllSubjects([...allSubjects]);
          }
        });
      }
    }
  };

  return (
    <Grid item xl="12" lg="12" md="12" sm="12" xs="12">
      <>
        <Box>
          <Grid container spacing={2}>
            {allSubjects.length >= 1 &&
              allSubjects.map((singleSubject, subjectIndex) => (
                <Grid
                  item
                  xl="12"
                  lg="12"
                  md="12"
                  sm="12"
                  xs="12"
                  key={subjectIndex}
                >
                  <div
                    style={{
                      border: '1px solid #cacbcf',
                      background: '#fafafa',
                      borderRadius: '6px',
                      padding: '1rem',
                    }}
                  >
                    <div style={{ display: 'flex' }}>
                      <TextField
                        value={singleSubject.title}
                        required
                        fullWidth
                        size="small"
                        onChange={(e) => handleSubjectTitleChange(subjectIndex, e)}
                        placeholder="What Where How and When"
                        label={`Chapter ${subjectIndex + 1}`}
                        disabled={singleSubject.status === 'success'}
                        InputProps={{
                          endAdornment:
                            singleSubject.status === 'success' ? (
                              <InputAdornment position="end">
                                <Icon color="success">
                                  <CheckCircleIcon twoToneColor="#52c41a" />
                                </Icon>{' '}
                              </InputAdornment>
                            ) : null,
                        }}
                      />
                      {singleSubject.status === 'progress' ? (
                        <CircularProgress disableShrink />
                      ) : (
                        <div
                          className="font-icon-wrapper"
                          onClick={() =>
                            singleSubject.id
                              ? deleteSubjectWithId(singleSubject)
                              : deleteSubject(singleSubject)
                          }
                        >
                          <IconButton>
                            <DeleteOutline />
                          </IconButton>
                        </div>
                      )}
                      {singleSubject.status === 'success' ? null : (
                        <Button
                          sx={{ padding: '0px 8px' }}
                          onClick={() => {
                            setOpen(true);
                            createChapter(subjectIndex);
                          }}
                          variant="outlined"
                          color="secondary"
                          disabled={singleSubject.status === 'success'}
                        >
                          Create Chapter
                        </Button>
                      )}
                    </div>
                    {singleSubject.id ? (
                      <div style={{ marginTop: '1rem' }}>
                        {Array.isArray(singleSubject.topics) &&
                          singleSubject.topics.length > 0 &&
                          singleSubject.topics.map((singleTopic, topicIndex) => (
                            <div
                              key={topicIndex}
                              style={{
                                border: '1px solid #cacbcf',
                                background: '#fff',
                                borderRadius: '6px',
                                padding: '1rem',
                                marginTop: '1rem',
                              }}
                            >
                              <div style={{ display: 'flex' }}>
                                <TextField
                                  value={singleTopic.title}
                                  required
                                  fullWidth
                                  size="small"
                                  onChange={(e) => {
                                    allSubjects[subjectIndex].topics[topicIndex] = {
                                      ...allSubjects[subjectIndex].topics[
                                        topicIndex
                                      ],
                                      title: e.target.value,
                                      addContent: false,
                                    };
                                    setAllSubjects([...allSubjects]);
                                  }}
                                  placeholder="What is Herbivorus animal"
                                  label={`Lecture ${topicIndex + 1}`}
                                  disabled={singleTopic.status === 'success'}
                                  InputProps={{
                                    endAdornment:
                                      singleTopic.status === 'success' ? (
                                        <InputAdornment position="end">
                                          <Icon color="success">
                                            <CheckCircleIcon twoToneColor="#52c41a" />
                                          </Icon>{' '}
                                        </InputAdornment>
                                      ) : null,
                                  }}
                                />
                                {singleTopic.status === 'progress' ? (
                                  <CircularProgress disableShrink />
                                ) : (
                                  <div
                                    className="font-icon-wrapper"
                                    onClick={() =>
                                      singleTopic.id
                                        ? deleteTopicWithId(
                                            subjectIndex,
                                            singleTopic
                                          )
                                        : deleteTopic(subjectIndex, singleTopic)
                                    }
                                  >
                                    <IconButton>
                                      <DeleteOutline />
                                    </IconButton>
                                  </div>
                                )}
                                {singleTopic.status === 'success' &&
                                singleTopic.id ? (
                                  singleTopic.addContent ? (
                                    <Button
                                      sx={{ width: '200px' }}
                                      onClick={() => {
                                        handleMinusButtonClick(
                                          subjectIndex,
                                          topicIndex
                                        );
                                      }}
                                      startIcon={<MinimizeIcon />}
                                    >
                                      Minimize
                                    </Button>
                                  ) : (
                                    <Button
                                      sx={{ width: '200px' }}
                                      onClick={() => {
                                        handlePlusButtonClick(
                                          subjectIndex,
                                          topicIndex
                                        );
                                      }}
                                      startIcon={<Add />}
                                    >
                                      Add Content
                                    </Button>
                                  )
                                ) : (
                                  <Button
                                    sx={{ padding: '0px 8px' }}
                                    onClick={() => {
                                      setOpen(true);
                                      createLecture(
                                        subjectIndex,
                                        topicIndex,
                                        singleSubject.id
                                      );
                                    }}
                                    variant="outlined"
                                    color="secondary"
                                    disabled={singleTopic.status === 'success'}
                                  >
                                    Create Lecture
                                  </Button>
                                )}
                              </div>
                              {singleTopic.addContent === true && (
                                <div style={{ marginTop: '0.5rem' }}>
                                  <Tabs
                                    value={value}
                                    onChange={(e, newval) => handleChange(e, newval)}
                                    textColor="primary"
                                    indicatorColor="primary"
                                    aria-label="Account forms"
                                  >
                                    <Tab
                                      label="Upload Video"
                                      id="simple-tab-0"
                                      aria-controls="simple-tabpanel-0"
                                    />
                                  </Tabs>
                                  <TabPanel value={value} index={0}>
                                    <Grid container spacing={1}>
                                      <DisplayAndUploadVideo
                                        sind={subjectIndex}
                                        tind={topicIndex}
                                        topic={singleTopic}
                                        subject={singleSubject}
                                        handleSelectVideo={handleSelectVideo}
                                      />
                                    </Grid>
                                  </TabPanel>
                                </div>
                              )}
                            </div>
                          ))}
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Button
                            sx={{ marginTop: '0.5rem' }}
                            onClick={() => {
                              // Make sure allSubjects is defined
                              if (
                                allSubjects &&
                                Array.isArray(allSubjects) &&
                                allSubjects[subjectIndex]
                              ) {
                                // Make sure topics is defined and is an array
                                const currentTopics =
                                  allSubjects[subjectIndex]?.topics;
                                if (Array.isArray(currentTopics)) {
                                  // Update the state
                                  allSubjects[subjectIndex] = {
                                    ...allSubjects[subjectIndex],
                                    topics:
                                      currentTopics.length > 0
                                        ? [...currentTopics, { title: '' }]
                                        : [
                                            {
                                              title: '',

                                              addContent: false,
                                            },
                                          ],
                                  };
                                  setAllSubjects([...allSubjects]); // Ensure immutability by creating a new array
                                }
                              }
                            }}
                          >
                            Add Lecture
                          </Button>
                        </Box>
                      </div>
                    ) : null}
                  </div>
                </Grid>
              ))}

            <Grid item xl="12" lg="12" md="12" sm="12" xs="12">
              <Button
                type="button"
                variant="contained"
                onClick={() => {
                  setAllSubjects([
                    ...allSubjects,
                    {
                      topics: [
                        {
                          title: '',
                        },
                      ],
                      title: '',
                    },
                  ]);
                }}
              >
                Add Chapter
              </Button>
            </Grid>
          </Grid>
        </Box>
      </>
    </Grid>
  );
}

export default CreateCourseSubjectsAndTopics;
