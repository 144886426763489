/* eslint-disable no-unused-vars */
import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  Grid,
  Box,
  Chip,
  CardContent,
  Icon,
} from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useQuestionQuery } from '../../../../app/services/ExamsApi';
import QuestionList from './QuestionList';
import UpdateQuestion from './UpdateQuestion';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DataLoader from '../../../../components/DataLoader';

function SingleQuestion() {
  const params = useParams();

  const { data, isLoading, isSuccess } = useQuestionQuery({
    id: parseInt(params.questionId, 10),
  });

  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  return (
    <>
      {isSuccess && data.examQuestion ? (
        <>
          <Grid container spacing={1}>
            <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
              <Box sx={{ textAlign: 'center' }}>
                <Button variant="contained" onClick={handleClickOpen1}>
                  Update question
                </Button>
                <>
                  <Dialog
                    open={open1}
                    onClose={handleClose1}
                    fullWidth
                    maxWidth="sm"
                  >
                    <DialogTitle> Update question</DialogTitle>
                    <DialogContent>
                      <UpdateQuestion examQuestion={data.examQuestion} />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose1}>Close</Button>
                    </DialogActions>
                  </Dialog>
                </>
              </Box>
            </Grid>
            <Grid item xs="12" sm="12" md="2" lg="2" xl="2" align="left"></Grid>
            <Grid item xs="12" sm="12" md="8" lg="8" xl="8" align="left">
              <>
                <Card>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{ padding: 4, color: '#233c8d', overflowWrap: 'anywhere' }}
                  >
                    Question: {data.examQuestion.question}
                  </Typography>
                </Card>

                <Card>
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      sx={{ padding: 4, overflowWrap: 'anywhere' }}
                      color="text.secondary"
                    >
                      Options:
                      <br />
                      {data.examQuestion.options.map((singleOption, index) =>
                        singleOption.isCorrect ? (
                          <>
                            <Typography>
                              {`${index + 1}. ${singleOption.optionText}`}&nbsp;
                              <Icon color="success">
                                <CheckCircleIcon />
                              </Icon>
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Typography>
                              {`${index + 1}. ${singleOption.optionText}`}&nbsp;
                              <Icon color="error">
                                <CancelIcon />
                              </Icon>
                            </Typography>
                          </>
                        )
                      )}
                    </Typography>
                  </CardContent>
                </Card>
              </>
            </Grid>
            <Grid item xs="12" sm="12" md="2" lg="2" xl="2" align="left"></Grid>
          </Grid>
        </>
      ) : (
        <p>No question found</p>
      )}
    </>
  );
}

export default SingleQuestion;
