/* eslint-disable no-unused-vars */
import { Avatar, Box, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useInstitutionDashboardCountsQuery } from '../../../app/services/UserApi';
import DataLoader from '../../../components/DataLoader';

function InstitutionDashboard() {
  const { isSignedIn, user } = useSelector((state) => state.auth);

  const { data, isLoading, isSuccess } = useInstitutionDashboardCountsQuery();

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  return (
    <>
      {isSuccess && data ? (
        <Grid container spacing={2}>
          <Grid item xl="1" lg="1" md="12" sm="12" xs="12"></Grid>
          <Grid
            item
            xl="2"
            lg="2"
            md="12"
            sm="12"
            xs="12"
            //   sx={{ border: '0.5px solid', padding: '0.5rem' }}
          >
            <Box sx={{ border: '0.5px solid', padding: '0.5rem' }}>
              <div style={{ display: 'flex', gap: '1rem' }}>
                <div>
                  <Avatar sx={{ height: '80px', width: '80px' }}>
                    {isSignedIn ? user.name.charAt(0).toUpperCase() : null}
                  </Avatar>
                </div>
                <div>
                  <Typography
                    component={'h1'}
                    variant="h6"
                    sx={{
                      color: '#397abb',
                      fontWeight: 600,
                      fontSize: '16px',
                    }}
                  >
                    Welcome !
                  </Typography>
                  <Typography
                    component={'h1'}
                    variant="h6"
                    sx={{
                      color: '#397abb',
                      fontWeight: 600,
                      fontSize: '16px',
                    }}
                  >
                    {user ? user.name : null}
                  </Typography>
                </div>
              </div>
            </Box>
          </Grid>

          <Grid
            item
            xl="6"
            lg="6"
            md="12"
            sm="12"
            xs="12"
            //   sx={{ border: '0.5px solid', padding: '0.5rem' }}
          >
            <Grid container>
              <Grid
                item
                xl="12"
                lg="12"
                md="12"
                sm="12"
                xs="12"
                sx={{ border: '0.5px solid', padding: '0.5rem' }}
              >
                <Box>
                  <Typography
                    sx={{
                      color: '#1c76bb',
                      fontSize: '18px',
                      fontWeight: '600',
                    }}
                  >
                    User Engagement
                  </Typography>
                  <Box
                    style={{
                      display: 'flex',
                      gap: '0.5rem',
                    }}
                  >
                    {/* #f6f6f6 */}
                    <Box
                      sx={{
                        padding: '1rem',
                        width: '100px',
                        height: '50px',
                        borderRadius: '10px',
                        backgroundColor: '#f6f6f6',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignContent: 'center',
                          gap: '0.5rem',
                        }}
                      >
                        <img
                          src="/sand-clock.jpeg"
                          style={{ height: '20px', width: '20px' }}
                        />
                        <span style={{ fontSize: '12px' }}>Total Followers</span>
                      </div>
                      <Typography
                        component={'h5'}
                        variant="h5"
                        sx={{ fontWeight: 700 }}
                      >
                        {data.totalFollowers}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        padding: '1rem',
                        width: '100px',
                        height: '50px',
                        borderRadius: '10px',
                        backgroundColor: '#f6f6f6',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignContent: 'center',
                          gap: '0.5rem',
                        }}
                      >
                        <img
                          src="/sand-clock.jpeg"
                          style={{ height: '20px', width: '20px' }}
                        />
                        <span style={{ fontSize: '12px' }}>
                          Followers(last 24Hrs)
                        </span>
                      </div>
                      <Typography
                        component={'h5'}
                        variant="h5"
                        sx={{ fontWeight: 700 }}
                      >
                        {data.followersInLast24Hours}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        padding: '1rem',
                        width: '100px',
                        height: '50px',
                        borderRadius: '10px',
                        backgroundColor: '#f6f6f6',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignContent: 'center',
                          gap: '0.5rem',
                        }}
                      >
                        <img
                          src="/sand-clock.jpeg"
                          style={{ height: '20px', width: '20px' }}
                        />
                        <span style={{ fontSize: '12px' }}>Total Following</span>
                      </div>
                      <Typography
                        component={'h5'}
                        variant="h5"
                        sx={{ fontWeight: 700 }}
                      >
                        {data.totalFollowing}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        padding: '1rem',
                        width: '100px',
                        height: '50px',
                        borderRadius: '10px',
                        backgroundColor: '#f6f6f6',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignContent: 'center',
                          gap: '0.5rem',
                        }}
                      >
                        <img
                          src="/sand-clock.jpeg"
                          style={{ height: '20px', width: '20px' }}
                        />
                        <span style={{ fontSize: '12px' }}>
                          Following(last 24Hrs)
                        </span>
                      </div>
                      <Typography
                        component={'h5'}
                        variant="h5"
                        sx={{ fontWeight: 700 }}
                      >
                        {data.followedInLast24Hours}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box style={{ marginTop: '1rem' }}>
                  <Typography
                    sx={{
                      color: '#1c76bb',
                      fontSize: '18px',
                      fontWeight: '600',
                    }}
                  >
                    Courses
                  </Typography>
                  <Box
                    style={{
                      display: 'flex',
                      gap: '0.5rem',
                    }}
                  >
                    {/* #f6f6f6 */}
                    <Box
                      sx={{
                        padding: '1rem',
                        width: '100px',
                        height: '50px',
                        borderRadius: '10px',
                        backgroundColor: '#f6f6f6',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignContent: 'center',
                          gap: '0.5rem',
                        }}
                      >
                        <img
                          src="/sand-clock.jpeg"
                          style={{ height: '20px', width: '20px' }}
                        />
                        <span style={{ fontSize: '12px' }}>Total Courses</span>
                      </div>
                      <Typography
                        component={'h5'}
                        variant="h5"
                        sx={{ fontWeight: 700 }}
                      >
                        {data.totalCourses}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        padding: '1rem',
                        width: '100px',
                        height: '50px',
                        borderRadius: '10px',
                        backgroundColor: '#f6f6f6',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignContent: 'center',
                          gap: '0.5rem',
                        }}
                      >
                        <img
                          src="/sand-clock.jpeg"
                          style={{ height: '20px', width: '20px' }}
                        />
                        <span style={{ fontSize: '12px' }}>Published Courses</span>
                      </div>
                      <Typography
                        component={'h5'}
                        variant="h5"
                        sx={{ fontWeight: 700 }}
                      >
                        {data.totalPublishedCourses}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        padding: '1rem',
                        width: '100px',
                        height: '50px',
                        borderRadius: '10px',
                        backgroundColor: '#f6f6f6',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignContent: 'center',
                          gap: '0.5rem',
                        }}
                      >
                        <img
                          src="/sand-clock.jpeg"
                          style={{ height: '20px', width: '20px' }}
                        />
                        <span style={{ fontSize: '12px' }}>Draft Courses</span>
                      </div>
                      <Typography
                        component={'h5'}
                        variant="h5"
                        sx={{ fontWeight: 700 }}
                      >
                        {data.totalDraftCourses}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        padding: '1rem',
                        width: '100px',
                        height: '50px',
                        borderRadius: '10px',
                        backgroundColor: '#f6f6f6',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignContent: 'center',
                          gap: '0.5rem',
                        }}
                      >
                        <img
                          src="/sand-clock.jpeg"
                          style={{ height: '20px', width: '20px' }}
                        />
                        <span style={{ fontSize: '12px' }}>Free Courses</span>
                      </div>
                      <Typography
                        component={'h5'}
                        variant="h5"
                        sx={{ fontWeight: 700 }}
                      >
                        {data.totalFreeCourses}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        padding: '1rem',
                        width: '100px',
                        height: '50px',
                        borderRadius: '10px',
                        backgroundColor: '#f6f6f6',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignContent: 'center',
                          gap: '0.5rem',
                        }}
                      >
                        <img
                          src="/sand-clock.jpeg"
                          style={{ height: '20px', width: '20px' }}
                        />
                        <span style={{ fontSize: '12px' }}> Premium Courses </span>
                      </div>
                      <Typography
                        component={'h5'}
                        variant="h5"
                        sx={{ fontWeight: 700 }}
                      >
                        {data.totalPremiumCourses}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box style={{ marginTop: '1rem' }}>
                  <Typography
                    sx={{
                      color: '#1c76bb',
                      fontSize: '18px',
                      fontWeight: '600',
                    }}
                  >
                    Exams
                  </Typography>
                  <Box
                    style={{
                      display: 'flex',
                      gap: '0.5rem',
                    }}
                  >
                    {/* #f6f6f6 */}
                    <Box
                      sx={{
                        padding: '1rem',
                        width: '100px',
                        height: '50px',
                        borderRadius: '10px',
                        backgroundColor: '#f6f6f6',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignContent: 'center',
                          gap: '0.5rem',
                        }}
                      >
                        <img
                          src="/sand-clock.jpeg"
                          style={{ height: '20px', width: '20px' }}
                        />
                        <span style={{ fontSize: '12px' }}>Total Exams</span>
                      </div>
                      <Typography
                        component={'h5'}
                        variant="h5"
                        sx={{ fontWeight: 700 }}
                      >
                        {data.totalExams}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        padding: '1rem',
                        width: '100px',
                        height: '50px',
                        borderRadius: '10px',
                        backgroundColor: '#f6f6f6',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignContent: 'center',
                          gap: '0.5rem',
                        }}
                      >
                        <img
                          src="/sand-clock.jpeg"
                          style={{ height: '20px', width: '20px' }}
                        />
                        <span style={{ fontSize: '12px' }}>Published Exams</span>
                      </div>
                      <Typography
                        component={'h5'}
                        variant="h5"
                        sx={{ fontWeight: 700 }}
                      >
                        {data.totalPublishedExams}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        padding: '1rem',
                        width: '100px',
                        height: '50px',
                        borderRadius: '10px',
                        backgroundColor: '#f6f6f6',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignContent: 'center',
                          gap: '0.5rem',
                        }}
                      >
                        <img
                          src="/sand-clock.jpeg"
                          style={{ height: '20px', width: '20px' }}
                        />
                        <span style={{ fontSize: '12px' }}>Draft Exams</span>
                      </div>
                      <Typography
                        component={'h5'}
                        variant="h5"
                        sx={{ fontWeight: 700 }}
                      >
                        {data.totalDraftExams}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        padding: '1rem',
                        width: '100px',
                        height: '50px',
                        borderRadius: '10px',
                        backgroundColor: '#f6f6f6',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignContent: 'center',
                          gap: '0.5rem',
                        }}
                      >
                        <img
                          src="/sand-clock.jpeg"
                          style={{ height: '20px', width: '20px' }}
                        />
                        <span style={{ fontSize: '12px' }}>Free Exams</span>
                      </div>
                      <Typography
                        component={'h5'}
                        variant="h5"
                        sx={{ fontWeight: 700 }}
                      >
                        {data.totalFreeExams}
                      </Typography>
                    </Box>{' '}
                    <Box
                      sx={{
                        padding: '1rem',
                        width: '100px',
                        height: '50px',
                        borderRadius: '10px',
                        backgroundColor: '#f6f6f6',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignContent: 'center',
                          gap: '0.5rem',
                        }}
                      >
                        <img
                          src="/sand-clock.jpeg"
                          style={{ height: '20px', width: '20px' }}
                        />
                        <span style={{ fontSize: '12px' }}>Premium Exams</span>
                      </div>
                      <Typography
                        component={'h5'}
                        variant="h5"
                        sx={{ fontWeight: 700 }}
                      >
                        {data.totalPremiumExams}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box style={{ marginTop: '1rem' }}>
                  <Typography
                    sx={{
                      color: '#1c76bb',
                      fontSize: '18px',
                      fontWeight: '600',
                    }}
                  >
                    Test Series
                  </Typography>
                  <Box
                    style={{
                      display: 'flex',
                      gap: '0.5rem',
                    }}
                  >
                    {/* #f6f6f6 */}
                    <Box
                      sx={{
                        padding: '1rem',
                        width: '100px',
                        height: '50px',
                        borderRadius: '10px',
                        backgroundColor: '#f6f6f6',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignContent: 'center',
                          gap: '0.5rem',
                        }}
                      >
                        <img
                          src="/sand-clock.jpeg"
                          style={{ height: '20px', width: '20px' }}
                        />
                        <span style={{ fontSize: '12px' }}>Total Test Series</span>
                      </div>
                      <Typography
                        component={'h5'}
                        variant="h5"
                        sx={{ fontWeight: 700 }}
                      >
                        {data.totalTests}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        padding: '1rem',
                        width: '100px',
                        height: '50px',
                        borderRadius: '10px',
                        backgroundColor: '#f6f6f6',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignContent: 'center',
                          gap: '0.5rem',
                        }}
                      >
                        <img
                          src="/sand-clock.jpeg"
                          style={{ height: '20px', width: '20px' }}
                        />
                        <span style={{ fontSize: '12px' }}>Published Tests</span>
                      </div>
                      <Typography
                        component={'h5'}
                        variant="h5"
                        sx={{ fontWeight: 700 }}
                      >
                        {data.totalPublishedTests}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        padding: '1rem',
                        width: '100px',
                        height: '50px',
                        borderRadius: '10px',
                        backgroundColor: '#f6f6f6',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignContent: 'center',
                          gap: '0.5rem',
                        }}
                      >
                        <img
                          src="/sand-clock.jpeg"
                          style={{ height: '20px', width: '20px' }}
                        />
                        <span style={{ fontSize: '12px' }}>Draft Tests</span>
                      </div>
                      <Typography
                        component={'h5'}
                        variant="h5"
                        sx={{ fontWeight: 700 }}
                      >
                        {data.totalDraftTests}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        padding: '1rem',
                        width: '100px',
                        height: '50px',
                        borderRadius: '10px',
                        backgroundColor: '#f6f6f6',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignContent: 'center',
                          gap: '0.5rem',
                        }}
                      >
                        <img
                          src="/sand-clock.jpeg"
                          style={{ height: '20px', width: '20px' }}
                        />
                        <span style={{ fontSize: '12px' }}>Free Tests</span>
                      </div>
                      <Typography
                        component={'h5'}
                        variant="h5"
                        sx={{ fontWeight: 700 }}
                      >
                        {data.totalFreeTests}
                      </Typography>
                    </Box>{' '}
                    <Box
                      sx={{
                        padding: '1rem',
                        width: '100px',
                        height: '50px',
                        borderRadius: '10px',
                        backgroundColor: '#f6f6f6',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignContent: 'center',
                          gap: '0.5rem',
                        }}
                      >
                        <img
                          src="/sand-clock.jpeg"
                          style={{ height: '20px', width: '20px' }}
                        />
                        <span style={{ fontSize: '12px' }}>Premium Tests</span>
                      </div>
                      <Typography
                        component={'h5'}
                        variant="h5"
                        sx={{ fontWeight: 700 }}
                      >
                        {data.totalPremiumTests}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Typography>No Dashboard</Typography>
      )}
    </>
  );
}

export default InstitutionDashboard;
