/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const API_URL = `${process.env.REACT_APP_BACKEND_API_PATH}/api`;
const LOCALSTORAGE_APP_KEY = `${process.env.REACT_APP_LOCALSTORAGE_KEY}`;

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
  }),
  tagTypes: ['Auth'],
  endpoints: (builder) => ({
    createStudentUser: builder.mutation({
      query: (AuthDetails) => ({
        url: '/user/register',
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: {
          name: AuthDetails.name,
          email: AuthDetails.email,
          phone_number: AuthDetails.phoneNumber,
          password: AuthDetails.password,
          password_confirmation: AuthDetails.confirmPassword,
        },
      }),
      invalidatesTags: ['Auth'],
    }),
    createEducatorUser: builder.mutation({
      query: (AuthDetails) => ({
        url: '/user/educator/register',
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: {
          name: AuthDetails.name,
          phone_number: AuthDetails.phoneNumber,
          field: AuthDetails.field,
          subject: AuthDetails.subject,
          email: AuthDetails.email,
          password: AuthDetails.password,
          password_confirmation: AuthDetails.confirmPassword,
        },
      }),
      invalidatesTags: ['Auth'],
    }),
    createInstitutionUser: builder.mutation({
      query: (AuthDetails) => ({
        url: '/user/institution/register',
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: {
          name: AuthDetails.name,
          institution_name: AuthDetails.institutionName,
          phone_number: AuthDetails.phoneNumber,
          contact_person: AuthDetails.contactPerson,
          email: AuthDetails.email,
          password: AuthDetails.password,
          password_confirmation: AuthDetails.confirmPassword,
        },
      }),
      invalidatesTags: ['Auth'],
    }),
    resetPasswordLink: builder.mutation({
      query: (AuthDetails) => ({
        url: '/user/password/reset/email',
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: {
          email: AuthDetails.email,
        },
      }),
      invalidatesTags: ['Auth'],
    }),
    resetPassword: builder.mutation({
      query: (AuthDetails) => ({
        url: '/user/password/reset',
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: {
          email: AuthDetails.email,
          password: AuthDetails.password,
          password_confirmation: AuthDetails.password_confirmation,
          token: AuthDetails.token,
        },
      }),
      invalidatesTags: ['Auth'],
    }),
  }),
});

export const {
  useCreateStudentUserMutation,
  useCreateEducatorUserMutation,
  useCreateInstitutionUserMutation,
  useResetPasswordLinkMutation,
  useResetPasswordMutation,
} = authApi;
