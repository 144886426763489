/* eslint-disable no-unused-vars */
import { Typography, Grid, Card, CardContent } from '@mui/material';
import moment from 'moment';
import { useParams } from 'react-router';
import { useGetVideoViolationQuery } from '../../../../app/services/CoursesApi';
import DataLoader from '../../../../components/DataLoader';
import HlsJs from '../../HlsJs';

function SingleVideoViolation() {
  const params = useParams();

  const { data, isLoading, isSuccess } = useGetVideoViolationQuery({
    id: parseInt(params.Id, 10),
  });

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  return (
    <>
      {' '}
      {isSuccess && data.videoViolation ? (
        <Grid container spacing={1}>
          <Grid item xs="12" sm="12" md="1" lg="1" xl="1" align="left"></Grid>

          <Grid item xs="12" sm="12" md="6" lg="6" xl="6" align="left">
            {data.videoViolation.video?.video_url ? (
              <HlsJs vidSrc={data.videoViolation.video.video_url} />
            ) : null}

            <Card>
              <CardContent sx={{ overflowWrap: 'anywhere' }}>
                <Typography component="h2" variant="h5">
                  Reported Video Violation
                </Typography>
                <Typography variant="subtitle1">
                  Video :<strong>{data.videoViolation.video.title}</strong>
                </Typography>
                <Typography variant="subtitle1" paragraph>
                  Issue: {data.videoViolation.issue_type}
                </Typography>
                <Typography variant="subtitle1" paragraph>
                  Issue Details: {data.videoViolation.issue_details}
                </Typography>

                <Typography variant="subtitle1" paragraph>
                  Reported By: {data.videoViolation.reporter.email}
                </Typography>

                <Typography variant="subtitle1" paragraph>
                  {moment(data.videoViolation.created_at).format(
                    'Do MMM YY hh:mm A'
                  )}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      ) : (
        <p>No video violation found</p>
      )}
    </>
  );
}

export default SingleVideoViolation;
