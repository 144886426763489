import { Button, Box, TextField } from '@mui/material';
import { useCreateSubCategoryMutation } from '../../../app/services/CategoriesApi';

function CreateNewSubCategory(props) {
  const { categoryId } = props;

  const [createSubCategory, result] = useCreateSubCategoryMutation();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const subCategoryData = {
      title: data.get('title'),
      categoryId: categoryId,
    };
    await createSubCategory(subCategoryData);
  };
  return result.data ? (
    result.data.message
  ) : (
    <>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="title"
          label="Sub Category Title"
          name="title"
          autoComplete="title"
          autoFocus
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={result.isLoading || result.isSuccess}
        >
          Add Sub Category
        </Button>
      </Box>
    </>
  );
}

export default CreateNewSubCategory;
