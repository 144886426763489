import { Grid } from '@mui/material';
import { useCourseViolationsQuery } from '../../../../app/services/CoursesApi';
import DataLoader from '../../../../components/DataLoader';
import CourseViolationsList from './CourseViolationsList';

function CourseViolationManager() {
  const { data, isLoading, isSuccess } = useCourseViolationsQuery();

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
          {isSuccess && data.courseViolations ? (
            <CourseViolationsList courseViolations={data.courseViolations} />
          ) : (
            <p>No Course Violations found</p>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default CourseViolationManager;
