/* eslint-disable no-unused-vars */
import { Button, Box, TextField } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useUpdateStudentProfileMutation } from '../../../app/services/StudentApi';
import MomentUtils from '@date-io/moment';

function UpdateStudentProfile(props) {
  const { studentProfile } = props;

  const { enqueueSnackbar } = useSnackbar();
  const [updatestudentProfile, result] = useUpdateStudentProfileMutation();
  const [dob, setDob] = useState(
    studentProfile.date_of_birth ? moment(studentProfile.date_of_birth) : moment()
  );

  const handleDobChange = (newValue) => {
    setDob(newValue);
  };
  const validateData = (studentProfileData) => {
    let err = true;

    if (!studentProfileData.firstName) {
      enqueueSnackbar('Please provide all the required fields', {
        variant: 'error',
      });
    } else {
      err = false;
    }

    return err;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const studentProfileData = {
      id: studentProfile.id,
      firstName: data.get('firstName'),
      lastName: data.get('lastName'),
      gender: data.get('gender'),
      school: data.get('school'),
      location: data.get('location'),
      address: data.get('address'),
      dateOfBirth: dob.format(),
    };

    const res = validateData(studentProfileData);
    if (!res) {
      updatestudentProfile(studentProfileData);
    }
  };

  return result.data ? (
    result.data.message
  ) : (
    <>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="firstName"
          label=" First Name"
          name="firstName"
          autoComplete="firstName"
          defaultValue={studentProfile.first_name}
          autoFocus
        />
        <TextField
          margin="normal"
          fullWidth
          id="lastName"
          label=" Last Name"
          name="lastName"
          autoComplete="lastName"
          defaultValue={studentProfile.last_name}
          autoFocus
        />
        <Box sx={{ marginTop: 'inherit' }}>
          <LocalizationProvider dateAdapter={MomentUtils}>
            <DatePicker
              label="Date Of Birth"
              value={dob}
              onChange={handleDobChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Box>

        <TextField
          margin="normal"
          fullWidth
          id="gender"
          label=" Gender"
          name="gender"
          autoComplete="gender"
          defaultValue={studentProfile.gender}
          autoFocus
        />
        <TextField
          margin="normal"
          fullWidth
          id="school"
          label=" School"
          name="school"
          autoComplete="school"
          defaultValue={studentProfile.school}
          autoFocus
          multiline
          rows="2"
        />
        <TextField
          margin="normal"
          fullWidth
          id="location"
          label=" Location"
          name="location"
          autoComplete="location"
          defaultValue={studentProfile.location}
          autoFocus
        />

        <TextField
          margin="normal"
          fullWidth
          id="address"
          label="Address"
          name="address"
          autoComplete="address"
          autoFocus
          multiline
          defaultValue={studentProfile.address}
          rows="2"
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={result.isLoading || result.isSuccess}
        >
          Update Profile
        </Button>
      </Box>
    </>
  );
}

export default UpdateStudentProfile;
