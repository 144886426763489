import { ThemeProvider } from '@emotion/react';
import {
  Button,
  Checkbox,
  Container,
  CssBaseline,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  Box,
  createTheme,
  Avatar,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import {
  signIn,
  signInWithGoogle,
  toggleforgotPasswordButton,
} from '../../../app/slices/authSlice';
import GoogleLogin from 'react-google-login';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useLocation } from 'react-router';
import styled from '@emotion/styled';

const theme = createTheme();

// eslint-disable-next-line no-undef
const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

// Sign In Page with ui form
function StudentSignInPage() {
  const { enqueueSnackbar } = useSnackbar();

  // const { changeView } = props;
  const dispatch = useDispatch();
  const [verifyButton, setVerifyButton] = useState(false);
  const { isSignedIn } = useSelector((state) => state.auth);
  const location = useLocation();
  const WhiteBorderTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'rgba(255, 255, 255, 1)', // Background color
      borderRadius: '6px',
      '&:hover fieldset': {
        borderColor: 'white', // Change the border color on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white', // Change the border color when focused
      },
      '& fieldset': {
        borderColor: 'white', // Default border color
      },
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: 'black', // Label color when focused
    },
  });
  const ValidateEmail = (mail) => {
    // eslint-disable-next-line no-useless-escape
    const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (mail.match(mailFormat)) {
      return true;
    }
    return false;
  };
  const validateData = (authData) => {
    let err = true;

    if (!authData.email || !authData.password) {
      enqueueSnackbar('Both Email and Password are required', { variant: 'error' });
    } else if (!ValidateEmail(authData.email)) {
      enqueueSnackbar('Please enter a valid Email address', { variant: 'error' });
    } else if (authData.password.length < 8) {
      enqueueSnackbar('Password length must be at least 8 chars', {
        variant: 'error',
      });
    } else {
      err = false;
    }

    return err;
  };

  // Handle Submit of the form
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const Authdata = {
      email: data.get('email'),
      password: data.get('password'),
    };
    const res = validateData(Authdata);
    if (!res) {
      // Dispatch with signin parameters
      dispatch(signIn(Authdata))
        .unwrap()
        .then((response) => {
          if (response.free === true && response.user.role === 'student') {
            enqueueSnackbar('Signin Success. You are in a free trial period', {
              variant: 'success',
            });
          } else if (response.free === false && response.user.role === 'student') {
            enqueueSnackbar('Signin Success. Your free trial period has ended', {
              variant: 'success',
            });
          } else {
            enqueueSnackbar('Signin Success.', {
              variant: 'success',
            });
          }
        })
        .catch((error) => {
          const errRes = Object.values(error.data);
          errRes.forEach((singleError) => {
            singleError.forEach((message) => {
              if (message === 'EMAIL_NOT_VERIFIED') {
                setVerifyButton(true);
              }
              enqueueSnackbar(message, { variant: 'error' });
            });
          });
        });
    }
  };

  if (isSignedIn) {
    return location.state ? (
      <Navigate replace to={location.state} />
    ) : (
      <Navigate replace to="/" />
    );
  }

  // const openRegisterTab = (e) => changeView(e, 1);

  const toggleForgotPassword = () => {
    dispatch(toggleforgotPasswordButton());
  };

  const responseGoogle = async (response) => {
    const userData = {
      email: response.profileObj.email,
      name: response.profileObj.name,
    };

    dispatch(signInWithGoogle(userData))
      .unwrap()
      .then(() => {
        enqueueSnackbar('Signin Success. ', {
          variant: 'success',
        });
      })
      .catch((error) => {
        const errRes = Object.values(error.data);
        errRes.forEach((singleError) => {
          singleError.forEach((message) => {
            enqueueSnackbar(message, { variant: 'error' });
          });
        });
      });
  };

  const responseErrorGoogle = (response) => {
    console.log(response);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            border: '2px solid #60696e',
            borderRadius: '10px',
            marginTop: '5rem',
          }}
        >
          <CssBaseline />

          <Typography
            style={{
              font: 800,
              position: 'relative',
              top: '-3rem',
              color: '#f6b605',
              fontSize: '20px',
            }}
            align="center"
          >
            Transforming Education In To Digital
          </Typography>
          <Box
            sx={{
              marginTop: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <>
              <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography
                component="h1"
                variant="h5"
                style={{
                  color: '#f6b605',
                }}
              >
                Student Login
              </Typography>
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <WhiteBorderTextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  size="small"
                />
                <WhiteBorderTextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  size="small"
                />
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign In
                </Button>
                {verifyButton ? (
                  <div>
                    <Link to="/resend/email/verification">Verify Your Email</Link>
                  </div>
                ) : null}
                <Grid container>
                  <Grid item xs>
                    <Button sx={{ color: '#fff' }} onClick={toggleForgotPassword}>
                      Forgot password?
                    </Button>
                  </Grid>
                  <Grid item xs>
                    {/* <Typography>
                      <Button onClick={openRegisterTab}>Create New Account</Button>
                    </Typography> */}
                  </Grid>
                </Grid>
                <Typography
                  style={{ color: '#9799A3', font: '800 250%' }}
                  align="center"
                >
                  or
                </Typography>
                <Grid container align="center" sx={{ padding: '1rem' }}>
                  <Grid item xs={12}>
                    <GoogleLogin
                      clientId={googleClientId}
                      buttonText="LOGIN WITH GOOGLE"
                      onSuccess={responseGoogle}
                      onFailure={responseErrorGoogle}
                      theme="dark"
                    />
                  </Grid>
                </Grid>
              </Box>
            </>
          </Box>
        </Container>
      </ThemeProvider>
    </>
  );
}

export default StudentSignInPage;
