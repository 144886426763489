/* eslint-disable no-unused-vars */
import { Button, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { usePublishedSubscriptionPlanQuery } from '../../../app/services/SunscriptionPlansApi';

function DisplaySubscriptionPlan() {
  const { data, isLoading, isSuccess } = usePublishedSubscriptionPlanQuery();

  return (
    <Grid container>
      <Grid item md={2}></Grid>
      <Grid item md={8} sx={{ background: 'lightcyan' }}>
        {isSuccess && data.subscriptionPlan ? (
          <Box
            sx={{ ml: '20px', mt: '20px', height: '100vh', alignItems: 'center' }}
          >
            <Typography variant="h4">{data.subscriptionPlan.title}</Typography>
            <Typography>{data.subscriptionPlan.description}</Typography>

            <Button variant="contained" color="secondary">
              Start Subscription
            </Button>
          </Box>
        ) : (
          <Typography>No subscription Plan Found</Typography>
        )}
      </Grid>
      <Grid item md={8}></Grid>

      {/* <Grid
        item
        xs={false}
        sm={4}
        md={6}
        sx={{
          backgroundImage:
            'url(https://images.unsplash.com/photo-1521898284481-a5ec348cb555?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80)',
        }}
      ></Grid> */}
    </Grid>
  );
}
export default DisplaySubscriptionPlan;
