/* eslint-disable no-unused-vars */
import {
  Avatar,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import React from 'react';
import EnrolledCourseTabPanel from '../courses/student/EnrolledCourseTabPanel';
import EducatorFollower from './EducatorFollower';
import InstitutionFollower from './InstitutionFollower';
import StudentFollower from './StudentFollower';

function UserFollowers() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

  return (
    <>
      <Grid container sx={{ justifyContent: 'center' }}>
        <Grid item xl="10" lg="10" md="10" sm="12" xs="12">
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
            variant={window.screen.width < 600 ? `scrollable` : `standard`}
          >
            <Tab
              id="one"
              label="Educators"
              {...a11yProps(0)}
              sx={{
                justifyContent: 'flex-start',
                fontSize: 14,
                alignItems: 'flex-start',
                textAlign: 'start',
                textTransform: 'capitalize',
              }}
            ></Tab>
            <Tab
              id="one"
              label="Institutions"
              {...a11yProps(0)}
              sx={{
                justifyContent: 'flex-start',
                fontSize: 14,
                alignItems: 'flex-start',
                textAlign: 'start',
                textTransform: 'capitalize',
              }}
            ></Tab>
            <Tab
              id="one"
              label="Students"
              {...a11yProps(0)}
              sx={{
                justifyContent: 'flex-start',
                fontSize: 14,
                alignItems: 'flex-start',
                textAlign: 'start',
                textTransform: 'capitalize',
              }}
            ></Tab>
          </Tabs>
          <EnrolledCourseTabPanel value={value} index={0}>
            <EducatorFollower />
          </EnrolledCourseTabPanel>{' '}
          <EnrolledCourseTabPanel value={value} index={1}>
            <InstitutionFollower />
          </EnrolledCourseTabPanel>{' '}
          <EnrolledCourseTabPanel value={value} index={2}>
            <StudentFollower />
          </EnrolledCourseTabPanel>
        </Grid>
      </Grid>
    </>
  );
}
export default UserFollowers;
