/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react';
import Hls from 'hls.js';
import Plyr from 'plyr';
import { useUpdateVideoProgressMutation } from '../../app/services/StudentCoursesApi';
import axios from 'axios';
import { useSelector } from 'react-redux';
// eslint-disable-next-line no-undef
const API_URL = `${process.env.REACT_APP_BACKEND_API_PATH}/api`;

export const HlsJs = (props) => {
  const { vidSrc, enrolId, vidId, currentVidProgress, paramId } = props;
  const { token } = useSelector((state) => state.auth);
  const [vidProgress, setVidProgress] = useState(props.vidProgress || []);

  const [updateVideoProgress, result] = useUpdateVideoProgressMutation();

  const upDate = (data) => {
    if (vidProgress.length >= 1) {
      if (paramId !== vidId) {
        if (vidProgress.find((element) => element.vid === data.videoId)) {
          const updatedVidProgress = vidProgress.map((singleVidProgress) => {
            if (singleVidProgress.vid === data.videoId) {
              return {
                ...singleVidProgress,
                timeStamp: Math.floor(data.currentTime),
              };
            }
            return singleVidProgress;
          });

          updateVideoProgress({
            enrol_id: data.enrolId,
            vidProgress: updatedVidProgress,
          });
        } else {
          const updatedProg = [
            ...vidProgress,
            { vid: data.videoId, timeStamp: Math.floor(data.currentTime) },
          ];
          // setVidProgress([
          //   ...vidProgress,
          //   { vid: data.videoId, timeStamp: Math.floor(data.currentTime) },
          // ]);
          updateVideoProgress({
            enrol_id: data.enrolId,
            vidProgress: updatedProg,
          });
        }
      }
    }
    if (vidProgress.length === 0) {
      updateVideoProgress({
        enrol_id: data.enrolId,
        vidProgress: [
          { vid: data.videoId, timeStamp: Math.floor(data.currentTime) },
        ],
      });
    }
  };

  const upDateOnExit = (data) => {
    if (vidProgress.length >= 1) {
      if (vidProgress.find((element) => element.vid === data.videoId)) {
        const updatedVidProgress = vidProgress.map((singleVidProgress) => {
          if (singleVidProgress.vid === data.videoId) {
            return {
              ...singleVidProgress,
              timeStamp: Math.floor(data.currentTime),
            };
          }
          return singleVidProgress;
        });

        updateVideoProgress({
          enrol_id: data.enrolId,
          vidProgress: updatedVidProgress,
        });
      } else {
        const updatedProg = [
          ...vidProgress,
          { vid: data.videoId, timeStamp: Math.floor(data.currentTime) },
        ];

        updateVideoProgress({
          enrol_id: data.enrolId,
          vidProgress: updatedProg,
        });
      }
    }
    if (vidProgress.length === 0) {
      updateVideoProgress({
        enrol_id: data.enrolId,
        vidProgress: [
          { vid: data.videoId, timeStamp: Math.floor(data.currentTime) },
        ],
      });
    }
  };

  function updateQuality(newQuality) {
    if (newQuality === 0) {
      window.hls.currentLevel = -1; //Enable AUTO quality if option.value = 0
    } else {
      window.hls.levels.forEach((level, levelIndex) => {
        if (level.height === newQuality) {
          window.hls.currentLevel = levelIndex;
        }
      });
    }
  }

  React.useEffect(() => {
    if (Hls.isSupported) {
      const video = document.getElementById('video');
      const videoSrc = vidSrc;
      const defaultOptions = {};
      const hls = new Hls();
      hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
        const allAvailableQualities = hls.levels.map((level) => level.height);
        defaultOptions.controls = [
          'play-large',
          'restart',
          'rewind',
          'play',
          'fast-forward',
          'progress',
          'current-time',
          'duration',
          'mute',
          'volume',
          'captions',
          'settings',
          'pip',
        ];

        defaultOptions.quality = {
          default: allAvailableQualities[0],
          options: allAvailableQualities,
          forced: true,

          onChange: (e) => updateQuality(e),
        };
        defaultOptions.i18n = {
          qualityLabel: {
            0: 'Auto',
          },
        };
        const player = new Plyr(video, defaultOptions);
        window.Plyr = player;

        window.Plyr.on('loadeddata', (event) => {
          const instance = event.detail.plyr;

          if (currentVidProgress) {
            window.Plyr.currentTime = currentVidProgress.timeStamp;
          }

          instance.muted = true;
          instance.play();
        });
      });

      hls.on(Hls.Events.LEVEL_SWITCHED, function (event, data) {
        var span = document.querySelector(
          ".plyr__menu__container [data-plyr='quality'][value='0'] span"
        );
        if (hls.autoLevelEnabled) {
          span.innerHTML = `AUTO (${hls.levels[data.level].height}p)`;
        } else {
          span.innerHTML = `AUTO`;
        }
      });

      hls.loadSource(videoSrc);

      hls.attachMedia(video);
      window.hls = hls;
    }
  }, [vidId]);

  React.useEffect(() => {
    return () => {
      if (window.Plyr && vidId && enrolId) {
        if (window.Plyr.currentTime) {
          upDate({
            videoId: vidId,
            currentTime: window.Plyr.currentTime ? window.Plyr.currentTime : 0,
            enrolId: enrolId,
          });

          // window.hls.destroy();
          // window.Plyr.destroy();
        }
      }
    };
  }, []);

  React.useEffect(() => {
    const cleanup = () => {
      if (window.Plyr && vidId && enrolId) {
        if (window.Plyr.currentTime) {
          upDateOnExit({
            videoId: vidId,
            currentTime: window.Plyr.currentTime ? window.Plyr.currentTime : 0,
            enrolId: enrolId,
          });

          window.hls.destroy();
          window.Plyr.destroy();
        }
      }
    };

    window.addEventListener('beforeunload', cleanup);

    return () => {
      window.removeEventListener('beforeunload', cleanup);
    };
  }, []);

  return (
    <div>
      <video controls id="video" autoPlay="true"></video>{' '}
    </div>
  );
};

export default HlsJs;
