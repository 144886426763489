import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  Grid,
  Box,
  CardMedia,
  CardContent,
} from '@mui/material';
import React from 'react';
import { useParams } from 'react-router';
import { useTestQuery } from '../../../app/services/TestsApi';
import DataLoader from '../../../components/DataLoader';
import CreateNewTestSubject from './testSubjects/CreateNewTestSubject';
import TestSubjectList from './testSubjects/TestSubjectList';
import UpdateTest from './UpdateTest';
import UploadTestImage from './UploadTestImage';

function SingleTest() {
  const params = useParams();

  const { data, isLoading, isSuccess } = useTestQuery({
    id: parseInt(params.Id, 10),
  });
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  return (
    <>
      {isSuccess && data.test ? (
        <>
          <Grid container spacing={1}>
            <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
              <Box sx={{ textAlign: 'center' }}>
                <Button variant="contained" onClick={handleClickOpen1}>
                  Update Test
                </Button>
                <>
                  <Dialog
                    open={open1}
                    onClose={handleClose1}
                    fullWidth
                    maxWidth="sm"
                  >
                    <DialogTitle> Update Test</DialogTitle>
                    <DialogContent>
                      <UpdateTest test={data.test} />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose1}>Close</Button>
                    </DialogActions>
                  </Dialog>
                </>
                &nbsp;
                <Button variant="contained" onClick={handleClickOpen2}>
                  {data.test.image_url ? 'Change Test Image' : 'Upload Test Image'}
                </Button>
                <>
                  <Dialog
                    open={open2}
                    onClose={handleClose2}
                    fullWidth
                    maxWidth="sm"
                  >
                    <DialogTitle>
                      {data.test.image_url
                        ? 'Change test Image'
                        : 'Upload test Image'}
                    </DialogTitle>
                    <DialogContent>
                      <UploadTestImage
                        imgUrl={data.test.image_url}
                        testId={data.test.id}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose2}>Close</Button>
                    </DialogActions>
                  </Dialog>
                </>
                &nbsp;
                <Button variant="contained" onClick={handleClickOpen}>
                  Create New Test Subject
                </Button>
                <>
                  <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                    <DialogTitle> Create New Test Subject</DialogTitle>
                    <DialogContent>
                      <CreateNewTestSubject testId={data.test.id} />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose}>Close</Button>
                    </DialogActions>
                  </Dialog>
                </>
              </Box>
            </Grid>
            <Grid item xs="12" sm="12" md="8" lg="8" xl="8" align="left">
              <>
                <Card sx={{ display: 'flex' }}>
                  <CardMedia
                    component="img"
                    sx={{ width: 340, display: { xs: 'none', sm: 'block' } }}
                    image={
                      data.test.image_url ||
                      `https://images.unsplash.com/photo-1516397281156-ca07cf9746fc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80`
                    }
                  />
                  <CardContent sx={{ flex: 1, overflowWrap: 'anywhere' }}>
                    <Typography component="h2" variant="h5">
                      <strong>{data.test.title}</strong>
                    </Typography>

                    <Typography variant="subtitle1" color="text.secondary">
                      {/* <LanguageIcon /> */}
                      Language: &nbsp;
                      {data.test.language}
                    </Typography>
                    {data.test.cost ? (
                      <Typography variant="subtitle1" color="text.secondary">
                        Cost: &nbsp; ₹{data.test.cost}
                      </Typography>
                    ) : (
                      <Typography>Free Test</Typography>
                    )}
                  </CardContent>
                </Card>

                <Card>
                  <CardContent sx={{ flex: 1, overflowWrap: 'anywhere' }}>
                    <Typography variant="subtitle1" paragraph>
                      Test Description: {data.test.description}
                    </Typography>
                  </CardContent>
                </Card>
              </>
            </Grid>
            <Grid item xs="12" sm="12" md="4" lg="4" xl="4" align="left">
              {data.test.test_subjects && data.test.test_subjects.length ? (
                <TestSubjectList testSubjects={data.test.test_subjects} />
              ) : (
                <p>No Test Subjects Found</p>
              )}
            </Grid>
          </Grid>
        </>
      ) : (
        <p>No Test found</p>
      )}
    </>
  );
}

export default SingleTest;
