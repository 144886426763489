/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BaseApi } from './BaseApi';

const API_URL = `${process.env.REACT_APP_BACKEND_API_PATH}/api`;
const LOCALSTORAGE_APP_KEY = `${process.env.REACT_APP_LOCALSTORAGE_KEY}`;

export const testsApi = BaseApi.injectEndpoints({
  reducerPath: 'testsApi',
  endpoints: (builder) => ({
    tests: builder.query({
      query: () => ({
        url: '/tests',
        method: 'GET',
      }),
      providesTags: ['Test'],
    }),
    createTest: builder.mutation({
      query: (details) => ({
        url: '/test/create',
        method: 'POST',

        body: {
          title: details.title,
          description: details.description,
          sub_category_id: details.sub_category_id,
          language: details.language,
          cost: details.cost,
          level: details.level,
        },
      }),
      invalidatesTags: ['Test'],
    }),
    test: builder.query({
      query: (details) => ({
        url: `/test/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['Test'],
    }),
    updateTest: builder.mutation({
      query: (details) => ({
        url: `/test/update/${details.id}`,
        method: 'POST',

        body: {
          title: details.title,
          description: details.description,
          sub_category_id: details.sub_category_id,
          language: details.language,
          start_date: details.start_date,
          end_date: details.end_date,
          educator_id: details.educator_id,
          institution_id: details.institution_id,
          cost: details.cost,
          level: details.level,
        },
      }),
      invalidatesTags: ['Test'],
    }),
    updateTestSatus: builder.mutation({
      query: (details) => ({
        url: '/test/status/update',
        method: 'POST',

        body: {
          status: details.status,
          test_id: details.id,
        },
      }),
      invalidatesTags: ['Test'],
    }),
    deleteTest: builder.mutation({
      query: (details) => ({
        url: `/test/delete/${details.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Test'],
    }),
    // Subjects
    testSubjects: builder.query({
      query: () => ({
        url: '/test/subjects',
        method: 'GET',
      }),
      providesTags: ['TestSubject'],
    }),
    createTestSubject: builder.mutation({
      query: (details) => ({
        url: '/test/subject/create',
        method: 'POST',

        body: {
          title: details.title,
          description: details.description,
          start_time: details.start_time,
          end_time: details.end_time,
          test_id: details.test_id,
        },
      }),
      invalidatesTags: ['Test'],
    }),
    testSubject: builder.query({
      query: (details) => ({
        url: `/test/subject/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['TestSubject'],
    }),
    updateTestSubject: builder.mutation({
      query: (details) => ({
        url: `/test/subject/update/${details.id}`,
        method: 'POST',

        body: {
          title: details.title,
          description: details.description,
          start_time: details.start_time,
          end_time: details.end_time,
          test_id: details.test_id,
        },
      }),
      invalidatesTags: ['TestSubject'],
    }),

    deleteTestSubject: builder.mutation({
      query: (details) => ({
        url: `/test/subject/delete/${details.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Test'],
    }),

    // Topics

    testTopics: builder.query({
      query: () => ({
        url: '/test/subject/topics',
        method: 'GET',
      }),
      providesTags: ['TestTopic'],
    }),
    createTestTopic: builder.mutation({
      query: (details) => ({
        url: '/test/subject/topic/create',
        method: 'POST',

        body: {
          title: details.title,
          description: details.description,
          test_subject_id: details.test_subject_id,
        },
      }),
      invalidatesTags: ['TestSubject'],
    }),
    testTopic: builder.query({
      query: (details) => ({
        url: `/test/subject/topic/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['TestTopic'],
    }),
    updateTestTopic: builder.mutation({
      query: (details) => ({
        url: `/test/subject/topic/update/${details.id}`,
        method: 'POST',

        body: {
          title: details.title,
          description: details.description,
          test_subject_id: details.test_subject_id,
        },
      }),
      invalidatesTags: ['TestTopic'],
    }),

    deleteTestTopic: builder.mutation({
      query: (details) => ({
        url: `/test/subject/topic/delete/${details.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['TestSubject'],
    }),

    TestQuestions: builder.query({
      query: () => ({
        url: '/test/subject/topic/questions',
        method: 'GET',
      }),
      providesTags: ['TestQuestion'],
    }),
    createTestQuestion: builder.mutation({
      query: (details) => ({
        url: '/test/subject/topic/question/create',
        method: 'POST',

        body: {
          question: details.question,
          options: details.options,
          test_topic_id: details.test_topic_id,
          severity: details.severity,
          time: details.time,
        },
      }),
      invalidatesTags: ['TestTopic'],
    }),
    TestQuestion: builder.query({
      query: (details) => ({
        url: `/test/subject/topic/question/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['TestQuestion'],
    }),
    updateTestQuestion: builder.mutation({
      query: (details) => ({
        url: `/test/subject/topic/question/update/${details.id}`,
        method: 'POST',

        body: {
          question: details.question,
          options: details.options,
          test_topic_id: details.test_topic_id,
          severity: details.severity,
          time: details.time,
        },
      }),
      invalidatesTags: ['TestQuestion'],
    }),

    deleteTestQuestion: builder.mutation({
      query: (details) => ({
        url: `/test/subject/topic/question/delete/${details.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['TestTopic'],
    }),
  }),
});

export const {
  useTestsQuery,
  useCreateTestMutation,
  useTestQuery,
  useUpdateTestMutation,
  useUpdateTestSatusMutation,
  useTestSubjectsQuery,
  useTestSubjectQuery,
  useCreateTestSubjectMutation,
  useUpdateTestSubjectMutation,
  useDeleteTestMutation,
  useDeleteTestSubjectMutation,
  useTestTopicsQuery,
  useTestTopicQuery,
  useCreateTestTopicMutation,
  useDeleteTestTopicMutation,
  useTestQuestionsQuery,
  useTestQuestionQuery,
  useCreateTestQuestionMutation,
  useUpdateTestQuestionMutation,
  useDeleteTestQuestionMutation,
  useUpdateTestTopicMutation,
} = testsApi;
