/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography, TextField } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import { Box } from '@mui/system';
import { usePublishedCategoriesQuery } from '../app/services/CategoriesApi';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { createSearchParams, useNavigate } from 'react-router-dom';

const firstSlide = [
  {
    description: 'I am a Student',
    img: 'slide1/I-am-student.png',
  },
  {
    description: 'I am a FreeLance Teacher',
    img: 'slide1/I-am-Freelancer.png',
  },
  {
    description: 'I am a Training Institue',
    img: 'slide1/i-am-institute.png',
  },
  {
    description: 'I want to donate Education',
    img: 'slide1/I-want-to-donate-Education.png',
  },
  {
    description: 'I am a Corporate Company',
    img: 'slide1/I-am-corporate-company.png',
  },
  {
    description: 'I am a Parent',
    img: 'slide1/I-am-parent.png',
  },
];
const firstSlide2 = [
  {
    description: 'Academic Success',
    shortDescription: 'Nursery to Class 10',
    img: 'slide2/Academics-Success.png',
  },
  {
    description: 'Entrance Exam Mastery',
    shortDescription: 'NEET, JEE, NDA',
    img: 'slide2/Entrance-Exam-Mastery.png',
  },
  {
    description: 'Government Job Prep',
    shortDescription: 'MPSC, UPSC, SSC',
    img: 'slide2/Gov-Job-Preparation.png',
  },
  {
    description: 'IT Skills and Career',
    shortDescription: 'PHP, SQL, JAVA',
    img: 'slide2/IT-Skills-Career.png',
  },
  {
    description: 'College Support',
    shortDescription: 'BCom, MCom etc',
    img: 'slide2/College-Support.png',
  },
  {
    description: 'Hobby and Interest',
    shortDescription: 'Dance, Music, Sport',
    img: 'slide2/Hobby-Interest.png',
  },
  {
    description: 'Research and Develop',
    shortDescription: 'PHD, MPhil',
    img: 'slide2/Research-and-Develop.png',
  },
  {
    description: 'Language Learning',
    shortDescription: 'English, Spanish, Hindi',
    img: 'slide2/Langugae-Learning.png',
  },
  {
    description: 'Vocational Excellence',
    shortDescription: 'Carpenter, Plumber',
    img: 'slide2/Vocational-Excellence.png',
  },
];

const thirdSlide = {
  mainHeading: 'Discover Learn & Succeed with Us',
  image: 'slide3/schoolingbetter.jpg',
  description:
    "Here, you can explore countless resources, attend live online sessions with experienced teachers, and access a treasure trove of recorded classes. Define your goals, choose your preferred learning language, and receive personalized course recommendations. Whether it's mastering new skills, preparing for exams, or simply expanding your horizons, our platform is your key to success. Join us today and start your journey to a brighter future!",
  subData: {
    heading: 'Make Your Schooling Better',
    title:
      'Academic Excellence from Nursery to 12th Grade.  All you need for successful school performance in one place.',
    points: [
      {
        highlighted: 'Personalized Learning',
        text: 'Choose your school-prescribed textbooks and publications for tailored education.',
      },
      {
        highlighted: 'Comprehensive Resources',
        text: 'Access recorded video lessons for each textbook, covering all subjects from nursery to 12th grade.',
      },
      {
        highlighted: 'Enhanced Understanding',
        text: 'Get solved exercises, questions, and answers for every lesson.',
      },
      {
        highlighted: 'All Boards Supported',
        text: "From ICSE, CBSE, to All state boards, we've got it all",
      },
      {
        highlighted: 'Language Options',
        text: 'Study in your preferred language for a comfortable learning experience.',
      },
      {
        highlighted: 'Schooling from Home',
        text: 'Gain knowledge without the need for in-person school attendance or extra classes.',
      },
      {
        highlighted: 'Self-Paced Learning',
        text: 'Progress at your own pace, ensuring a deeper grasp of the material.',
      },
      {
        highlighted: 'Supporting Success',
        text: 'Elevate your academic journey and excel in your studies with our LMS.',
      },
      {
        highlighted: 'A Complete Solution',
        text: 'All you need for successful school performance in one place.',
      },
    ],
  },
};

const fourthSlide = [
  {
    heading: 'Self-Paced Learning',
    title: 'Study at your own pace with pre-recorded video lessons.',
    image: 'slide4/self-placed.png',
    points: [
      {
        highlighted: 'On-Demand Access',
        text: 'Watch lessons whenever it suits you, allowing for a more personalized experience.',
      },
      {
        highlighted: 'Pause and Rewind',
        text: 'Pause, rewind, and revisit content to ensure you grasp every concept.',
      },
      {
        highlighted: 'Learn Anywhere',
        text: 'Access materials from anywhere, making it convenient for your busy life.',
      },
    ],
  },
  {
    heading: 'Instructor-Based Learning',
    title: 'Participate in live classes, discussions, and Q&A sessions',
    image: 'slide4/instructor-based.jpg',
    points: [
      {
        highlighted: 'Expert Guidance',
        text: 'Learn from experienced instructors who provide mentorship and insights.',
      },
      {
        highlighted: 'Feedback and Support',
        text: 'Get valuable feedback and support from your instructors and peers.',
      },
      {
        highlighted: 'Collaborative Learning',
        text: 'Engage with your instructor and fellow learners for a more interactive experience.',
      },
    ],
  },
  {
    heading: 'Exclusive Webinars with Industry Experts',
    title:
      'Engage in live webinars featuring experts from esteemed institutions like IIT, MIT, and renowned organizations',
    image: 'slide4/webinar.jpg',
    points: [
      {
        highlighted: 'Rich Insights',
        text: 'Gain access to the wealth of experience and knowledge these experts bring.',
      },
      {
        highlighted: 'Real-World Learning',
        text: `Discover practical insights and real-world applications of what you're learning.`,
      },
      {
        highlighted: 'Interactive Sessions',
        text: 'Participate in live Q&A sessions to get answers to your questions.',
      },
      {
        highlighted: 'Networking Opportunities',
        text: 'Connect with experts and like-minded peers in your field.',
      },
      {
        highlighted: 'Stay Updated',
        text: 'Stay ahead of industry trends and advancements with the latest information.',
      },
      {
        highlighted: 'Inspiration and Motivation',
        text: 'Find inspiration and motivation from accomplished professionals.',
      },
      {
        highlighted: 'Enhanced Learning',
        text: 'Elevate your learning experience with these exclusive webinars.',
      },
    ],
  },
];
const fifthSlide = [
  {
    heading: 'Get Help with Your Homework',
    title: 'Get solved exercises, questions, and answers for every lesson.',
    image: 'slide5/get-help-homework.jpg',
    points: [
      {
        highlighted: 'Homework Support',
        text: 'Find help with your homework through our extensive collection of solved exercises and answers.',
      },
      {
        highlighted: 'Quick Solutions',
        text: 'Access answers and explanations for each lesson to aid your understanding.',
      },
      {
        highlighted: 'Efficient Learning',
        text: 'Speed up your homework completion with readily available solutions.',
      },
      {
        highlighted: 'Homework Made Easier',
        text: 'Simplify your homework process with our comprehensive support.',
      },
    ],
  },
  {
    heading: 'Taking Notes in Real Time',
    title:
      'Add live notes while watching video lessons to reinforce your understanding.',
    image: 'slide5/live-notes.jpg',
    points: [
      {
        highlighted: 'Visual Annotations',
        text: 'Highlight key points, jot down questions, and make your learning experience more interactive.',
      },
      {
        highlighted: 'Study Aids',
        text: 'Create a personalized study resource for future reference and exam preparation.',
      },
      {
        highlighted: 'Accessible Anytime',
        text: 'Access your live notes whenever you revisit the video course.',
      },
      {
        highlighted: 'Elevate Your Learning',
        text: 'Make your study sessions more effective and engaging with our live notes feature.',
      },
    ],
  },
  {
    heading: 'Boost Your Scores with Practice Exams',
    title:
      'Benefit from different exam formats and questions to improve your performance',
    image: 'slide5/boost-scores.jpg',
    points: [
      {
        highlighted: 'Collaborative Learning',
        text: 'Multiple teachers post exams, offering diverse learning experiences.',
      },
      {
        highlighted: 'Variety of Assessments',
        text: `Access a range of exams to enhance your understanding of the subject matter.`,
      },
      {
        highlighted: 'Comprehensive Evaluation',
        text: 'Prepare for a well-rounded understanding of the subject through various exams.',
      },
      {
        highlighted: 'Exam Diversity',
        text: 'Our exam feature fosters collaboration and learning diversity for better outcomes.',
      },
    ],
  },
];
const sixthSlide = [
  {
    heading: 'Choose teachers of your Choice',
    title: 'Choose from a wide range of teachers who can help you excel.',
    image: 'slide6/teacher-choice.png',
    points: [
      {
        highlighted: 'Personalized Learning',
        text: 'Find the perfect teacher who matches your unique learning style and preferences.',
      },
      {
        highlighted: 'Expert Guidance',
        text: 'Gain insights and expertise from the instructor that suits your needs.',
      },
      {
        highlighted: 'Personalized Guidance',
        text: 'Get insights and expertise from the teachers you admire.',
      },
      {
        highlighted: 'Consistent Learning',
        text: `Stay updated with your chosen teacher's courses and updates.`,
      },
      {
        highlighted: 'Motivating Connections',
        text: `Stay inspired by connecting with educators you respect.`,
      },
    ],
  },
  {
    heading: 'Follow Your Preferred Instructors',
    title: `Stay informed about your selected teacher's latest courses and updates to enhance your learning journey`,
    image: 'slide6/follow-teacher.jpg',
    points: [
      {
        highlighted: 'Teacher Updates',
        text: `Stay informed about your chosen teacher's latest courses and announcements.`,
      },
      {
        highlighted: 'Enhanced Learning',
        text: 'Be the first to know about new materials and resources.',
      },
      {
        highlighted: 'Engage Actively',
        text: 'Connect with your instructors for a more interactive learning experience.',
      },
      {
        highlighted: 'Continuous Support',
        text: 'Enjoy ongoing assistance to ensure your success.',
      },
      {
        highlighted: 'Elevate Your Learning',
        text: `Keep up with your teacher's courses and updates for a richer educational journey.`,
      },
    ],
  },
  {
    heading: 'Watch Later: Save Your Courses',
    title: 'Browse, Search Collect, Save, and Plan Your Learning',
    image: 'slide6/watch-later.jpg',
    points: [
      {
        highlighted: 'Bookmark Courses',
        text: 'Easily save courses to watch later.',
      },
      {
        highlighted: 'Plan Your Learning',
        text: `Use the "Watch Later" feature to organize your educational journey and set your learning goals.`,
      },
      {
        highlighted: 'Never Miss Out',
        text: `Stay updated on your desired courses, even if you're not ready to watch them immediately.`,
      },
      {
        highlighted: 'Personalized Learning',
        text: 'Make learning decisions that suit your schedule and needs with the help of the "Watch Later" feature.',
      },
      {
        highlighted: 'Your Learning Hub',
        text: '"Watch Later" is your go-to place for course management and educational planning within our app.',
      },
    ],
  },
];
const lastSlide = {
  image: 'lastslide/Explore-Beyond-Acedemics.png',
  heading: 'Explore Beyond Academics',
  title: `Choose from academics, Technologies to hobbies,
      beyond the boundaries of your academic studies
      on our LMS.`,
  description: `Here, you can explore countless
      resources, attend live online
      sessions with experienced teachers,
      and access a treasure trove of
      recorded classes. Define your goals,
      choose your preferred learning
      language, and receive personalized
      course recommendations. Whether
      it's mastering new skills, preparing
      for exams, or simply expanding
      your horizons, our platform is your
      key to success. Join us today and
      start your journey to a brighter
      future!`,
};

export const Slides = () => {
  const { data } = usePublishedCategoriesQuery();
  const [selectedUserType, setSelectedUserType] = useState('');
  const [selectedCourse, setSelectedCourse] = useState('');
  const navigate = useNavigate();

  const [categoryTitle, setCategoryTitle] = useState('');

  const [subCategoryTitle, setSubCategoryTitle] = useState('');
  const [gradeTitle, setGradeTitle] = useState('');
  const [gradeSubjectTitle, setGradeSubjectTitle] = useState('');

  const [selectedCategory, setSelectedCategory] = useState({
    title: '',
    id: null,
    published_sub_categories: [],
  });
  const [selectedSubCategory, setSelectedSubCategory] = useState({
    title: '',
    id: null,
  });

  const [selectedGrade, setSelectedGrade] = useState({
    title: '',
    id: null,
  });

  const [selectedGradeSubject, setSelectedGradeSubject] = useState({
    title: '',
    id: null,
  });

  const getImageBody = (data) => {
    return (
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ alignSelf: 'center' }}>
        <img
          src={data.image}
          alt={data.image}
          style={{
            height: '300px',
            width: '100%',
            objectFit: 'contain',
          }}
        />
      </Grid>
    );
  };
  const getTextBody = (data) => {
    return (
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <Typography
          sx={{
            fontSize: '22px',
            fontWeight: 600,
            color: '#EE3163',
          }}
        >
          {data.heading}
        </Typography>
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: 700,
            color: '#083FAE',
          }}
        >
          {data.title}
        </Typography>
        <div>
          {data.points.map((item, index) => (
            <li key={index}>
              <span
                style={{
                  fontSize: '15px',
                  fontWeight: 600,
                  color: '#6D6161',
                }}
              >
                {item.highlighted}
              </span>{' '}
              :{' '}
              <span
                style={{
                  fontSize: '15px',
                  fontWeight: 400,
                  color: '#6D6161',
                }}
              >
                {item.text}
              </span>
            </li>
          ))}
        </div>
      </Grid>
    );
  };

  const handleSubCategoryChange = (event) => {
    const tempSelectedSubCategory = selectedCategory.published_sub_categories.find(
      (singleSubCategory) => singleSubCategory.id === event.target.value
    );

    setSelectedSubCategory(tempSelectedSubCategory);
    setSubCategoryTitle(event.target.value);
  };

  const handleGradeChange = (event) => {
    const tempSelectedGrade = selectedSubCategory.grades.find(
      (singleGrade) => singleGrade.id === event.target.value
    );

    setSelectedGrade(tempSelectedGrade);
    setGradeTitle(event.target.value);
  };

  const handleGradeSubjectChange = (event) => {
    const tempSelectedGradeSubject = selectedGrade.grade_subjects.find(
      (singleGradeSubject) => singleGradeSubject.id === event.target.value
    );

    setSelectedGradeSubject(tempSelectedGradeSubject);
    setGradeSubjectTitle(event.target.value);
  };

  const handleCourseTypeChange = (desc) => {
    setSelectedCourse(desc);

    const tempSelectedCategory =
      data && data.categories.find((item) => item.title === 'Academics');

    setSelectedCategory(tempSelectedCategory);
    setCategoryTitle('Academics');
  };

  const onGetStarted = () => {
    navigate({
      pathname: 'search',
      search: createSearchParams({
        q: selectedGradeSubject.title || 'Science',
        grade: selectedGrade.title || '',
        offering: ['courses'],
      }).toString(),
    });
  };

  return (
    <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
      <Grid item xs={12} sm={12} md={11} lg={10} xl={10}>
        {selectedUserType !== 'I am a Student' && (
          <Carousel animation="slide" navButtonsAlwaysInvisible={true}>
            <div style={{ height: '100%', width: '100%' }}>
              <Grid container spacing={2}>
                {firstSlide.map((item, i) => (
                  <Grid key={i} item xs={12} sm={6} md={4} lg={4} xl={4}>
                    <Box
                      sx={{
                        border: '0.5px solid grey',
                        cursor: 'pointer',
                      }}
                      onClick={() => setSelectedUserType(item.description)}
                    >
                      <img
                        src={item.img}
                        alt={item.img}
                        style={{
                          width: '100%',
                          height: '200px',
                          objectFit: 'contain',
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: '18px',
                          color: '#000',
                          fontWeight: 600,
                          textAlign: 'center',
                        }}
                      >
                        {item.description}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </div>
          </Carousel>
        )}
        {selectedUserType === 'I am a Student' &&
          selectedCourse !== 'Academic Success' && (
            <Carousel animation="slide" navButtonsAlwaysInvisible={true}>
              <div style={{ height: '100%', width: '100%' }}>
                <Grid container spacing={2}>
                  {firstSlide2.map((item, i) => (
                    <Grid key={i} item xs={12} sm={6} md={4} lg={4} xl={4}>
                      <Box
                        sx={{
                          border: '0.5px solid grey',
                          cursor: 'pointer',
                        }}
                        // onClick={() => setSelectedCourse(item.description)}
                        onClick={() => handleCourseTypeChange(item.description)}
                      >
                        <img
                          src={item.img}
                          alt={item.img}
                          style={{
                            width: '100%',
                            height: '200px',
                            objectFit: 'contain',
                          }}
                        />
                        <Typography
                          sx={{
                            fontSize: '18px',
                            color: '#000',
                            fontWeight: 600,
                            textAlign: 'center',
                          }}
                        >
                          {item.description}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: '15px',
                            color: '#000',
                            fontWeight: 500,
                            textAlign: 'center',
                          }}
                        >
                          {item.shortDescription}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </Carousel>
          )}
        {selectedCourse === 'Academic Success' && (
          <Carousel
            autoPlay={false}
            animation="slide"
            navButtonsAlwaysVisible={true}
            cycleNavigation={false}
          >
            <Grid container spacing={2} sx={{ height: '100%' }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography
                  sx={{
                    fontSize: '22px',
                    fontWeight: 600,
                    color: '#EE3163',
                  }}
                >
                  {thirdSlide.mainHeading}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '15px',
                    fontWeight: 500,
                    color: '#6D6161',
                    marginTop: '1rem',
                  }}
                >
                  {thirdSlide.description}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <img
                  src={thirdSlide.image}
                  alt={thirdSlide.image}
                  style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'contain',
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Typography
                  sx={{
                    fontSize: '22px',
                    fontWeight: 600,
                    color: '#EE3163',
                  }}
                >
                  {thirdSlide.subData.heading}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '15px',
                    fontWeight: 700,
                    color: '#083FAE',
                  }}
                >
                  {thirdSlide.subData.title}
                </Typography>
                <div style={{ marginTop: '2rem' }}>
                  {thirdSlide.subData.points.map((item, index) => (
                    <li key={index}>
                      <span
                        style={{
                          fontSize: '15px',
                          fontWeight: 600,
                          color: '#6D6161',
                        }}
                      >
                        {item.highlighted}
                      </span>{' '}
                      :{' '}
                      <span
                        style={{
                          fontSize: '15px',
                          fontWeight: 400,
                          color: '#6D6161',
                        }}
                      >
                        {item.text}
                      </span>
                    </li>
                  ))}
                </div>
              </Grid>
            </Grid>
            <Grid container>
              {fourthSlide
                .map((item, ind) =>
                  ind % 2 === 0
                    ? [getImageBody(item), getTextBody(item)]
                    : [getTextBody(item), getImageBody(item)]
                )
                .flat()}
            </Grid>
            <Grid container>
              {fifthSlide
                .map((item, ind) =>
                  ind % 2 !== 0
                    ? [getImageBody(item), getTextBody(item)]
                    : [getTextBody(item), getImageBody(item)]
                )
                .flat()}
            </Grid>
            <Grid container>
              {sixthSlide
                .map((item, ind) =>
                  ind % 2 === 0
                    ? [getImageBody(item), getTextBody(item)]
                    : [getTextBody(item), getImageBody(item)]
                )
                .flat()}
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <img
                  src={lastSlide.image}
                  style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'contain',
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Typography
                  sx={{
                    fontSize: '27px',
                    fontWeight: 600,
                    color: '#EE3163',
                  }}
                >
                  {lastSlide.heading}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '18px',
                    fontWeight: 700,
                    color: '#083FAE',
                    marginTop: '1rem',
                  }}
                >
                  {lastSlide.title}
                </Typography>
                <div style={{ width: '100%' }}>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: 500,
                      color: '#6D6161',
                      marginTop: '1rem',
                      width: '80%',
                    }}
                  >
                    {lastSlide.description}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Typography
                  sx={{
                    fontSize: '27px',
                    fontWeight: 600,
                    color: '#EE3163',
                  }}
                >
                  Pick Your Subjects and Class
                </Typography>
                <Typography
                  sx={{
                    fontSize: '18px',
                    fontWeight: 700,
                    color: '#083FAE',
                    marginTop: '1rem',
                  }}
                >
                  Select your Class and subjects to find the perfect lessons for you
                </Typography>
                <Box>
                  {selectedCategory?.id ? (
                    <FormControl sx={{ m: 1, width: '100%' }} size="small">
                      <InputLabel id="demo-select-small-label">
                        Choose Your Board
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small-label"
                        value={subCategoryTitle}
                        placeholder="Choose Your Board"
                        label="Choose Your Board"
                        onChange={handleSubCategoryChange}
                      >
                        {selectedCategory &&
                        selectedCategory.published_sub_categories?.length
                          ? selectedCategory.published_sub_categories.map(
                              (singleSubCategory, index) => (
                                <MenuItem
                                  value={singleSubCategory.id}
                                  key={singleSubCategory.id}
                                >
                                  {singleSubCategory.title}
                                </MenuItem>
                              )
                            )
                          : null}
                      </Select>
                    </FormControl>
                  ) : null}
                  {selectedCategory?.id && selectedSubCategory?.id ? (
                    <FormControl sx={{ m: 1, width: '100%' }} size="small">
                      <InputLabel id="demo-select-small">
                        Choose Your Class
                      </InputLabel>
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={gradeTitle}
                        placeholder="Choose Your Class"
                        label="Choose Your Class"
                        onChange={handleGradeChange}
                      >
                        {selectedCategory &&
                        selectedSubCategory &&
                        selectedSubCategory.grades?.length
                          ? selectedSubCategory.grades.map((singleGrade, index) => (
                              <MenuItem value={singleGrade.id} key={singleGrade.id}>
                                {singleGrade.title}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </FormControl>
                  ) : null}
                  {selectedCategory?.id &&
                  selectedSubCategory?.id &&
                  selectedGrade?.id ? (
                    <FormControl sx={{ m: 1, width: '100%' }} size="small">
                      <InputLabel id="demo-select-small-labe">
                        Choose Your Subject
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-labe"
                        id="demo-select-small-labe"
                        value={gradeSubjectTitle}
                        placeholder="Choose Your Class"
                        label="Choose Your Subject"
                        onChange={handleGradeSubjectChange}
                      >
                        {selectedGrade.grade_subjects?.length
                          ? selectedGrade.grade_subjects.map(
                              (singleGradeSubject, index) => (
                                <MenuItem
                                  value={singleGradeSubject.id}
                                  key={singleGradeSubject.id}
                                >
                                  {singleGradeSubject.title}
                                </MenuItem>
                              )
                            )
                          : null}{' '}
                      </Select>
                    </FormControl>
                  ) : null}
                  <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <Button
                      variant="contained"
                      sx={{
                        width: '200px',
                        borderRadius: '10px',
                        marginTop: '1rem',
                        background: '#1B75BB',
                      }}
                      onClick={() => onGetStarted()}
                    >
                      Get Started
                    </Button>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <img
                  src={lastSlide.image}
                  style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'contain',
                  }}
                />
              </Grid>
            </Grid>
          </Carousel>
        )}
      </Grid>
    </Grid>
  );
};
