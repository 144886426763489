/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BaseApi } from './BaseApi';

const API_URL = `${process.env.REACT_APP_BACKEND_API_PATH}/api`;

export const publicTestsApi = BaseApi.injectEndpoints({
  reducerPath: 'publicTestsApi',
  endpoints: (builder) => ({
    publiTests: builder.query({
      query: () => ({
        url: '/public/tests',
        method: 'GET',
      }),
    }),
    publicTest: builder.query({
      query: (details) => ({
        url: `/public/category/subCategory/test/show/${details.id}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { usePubliTestsQuery, usePublicTestQuery } = publicTestsApi;
