/* eslint-disable no-unused-vars */
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Collapse,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Box,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function DisplayTestSubjectsAndTopics(props) {
  const { testSubjects } = props;

  return (
    <Paper sx={{ m: 2 }}>
      {testSubjects.length >= 1 ? (
        <>
          {testSubjects.map((singleTestSubject, index) => (
            <>
              <Accordion key={singleTestSubject.id}>
                <AccordionSummary
                  expandIcon={<KeyboardArrowDownIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    {index + 1}. {singleTestSubject.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {singleTestSubject.test_topics.length >= 1 &&
                    singleTestSubject.test_topics.map((singleTestTopic, i) => (
                      <>
                        <Accordion key={singleTestTopic.id}>
                          <AccordionSummary
                            expandIcon={<KeyboardArrowDownIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography>
                              {index + 1}. {i + 1}. {singleTestTopic.title}
                            </Typography>
                          </AccordionSummary>
                        </Accordion>
                      </>
                    ))}
                </AccordionDetails>
              </Accordion>
            </>
          ))}
        </>
      ) : null}
    </Paper>
  );
}

export default DisplayTestSubjectsAndTopics;
