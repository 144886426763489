import { Typography } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router';
import { useGradeSubjectQuery } from '../../../app/services/CategoriesApi';
import DataLoader from '../../../components/DataLoader';

function SingleGradeSubject() {
  const params = useParams();

  const { data, isLoading, isSuccess } = useGradeSubjectQuery({
    id: parseInt(params.gradeSubjectId, 10),
  });

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  return (
    <>
      {isSuccess && data.gradeSubject ? (
        <>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{ padding: 4, color: '#233c8d' }}
          >
            Title: {data.gradeSubject.title}
          </Typography>
        </>
      ) : (
        <p>No Grade Subject found</p>
      )}
    </>
  );
}

export default SingleGradeSubject;
