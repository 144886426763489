import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

// Application Copyright in footer
function Copyright(props) {
  return (
    <>
      <Box
        sx={{
          marginTop: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
          {'Copyright © '}
          <Link color="inherit" to="/">
            Gurumavin
          </Link>
          &nbsp;
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      </Box>
    </>
  );
}

export default Copyright;
