import { Rating, Typography } from '@mui/material';

function DisplayTestReview(props) {
  const { testReview } = props;
  return (
    <>
      <Typography>Your Review</Typography>
      <Rating name="read-only" value={testReview.rating} readOnly precision={0.5} />
      <Typography>{testReview.review_text}</Typography>
    </>
  );
}
export default DisplayTestReview;
