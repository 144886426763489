import { Rating, Typography } from '@mui/material';

function DisplayExamReview(props) {
  const { examReview } = props;
  return (
    <>
      <Typography>Your Review</Typography>
      <Rating name="read-only" value={examReview.rating} readOnly precision={0.5} />
      <Typography>{examReview.review_text}</Typography>
    </>
  );
}
export default DisplayExamReview;
