import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  Grid,
  Box,
  CardMedia,
  CardContent,
} from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useParams } from 'react-router';
import { useExamQuery } from '../../../app/services/ExamsApi';
import DataLoader from '../../../components/DataLoader';
import CreateNewQuestion from './questions/CreateNewQuestion';
import QuestionList from './questions/QuestionList';
import UpdateExam from './UpdateExam';
import UploadExamImage from './UploadExamImage';

function SingleExam() {
  const params = useParams();

  const { data, isLoading, isSuccess } = useExamQuery({
    id: parseInt(params.Id, 10),
  });
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  return (
    <>
      {isSuccess && data.exam ? (
        <>
          <Grid container spacing={1}>
            <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
              <Box sx={{ textAlign: 'center' }}>
                <Button variant="contained" onClick={handleClickOpen1}>
                  Update exam
                </Button>
                <>
                  <Dialog open={open1} onClose={handleClose1}>
                    <DialogTitle> Update exam</DialogTitle>
                    <DialogContent>
                      <UpdateExam exam={data.exam} />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose1}>Close</Button>
                    </DialogActions>
                  </Dialog>
                </>
                &nbsp;
                <Button variant="contained" onClick={handleClickOpen2}>
                  {data.exam.image_url ? 'Change exam Image' : 'Upload exam Image'}
                </Button>
                <>
                  <Dialog open={open2} onClose={handleClose2}>
                    <DialogTitle>
                      {data.exam.image_url
                        ? 'Change exam Image'
                        : 'Upload exam Image'}
                    </DialogTitle>
                    <DialogContent>
                      <UploadExamImage
                        imgUrl={data.exam.image_url}
                        examId={data.exam.id}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose2}>Close</Button>
                    </DialogActions>
                  </Dialog>
                </>
                &nbsp;
                <Button variant="contained" onClick={handleClickOpen}>
                  Create New Question
                </Button>
                <>
                  <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                    <DialogTitle> Create New Question</DialogTitle>
                    <DialogContent>
                      <CreateNewQuestion examId={data.exam.id} />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose}>Close</Button>
                    </DialogActions>
                  </Dialog>
                </>
              </Box>
            </Grid>
            <Grid item xs="12" sm="12" md="6" lg="6" xl="6" align="left">
              <>
                <Card sx={{ display: 'flex' }}>
                  <CardMedia
                    component="img"
                    sx={{ width: 340, display: { xs: 'none', sm: 'block' } }}
                    image={
                      data.exam.image_url ||
                      `https://images.unsplash.com/photo-1516397281156-ca07cf9746fc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80`
                    }
                  />
                  <CardContent sx={{ flex: 1, overflowWrap: 'anywhere' }}>
                    <Typography component="h2" variant="h5">
                      <strong>{data.exam.title}</strong>
                    </Typography>
                    {/* <Typography variant="subtitle1" color="text.secondary">
                      Starts from&nbsp;
                      {moment(data.exam.start_date).format('Do MMM YY hh:mm A')}
                    </Typography>{' '}
                    <Typography variant="subtitle1" color="text.secondary">
                      Ends On&nbsp;
                      {moment(data.exam.end_date).format('Do MMM YY hh:mm A')}
                    </Typography> */}
                    <Typography variant="subtitle1">
                      Duration:&nbsp;
                      {moment(data.exam.end_date).diff(
                        moment(data.exam.start_date),
                        'minutes'
                      )}
                      &nbsp; minutes
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary">
                      {/* <LanguageIcon /> */}
                      Language: &nbsp;
                      {data.exam.language}
                    </Typography>
                    {data.exam.cost ? (
                      <Typography variant="subtitle1" color="text.secondary">
                        Cost: &nbsp; ₹{data.exam.cost}
                      </Typography>
                    ) : (
                      <Typography>Free Exam</Typography>
                    )}
                  </CardContent>
                </Card>

                <Card>
                  <CardContent sx={{ flex: 1, overflowWrap: 'anywhere' }}>
                    <Typography variant="subtitle1" paragraph>
                      Exam Description: {data.exam.description}
                    </Typography>
                  </CardContent>
                </Card>
              </>
            </Grid>
            <Grid item xs="12" sm="12" md="6" lg="6" xl="6" align="left">
              {data.exam.questions && data.exam.questions.length ? (
                <QuestionList questions={data.exam.questions} />
              ) : (
                <p>No Questions Found</p>
              )}
            </Grid>
          </Grid>
        </>
      ) : (
        <p>No Exam found</p>
      )}
    </>
  );
}

export default SingleExam;
