/* eslint-disable no-unused-vars */

import { Button, Grid, Typography } from '@mui/material';
import {
  useFollowingStudentsQuery,
  useUnFollowUserMutation,
} from '../../app/services/UserApi';
import DataLoader from '../../components/DataLoader';

function StudentFollowing() {
  const { data, isLoading, isSuccess } = useFollowingStudentsQuery();

  const [unFollowUser, unFollowResult] = useUnFollowUserMutation();

  const onUnFollowClick = (id) => {
    const res = unFollowUser({ id });
  };
  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }
  return (
    <Grid container spacing={2}>
      {isSuccess && data.following.length >= 1 ? (
        data.following.map((singleFollowing) => (
          <Grid item xl="4" lg="4" md="6" sm="12" xs="12" key={singleFollowing.id}>
            <Grid container sx={{ background: '#fff' }}>
              <>
                <Grid item xl="4" lg="4" md="12" sm="12" xs="12">
                  <img
                    style={{ height: '100%', width: '100%' }}
                    src="https://cdn4.vectorstock.com/i/1000x1000/95/28/cartoon-male-teacher-vector-16659528.jpg"
                  />
                </Grid>
                <Grid
                  item
                  xl="8"
                  lg="8"
                  md="12"
                  sm="12"
                  xs="12"
                  sx={{ padding: '0.5rem 1rem 0.5rem 1rem' }}
                >
                  <Typography
                    sx={{ fontSize: '28px', fontWeight: 700, color: '#727391' }}
                  ></Typography>

                  <Typography
                    sx={{ fontSize: '16px', fontWeight: 600, color: '#5e7fb1' }}
                  ></Typography>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => onUnFollowClick(singleFollowing.id)}
                    sx={{ textTransform: 'capitalize', borderRadius: '30px' }}
                  >
                    UnFollow
                  </Button>
                  {/* <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 500,
                  color: '#1c1d1f',
                  marginTop: '0.5rem',
                }}
              >
                <span style={{ width: '100px' }}></span> 4.6 Instructor Rating
              </Typography>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 500,
                  color: '#1c1d1f',
                  marginTop: '0.2rem',
                }}
              >
                <span style={{ width: '100px' }}></span> 18,534 Reviews
              </Typography>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 500,
                  color: '#1c1d1f',
                  marginTop: '0.2rem',
                }}
              >
                <span style={{ width: '100px' }}></span> 115,904 Students
              </Typography>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 500,
                  color: '#1c1d1f',
                  marginTop: '0.2rem',
                }}
              >
                <span style={{ width: '100px' }}></span> 13 Courses Students
              </Typography> */}
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: 500,
                      color: '#1c1d1f',
                      marginTop: '0.5rem',
                    }}
                  ></Typography>
                </Grid>
              </>{' '}
            </Grid>
          </Grid>
        ))
      ) : (
        <Typography>Not Following Any Students</Typography>
      )}
    </Grid>
  );
}

export default StudentFollowing;
