/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BaseApi } from './BaseApi';

const API_URL = `${process.env.REACT_APP_BACKEND_API_PATH}/api`;
const LOCALSTORAGE_APP_KEY = `${process.env.REACT_APP_LOCALSTORAGE_KEY}`;

export const keywordsApi = BaseApi.injectEndpoints({
  reducerPath: 'keywordsApi',
  endpoints: (builder) => ({
    keywords: builder.query({
      query: () => ({
        url: '/keywords',
        method: 'GET',
      }),
      providesTags: ['Keyword'],
    }),
    createKeyword: builder.mutation({
      query: (details) => ({
        url: '/keyword/create',
        method: 'POST',

        body: {
          title: details.title,
        },
      }),
      invalidatesTags: ['Keyword'],
    }),
    keyword: builder.query({
      query: (details) => ({
        url: `/keyword/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['Keyword'],
    }),
    deleteKeyword: builder.mutation({
      query: (details) => ({
        url: `/keyword/delete/${details.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Keyword'],
    }),
  }),
});

export const {
  useKeywordsQuery,
  useCreateKeywordMutation,
  useKeywordQuery,
  useDeleteKeywordMutation,
} = keywordsApi;
