import React from 'react';
import { CssBaseline, Grid, Paper } from '@mui/material';
import EducatorRegistrationTabs from './EducatorRegistrationTabs';
import PasswordReset from '../PasswordReset';
import { useSelector } from 'react-redux';
import Copyright from '../../../components/Copyright';

function EducatorRegistrationPage() {
  const { forgotPassword } = useSelector((state) => state.auth);

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <title>Account | Gurumavin</title>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage:
            'url(https://source.unsplash.com/random/featured/?nature)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        {forgotPassword ? <PasswordReset /> : <EducatorRegistrationTabs />}
        <Copyright />
      </Grid>
    </Grid>
  );
}

export default EducatorRegistrationPage;
