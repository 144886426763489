/* eslint-disable no-unused-vars */
import {
  Button,
  Box,
  TextField,
  MenuItem,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  Grid,
  DialogContent,
  DialogActions,
  IconButton,
  FormControl,
  RadioGroup,
  Radio,
  FormLabel,
  FormGroup,
  Switch,
} from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  DateTimePicker,
  LocalizationProvider,
  MobileTimePicker,
} from '@mui/x-date-pickers';
import { useSnackbar } from 'notistack';
import MomentUtils from '@date-io/moment';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useUpdateTestQuestionMutation } from '../../../../../../app/services/TestsApi';

function UpdateTestQuestion(props) {
  const { testQuestion } = props;
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [updateQuestion, result] = useUpdateTestQuestionMutation();

  const [options, setOptions] = useState(testQuestion.options);
  const [severity, setSeverity] = React.useState(testQuestion.severity);
  const [timeChecked, setTimeChecked] = React.useState(
    testQuestion.time ? true : false
  );

  const handleTimeChange = (event) => {
    setTimeChecked(event.target.checked);
  };
  const handleSeverityChange = (event) => {
    setSeverity(event.target.value);
  };

  const validateData = (questionData) => {
    let err = true;

    if (!questionData.question || !options.length > 1 || !questionData.severity) {
      enqueueSnackbar('Please provide all the required fields', {
        variant: 'error',
      });
    } else {
      err = false;
    }

    return err;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const questionData = {
      id: testQuestion.id,
      question: data.get('question'),
      options: options,
      severity: severity,
      time: data.get('time'),
      test_topic_id: testQuestion.test_topic_id,
    };

    const res = validateData(questionData);
    if (!res && options.length > 1) {
      updateQuestion(questionData);
    } else {
      enqueueSnackbar(
        'Error adding question. Please provide valid question and options',
        {
          variant: 'error',
        }
      );
    }
  };
  const addOption = () => {
    setOptions([...options, { isCorrect: false, optionText: '' }]);
  };
  const handleValueChange = (index, e) => {
    const updatedOptions = options.map((value, i) => {
      if (i === index) {
        return { ...value, optionText: e.target.value };
      } else {
        return value;
      }
    });
    setOptions(updatedOptions);
  };
  const deleteOption = (singleOption) => {
    setOptions(options.filter((option) => option !== singleOption));
  };
  const handleChange = (index, e) => {
    const updatedOptions = options.map((value, i) => {
      if (i === index) {
        return { ...value, isCorrect: e.target.value === 'right' ? true : false };
      } else {
        return value;
      }
    });
    setOptions(updatedOptions);
  };
  return result.data ? (
    result.data.message
  ) : (
    <>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="question"
          label="Question"
          name="question"
          autoComplete="question"
          autoFocus
          defaultValue={testQuestion.question}
        />

        {options.map((singleOption, index) => (
          <Box key={index}>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item xs={8}>
                <TextField
                  autoFocus
                  margin="dense"
                  label={`option ${index + 1}`}
                  value={singleOption.optionText}
                  onChange={(e) => handleValueChange(index, e)}
                  required
                  defaultValue={singleOption.optionText}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={singleOption.isCorrect ? 'right' : 'wrong'}
                    onChange={(e) => handleChange(index, e)}
                  >
                    <FormControlLabel
                      value="right"
                      control={<Radio />}
                      label="Right"
                    />
                    <FormControlLabel
                      value="wrong"
                      control={<Radio />}
                      label="Wrong"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <div
                  className="font-icon-wrapper"
                  onClick={() => deleteOption(singleOption)}
                >
                  <IconButton aria-label="delete">
                    <DeleteForeverIcon />
                  </IconButton>
                </div>
              </Grid>
            </Grid>
          </Box>
        ))}
        <Button onClick={addOption}>Add Option</Button>
        <Grid item xs={12}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              Question Severity
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={severity}
              onChange={handleSeverityChange}
            >
              <FormControlLabel value="low" control={<Radio />} label="Low" />
              <FormControlLabel value="medium" control={<Radio />} label="Medium" />
              <FormControlLabel value="high" control={<Radio />} label="High" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset" variant="standard">
            <FormLabel component="legend">Assign Question Type</FormLabel>
            <FormGroup>
              <FormControlLabel
                value="high"
                control={
                  <Switch
                    onChange={handleTimeChange}
                    checked={timeChecked}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={timeChecked ? 'timed Question' : 'untimed Question'}
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <TextField
          id="time"
          name="time"
          required
          label="Question Time(in seconds)"
          sx={{ mt: 3, mb: 2 }}
          disabled={!timeChecked}
          type="number"
          defaultValue={testQuestion.time}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={result.isLoading || result.isSuccess}
        >
          Update Test Question
        </Button>
      </Box>
    </>
  );
}

export default UpdateTestQuestion;
