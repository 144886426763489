/* eslint-disable no-unused-vars */
import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  Grid,
  Box,
  CardMedia,
  CardContent,
} from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useCourseQuery } from '../../../app/services/CoursesApi';
import CreateNewSubject from './subjects/CreateNewSubject';
import SubjectList from './subjects/SubjectList';
import AssignInstitutionToCourse from './admin/AssignInstitutionToCourse';
import AssignEducatorToCourse from './institution/AssignEducatorToCourse';
import UpdateCourse from './UpdateCourse';
import UploadCourseImage from './UploadCourseImage';
import LanguageIcon from '@mui/icons-material/Language';
import UploadCourseVideo from './UploadCourseVideo';
import Slide from '@mui/material/Slide';

import DisplayCourseVideos from './DisplayCourseVideos';
import DataLoader from '../../../components/DataLoader';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function SingleCourse() {
  const params = useParams();
  const { user } = useSelector((state) => state.auth);

  const { data, isLoading, isSuccess } = useCourseQuery({
    id: parseInt(params.Id, 10),
  });
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const [open5, setOpen5] = React.useState(false);

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleClickOpen3 = () => {
    setOpen3(true);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };
  const handleClickOpen4 = () => {
    setOpen4(true);
  };

  const handleClose4 = () => {
    setOpen4(false);
  };
  const handleClickOpen5 = () => {
    setOpen5(true);
  };

  const handleClose5 = () => {
    setOpen5(false);
  };

  return (
    <>
      {isSuccess && data.course ? (
        <>
          <Grid container spacing={1}>
            <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
              <Box sx={{ textAlign: 'center' }}>
                <Button variant="contained" onClick={handleClickOpen1}>
                  Update Course
                </Button>
                <>
                  <Dialog open={open1} onClose={handleClose1}>
                    <DialogTitle> Update Course</DialogTitle>
                    <DialogContent>
                      <UpdateCourse course={data.course} />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose1}>Close</Button>
                    </DialogActions>
                  </Dialog>
                </>
                &nbsp;
                <Button variant="contained" onClick={handleClickOpen2}>
                  {data.course.image_url
                    ? 'Change Course Image'
                    : 'Upload Course Image'}
                </Button>
                <>
                  <Dialog open={open2} onClose={handleClose2}>
                    <DialogTitle>
                      {data.course.image_url
                        ? 'Change Course Image'
                        : 'Upload Course Image'}
                    </DialogTitle>
                    <DialogContent>
                      <UploadCourseImage
                        imgUrl={data.course.image_url}
                        courseId={data.course.id}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose2}>Close</Button>
                    </DialogActions>
                  </Dialog>
                </>
                &nbsp;
                <Button variant="contained" onClick={handleClickOpen5}>
                  {data.course.video_url
                    ? 'Change Course Video'
                    : 'Upload Course Video'}
                </Button>
                <>
                  <Dialog
                    open={open5}
                    onClose={handleClose5}
                    fullWidth
                    maxWidth="sm"
                    TransitionComponent={Transition}
                    fullScreen
                  >
                    <DialogTitle>
                      {data.course.video_url
                        ? 'Change Course Video'
                        : 'Upload Course Video'}
                    </DialogTitle>
                    <DialogContent>
                      <UploadCourseVideo
                        videoUrl={data.course.video_url}
                        courseId={data.course.id}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose5}>Close</Button>
                    </DialogActions>
                  </Dialog>
                </>
                {user.role === 'admin' ? (
                  <>
                    &nbsp;
                    <Button variant="contained" onClick={handleClickOpen3}>
                      {data.course.institution_id
                        ? 'Change Course Institution'
                        : 'Assign Course Institution'}
                    </Button>
                    <Dialog open={open3} onClose={handleClose3}>
                      <DialogTitle>
                        {data.course.institution_id
                          ? 'Change Course Institution'
                          : 'Assign Course Institution'}
                      </DialogTitle>
                      <DialogContent>
                        <AssignInstitutionToCourse courseId={data.course.id} />
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose3}>Close</Button>
                      </DialogActions>
                    </Dialog>
                  </>
                ) : null}
                {user.role === 'institution' ? (
                  <>
                    &nbsp;
                    <Button variant="contained" onClick={handleClickOpen4}>
                      {data.course.educator_id
                        ? 'Change Course Educator'
                        : 'Assign Course Educator'}
                    </Button>
                    <Dialog open={open4} onClose={handleClose4}>
                      <DialogTitle>
                        {data.course.educator_id
                          ? 'Change Course Educator'
                          : 'Assign Course Educator'}
                      </DialogTitle>
                      <DialogContent>
                        <AssignEducatorToCourse courseId={data.course.id} />
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose4}>Close</Button>
                      </DialogActions>
                    </Dialog>
                  </>
                ) : null}
                &nbsp;
                <Button variant="contained" onClick={handleClickOpen}>
                  Create New Subject
                </Button>
                <>
                  <Dialog open={open} onClose={handleClose}>
                    <DialogTitle> Create New Subject</DialogTitle>
                    <DialogContent>
                      <CreateNewSubject courseId={data.course.id} />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose}>Close</Button>
                    </DialogActions>
                  </Dialog>
                </>
              </Box>
            </Grid>
            <Grid item xs="12" sm="12" md="8" lg="8" xl="8" align="left">
              <>
                <Card sx={{ display: 'flex' }}>
                  <CardMedia
                    component="img"
                    sx={{ width: 340, display: { xs: 'none', sm: 'block' } }}
                    image={
                      data.course.image_url ||
                      `https://images.unsplash.com/photo-1516397281156-ca07cf9746fc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80`
                    }
                  />
                  <CardContent sx={{ flex: 1, overflowWrap: 'anywhere' }}>
                    <Typography component="h2" variant="h5">
                      <strong>{data.course.title}</strong>
                    </Typography>
                    {/* <Typography variant="subtitle1" color="text.secondary">
                      Starts from&nbsp;
                      {moment(data.course.start_date).format('Do MMM YY hh:mm A')}
                    </Typography>{' '}
                    <Typography variant="subtitle1" color="text.secondary">
                      Ends On&nbsp;
                      {moment(data.course.end_date).format('Do MMM YY hh:mm A')}
                    </Typography> */}
                    <Typography variant="subtitle1" color="text.secondary">
                      {/* <LanguageIcon /> */}
                      Language: &nbsp;
                      {data.course.language}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary">
                      Category: &nbsp;
                      {data.course.sub_category.category.title}
                    </Typography>{' '}
                    <Typography variant="subtitle1" color="text.secondary">
                      Sub Category: &nbsp;
                      {data.course.sub_category.title}
                    </Typography>
                    {data.course.level ? (
                      <Typography variant="subtitle1" color="text.secondary">
                        Level: &nbsp;
                        {data.course.level}
                      </Typography>
                    ) : null}
                    {data.course.grade ? (
                      <Typography variant="subtitle1" color="text.secondary">
                        Grade: &nbsp;
                        {data.course.grade.title}
                      </Typography>
                    ) : null}
                    {data.course.grade_subject ? (
                      <Typography variant="subtitle1" color="text.secondary">
                        Grade Subject: &nbsp;
                        {data.course.grade_subject.title}
                      </Typography>
                    ) : null}
                    {data.course.publication ? (
                      <Typography variant="subtitle1" color="text.secondary">
                        Publication: &nbsp;
                        {data.course.publication.title}
                      </Typography>
                    ) : null}
                    {data.course.publication_edition ? (
                      <Typography variant="subtitle1" color="text.secondary">
                        Publication Edition: &nbsp;
                        {data.course.publication_edition.edition}
                      </Typography>
                    ) : null}
                    {data.course.cost ? (
                      <Typography component="h2" color="text.primary">
                        <strong>₹{data.course.cost}</strong>
                      </Typography>
                    ) : (
                      <Typography>Free Course</Typography>
                    )}
                  </CardContent>
                </Card>

                <Card>
                  <CardContent sx={{ flex: 1, overflowWrap: 'anywhere' }}>
                    <Typography variant="subtitle1" paragraph>
                      Course Description: {data.course.description}
                    </Typography>
                  </CardContent>
                </Card>
              </>
            </Grid>
            <Grid item xs="12" sm="12" md="4" lg="4" xl="4" align="left">
              {data.course.videos && data.course.videos.length >= 1 ? (
                <Card>
                  <Box mt={2} textAlign="center">
                    <Typography variant="subtitle1" color="text.secondary">
                      Course Videos
                    </Typography>
                    <DisplayCourseVideos videos={data.course.videos} />
                  </Box>
                </Card>
              ) : (
                <Typography variant="subtitle1" color="text.secondary">
                  No Course Videos Found
                </Typography>
              )}
              {data.course.subjects && data.course.subjects.length >= 1 ? (
                <SubjectList subjects={data.course.subjects} />
              ) : (
                <p>No Subjects Found</p>
              )}
            </Grid>
          </Grid>
        </>
      ) : (
        <p>No course found</p>
      )}
    </>
  );
}

export default SingleCourse;
