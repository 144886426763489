/* eslint-disable no-unused-vars */
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { BaseApi } from '../../app/services/BaseApi';
import { signOut } from '../../app/slices/authSlice';

// Sign Out Component
function SignOut() {
  const { token } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const handleSignOut = () => {
    // dispatch(BaseApi.util.resetApiState());
    dispatch(signOut(token))
      .unwrap()
      .then(() => dispatch(BaseApi.util.resetApiState()));
  };

  return (
    <Button onClick={handleSignOut} variant="" sx={{ my: 1, mx: 1.5 }}>
      Sign Out
    </Button>
  );
}

export default SignOut;
