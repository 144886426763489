import React from 'react';
import { Navigate } from 'react-router-dom';

function NotFoundPage() {
  return (
    <div>
      <p>No page Found</p>
      <Navigate to="/">Home page(?)</Navigate>
    </div>
  );
}

export default NotFoundPage;
