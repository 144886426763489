/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BaseApi } from './BaseApi';

const API_URL = `${process.env.REACT_APP_BACKEND_API_PATH}/api`;
const LOCALSTORAGE_APP_KEY = `${process.env.REACT_APP_LOCALSTORAGE_KEY}`;

export const learningObjectivesApi = BaseApi.injectEndpoints({
  reducerPath: 'learningObjectivesApi',
  endpoints: (builder) => ({
    learningObjectives: builder.query({
      query: () => ({
        url: '/learning/objectives',
        method: 'GET',
      }),
      providesTags: ['learning objective'],
    }),
    createLearningObjective: builder.mutation({
      query: (details) => ({
        url: '/learning/objective/create',
        method: 'POST',

        body: {
          title: details.title,
          objectives: details.objectives,
        },
      }),
      invalidatesTags: ['learning objective'],
    }),
    learningObjective: builder.query({
      query: (details) => ({
        url: `/learning/objective/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['learning objective'],
    }),
    learningObjectivesForStudent: builder.query({
      query: () => ({
        url: '/student/learning/objectives',
        method: 'GET',
      }),
      providesTags: ['learning objective'],
    }),
  }),
});

export const {
  useLearningObjectivesQuery,
  useCreateLearningObjectiveMutation,
  useLearningObjectiveQuery,
  useLearningObjectivesForStudentQuery,
} = learningObjectivesApi;
