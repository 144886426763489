/* eslint-disable no-unused-vars */
import { ExpandMore } from '@mui/icons-material';
import {
  Grid,
  Typography,
  Button,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useDeleteGradeSubjectMutation } from '../../../app/services/CategoriesApi';

function GradeSubjectsList(props) {
  const { gradeSubjects } = props;
  const navigate = useNavigate();

  const [expandedId, setExpandedId] = React.useState(-1);

  const handleClick = (i) => {
    setExpandedId(expandedId === i ? -1 : i);
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [deleteGradeSubject, deletionResult] = useDeleteGradeSubjectMutation();

  const viewCategory = (id) => {
    navigate(`/gradesubjects/${id}`);
  };

  const onDelete = (id) => {
    const res = deleteGradeSubject({ id });
  };

  return (
    <>
      <Typography component="div" variant="h5" sx={{ padding: 4, color: '#233c8d' }}>
        Grade Subjects
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Grade</TableCell>
              <TableCell>Created On</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {gradeSubjects
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((singleGradeSubject, index) => (
                <TableRow
                  key={singleGradeSubject.id}
                  sx={{
                    '&:last-child td, &:last-child th': {
                      border: 0,
                    },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell>{singleGradeSubject.title}</TableCell>
                  <TableCell>{singleGradeSubject.grade.title}</TableCell>
                  <TableCell>
                    {moment(singleGradeSubject.created_at).format(
                      'Do MMM YY hh:mm A'
                    )}
                  </TableCell>
                  <TableCell>
                    <List
                      sx={{
                        width: '100%',
                        maxWidth: 200,
                        bgcolor: 'background.paper',
                      }}
                    >
                      <ListItemButton
                        onClick={() => handleClick(index)}
                        aria-expanded={expandedId === index}
                      >
                        <ListItemText primary="Options" />
                        <ExpandMore />
                      </ListItemButton>
                      <Collapse
                        in={expandedId === index}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          <ListItemButton
                            sx={{ pl: 4 }}
                            onClick={() => viewCategory(singleGradeSubject.id)}
                          >
                            <ListItemText primary="View" />
                          </ListItemButton>
                        </List>

                        <List component="div" disablePadding>
                          <ListItemButton
                            sx={{ pl: 4 }}
                            onClick={() => onDelete(singleGradeSubject.id)}
                          >
                            <ListItemText primary="Delete" />
                          </ListItemButton>
                        </List>
                      </Collapse>
                    </List>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={gradeSubjects && gradeSubjects.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}

export default GradeSubjectsList;
