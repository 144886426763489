import { PhotoCamera } from '@mui/icons-material';
import { IconButton, Box, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { uploadStudentProfilePictureRequest } from '../../../app/services/UploadRequests';

function UploadStudentProfilePicture(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { token } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const { imgUrl, studentId } = props;
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(imgUrl);
  const allowedTypes = ['image/png', 'image/jpeg', 'image/svg+xml'];
  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);
  const UploadImage = (event) => {
    event.preventDefault();
    setLoading(true);

    const UploadData = {
      file: selectedImage,
      token: token,
    };
    uploadStudentProfilePictureRequest(UploadData, studentId).then((res) => {
      if (res.url) {
        setLoading(false);
        enqueueSnackbar(
          'Profile Picture Uploaded Successfully. Please refresh your page for now.',
          {
            variant: 'success',
          }
        );
      } else {
        setLoading(false);

        enqueueSnackbar('Error Uploading Image. ', {
          variant: 'error',
        });
      }
    });
  };

  return (
    <>
      {imageUrl && (
        <Box mt={2} textAlign="center">
          <div>Image Preview:</div>
          <img src={imageUrl} height="200px" />
        </Box>
      )}

      <Box component="form" onSubmit={UploadImage} noValidate sx={{ mt: 1 }}>
        <IconButton color="primary" aria-label="upload picture" component="label">
          <input
            hidden
            accept="image/*"
            type="file"
            style={{ display: 'none' }}
            onChange={(e) => {
              if (allowedTypes.includes(e.target.files[0].type)) {
                setSelectedImage(e.target.files[0]);
              } else {
                enqueueSnackbar('Please select a PNG, JPEG, or SVG file.', {
                  variant: 'error',
                });
              }
            }}
          />
          <PhotoCamera />
        </IconButton>
        {loading ? <p>please wait while we upload your image</p> : null}
        {selectedImage ? (
          <Button variant="contained" type="submit" disabled={loading}>
            Upload
          </Button>
        ) : null}
      </Box>
    </>
  );
}

export default UploadStudentProfilePicture;
