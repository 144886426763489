/* eslint-disable no-unused-vars */
import { Accordion, AccordionSummary, Button, Divider } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

function DisplayEnroledTopicVideos(props) {
  const { videos, id } = props;
  const navigate = useNavigate();

  const viewVideo = (videoId) => {
    navigate(`/enrols/${id}/video/${videoId}`);
  };

  return (
    <>
      {videos.map((singleVideo, index) => (
        <>
          <Button
            variant="contained"
            onClick={() => viewVideo(singleVideo.id)}
            key={singleVideo.id}
            sx={{ margin: '10px' }}
            endIcon={<PlayCircleOutlineIcon />}
          >
            {index + 1}. {singleVideo.title}
          </Button>
          <Divider />
        </>
      ))}
    </>
  );
}

export default DisplayEnroledTopicVideos;
