/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import {
  useGetAdminVideoJobStatusMutation,
  useUpdateVideoStatusMutation,
} from '../../../../app/services/CoursesApi';

function ChangeVideoStatus(props) {
  const { video } = props;
  const [status, setStatus] = React.useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [localVideo, setLocalVideo] = React.useState(video);
  const [updateVideoStatus, result] = useUpdateVideoStatusMutation();
  const [getVideoJobStatus, result1] = useGetAdminVideoJobStatusMutation();

  const handleChangeStatus = (e) => {
    setStatus(e.target.value);
  };

  const onChangeStatus = (event) => {
    event.preventDefault();
    setLoading(true);
    const updateData = {
      videoId: localVideo.id,
      status: status,
    };

    const res = updateVideoStatus(updateData);
  };

  const checkStatus = (id) => {
    enqueueSnackbar('Checking Video status.', {
      variant: 'info',
    });
    getVideoJobStatus({ id: id })
      .then((res) => {
        if (res.data.video.status !== localVideo.status) {
          setLocalVideo(res.data.video);
        }
        if (res.data.video.status === 'SUBMITTED') {
          enqueueSnackbar('Video uploaded and submitted for transcoding.', {
            variant: 'success',
          });
        } else if (res.data.video.status === 'PROGRESSING') {
          enqueueSnackbar('Video uploaded and is progressing in transcoding.', {
            variant: 'success',
          });
        } else if (res.data.video.status === 'COMPLETED') {
          enqueueSnackbar('Video uploaded and transcoded Successfully.', {
            variant: 'success',
          });
        } else {
          enqueueSnackbar('Error getting Video Status. ', {
            variant: 'error',
          });
        }
      })
      .catch((err) => console.log(err));
  };

  return result.data ? (
    result.data.message
  ) : (
    <>
      <Box
        noValidate
        component="form"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          m: 'auto',
          width: 'fit-content',
        }}
        onSubmit={onChangeStatus}
      >
        <Typography>Current Status: {localVideo.status}</Typography>
        <FormControl sx={{ mt: 2, minWidth: 120 }}>
          {localVideo.status === 'COMPLETED' ? (
            <>
              <InputLabel htmlFor="max-width">status</InputLabel>

              <Select
                autoFocus
                onChange={handleChangeStatus}
                label="maxWidth"
                inputProps={{
                  name: 'max-width',
                  id: 'max-width',
                }}
              >
                <MenuItem value="BLOCKED">BLOCK</MenuItem>
                <MenuItem value="PUBLISHED">PUBLISH</MenuItem>
              </Select>
            </>
          ) : null}
          {localVideo.status === 'PUBLISHED' ? (
            <>
              <InputLabel htmlFor="max-width">status</InputLabel>
              <Select
                autoFocus
                onChange={handleChangeStatus}
                label="maxWidth"
                inputProps={{
                  name: 'max-width',
                  id: 'max-width',
                }}
              >
                <MenuItem value="BLOCKED">BLOCK</MenuItem>
              </Select>
            </>
          ) : null}

          {localVideo.status === 'BLOCKED' ? (
            <>
              <InputLabel htmlFor="max-width">status</InputLabel>
              <Select
                autoFocus
                onChange={handleChangeStatus}
                label="maxWidth"
                inputProps={{
                  name: 'max-width',
                  id: 'max-width',
                }}
              >
                <MenuItem value="PUBLISHED">PUBLISH</MenuItem>
              </Select>
            </>
          ) : null}
        </FormControl>
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 1 }}
          disabled={status === localVideo.status || loading || !status}
        >
          Change Status
        </Button>
      </Box>
      {localVideo.status === 'SUBMITTED' || localVideo.status === 'PROGRESSING' ? (
        <Box sx={{ mt: 1, display: 'grid' }}>
          <Button
            variant="contained"
            onClick={() => checkStatus(localVideo.media_convert_job_id)}
          >
            Check Status
          </Button>
        </Box>
      ) : null}
    </>
  );
}

export default ChangeVideoStatus;
