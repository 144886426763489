/* eslint-disable no-unused-vars */
import {
  Button,
  Typography,
  Grid,
  Box,
  CardActions,
  Paper,
  Alert,
  AlertTitle,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Rating,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router';

import moment from 'moment';
import { usePublicTestQuery } from '../../../app/services/PublicTestsApi';
import React from 'react';
import CreateNewPracticeTest from '../student/CreateNewPracticeTest';
import DisplayTestSubjectsAndTopics from './DisplayTestSubjectsAndTopics';
import DisplayTestReviews from './DisplayTestReviews';
import DataLoader from '../../../components/DataLoader';

function DisplaySingleTest() {
  const params = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const { user, isSignedIn } = useSelector((state) => state.auth);
  const { data, isLoading, isSuccess } = usePublicTestQuery({
    id: parseInt(params.testId, 10),
  });

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return isSuccess && data.test ? (
    <>
      <Paper
        sx={{
          position: 'relative',
          backgroundColor: 'grey.800',
          color: '#fff',
          mb: 4,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundImage: data.test.image_url
            ? `url(${data.test.image_url})`
            : `url("https://images.unsplash.com/photo-1516397281156-ca07cf9746fc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80")`,
        }}
      >
        {/* Increase the priority of the hero background image */}
        {
          <img
            style={{ display: 'none' }}
            src={
              data.test.image_url ||
              `https://images.unsplash.com/photo-1516397281156-ca07cf9746fc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80`
            }
            // alt={data.test.imageText}
          />
        }
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: 'rgba(0,0,0,.3)',
          }}
        />
        <Grid container>
          <Grid item md={3}></Grid>
          <Grid item md={6}>
            <Box
              sx={{
                position: 'relative',
                p: { xs: 3, md: 6 },
                pr: { md: 0 },
                overflowWrap: 'anywhere',
              }}
            >
              <Typography component="h2" variant="h5">
                <strong>{data.test.title}</strong>
              </Typography>

              <Typography variant="subtitle1">
                In&nbsp;
                {data.test.language}
              </Typography>
              <Box sx={{ display: 'flex' }}>
                <Box>
                  <Typography>
                    {Math.round(data.test.test_reviews_avg_rating * 10) / 10}&nbsp;
                  </Typography>
                </Box>
                <Rating
                  name="read-only"
                  value={Math.round(data.test.test_reviews_avg_rating * 10) / 10}
                  readOnly
                  precision={0.5}
                />

                {data.test.test_reviews_count ? (
                  <Typography>
                    &#40;{data.test.test_reviews_count}
                    &nbsp;reviews&#41;
                  </Typography>
                ) : (
                  <Typography>&#40;no reviews&#41;</Typography>
                )}
              </Box>
              {data.test.cost ? (
                <Typography component="h2" color="text.primary">
                  <strong>₹{data.test.cost}</strong>
                </Typography>
              ) : (
                <Typography>Free Practice Test</Typography>
              )}
              <CardActions sx={{ padding: 0 }}>
                {isSignedIn ? (
                  user.role !== 'student' ? (
                    <Alert severity="info">
                      Please register as a student to create your practice test
                    </Alert>
                  ) : (
                    <>
                      <Button variant="contained" onClick={handleClickOpen}>
                        Create Your New Practice Test
                      </Button>
                      <>
                        <Dialog
                          open={open}
                          onClose={handleClose}
                          fullWidth
                          maxWidth="sm"
                        >
                          <DialogTitle> Create Your New Practice Test</DialogTitle>
                          <DialogContent>
                            <CreateNewPracticeTest test={data.test} />
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={handleClose}>Close</Button>
                          </DialogActions>
                        </Dialog>
                      </>
                    </>
                  )
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      navigate('/student-account', {
                        state: location.pathname,
                      });
                    }}
                  >
                    Create Your New Practice Test
                  </Button>
                )}
              </CardActions>
            </Box>
          </Grid>
          <Grid item md={3}></Grid>
        </Grid>
      </Paper>

      <Grid container spacing={2}>
        <Grid item xs="12" sm="12" md="1" lg="1" xl="1"></Grid>
        <Grid item xs="12" sm="12" md="10" lg="10" xl="10">
          <Box
            sx={{
              position: 'relative',
              p: { xs: 3, md: 3 },
              pr: { md: 0 },
              overflowWrap: 'anywhere',
            }}
          >
            <Typography component="h2" variant="h5">
              <strong>Test Description</strong>
            </Typography>
            <Typography variant="subtitle1" paragraph>
              {data.test.description}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs="12" sm="12" md="1" lg="1" xl="1"></Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs="12" sm="12" md="1" lg="1" xl="1"></Grid>
        <Grid item xs="12" sm="12" md="10" lg="10" xl="10">
          <>
            <Box
              sx={{
                position: 'relative',
                p: { xs: 3, md: 3 },
                pr: { md: 0 },
                overflowWrap: 'anywhere',
              }}
            >
              <Typography component="h2" variant="h5">
                <strong>
                  You can choose Questions from these Subjects and Topics
                </strong>
              </Typography>
              <DisplayTestSubjectsAndTopics testSubjects={data.test.test_subjects} />
            </Box>
          </>
        </Grid>
        <Grid item xs="12" sm="12" md="1" lg="1" xl="1"></Grid>

        <Grid item xs="12" sm="12" md="1" lg="1" xl="1"></Grid>
        <Grid item xs="12" sm="12" md="10" lg="10" xl="10">
          <>
            <Box
              sx={{
                position: 'relative',
                p: { xs: 3, md: 3 },
                pr: { md: 0 },
                overflowWrap: 'anywhere',
              }}
            >
              <DisplayTestReviews testReviews={data.test.test_reviews} />
            </Box>
          </>
        </Grid>
        <Grid item xs="12" sm="12" md="1" lg="1" xl="1"></Grid>
      </Grid>
    </>
  ) : (
    <p>No test found</p>
  );
}

export default DisplaySingleTest;
