import {
  Button,
  Checkbox,
  Container,
  createTheme,
  CssBaseline,
  Grid,
  TextField,
  ThemeProvider,
  Typography,
  Box,
  Avatar,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useSelector } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';

import { useSnackbar } from 'notistack';
import { useCreateEducatorUserMutation } from '../../../app/services/AuthApi';
import { useState } from 'react';

const theme = createTheme();

// Sign Up Page with ui form for new user
function EducatorSignUpPage(props) {
  const { changeView } = props;
  const [termsAndConditions, setTermsAndConditions] = useState(false);

  const [createEducatorUser, result] = useCreateEducatorUserMutation();
  const { enqueueSnackbar } = useSnackbar();

  const { isSignedIn } = useSelector((state) => state.auth);
  const ValidateEmail = (mail) => {
    // eslint-disable-next-line no-useless-escape
    const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (mail.match(mailFormat)) {
      return true;
    }
    return false;
  };
  const validateData = (authData) => {
    let err = true;

    if (
      !authData.name ||
      !authData.field ||
      !authData.subject ||
      !authData.email ||
      !authData.phoneNumber ||
      !authData.password ||
      !authData.confirmPassword
    ) {
      enqueueSnackbar('All fields are required', { variant: 'error' });
    } else if (termsAndConditions !== true) {
      enqueueSnackbar('You must agree to terms and Conditions.', {
        variant: 'error',
      });
    } else if (authData.password !== authData.confirmPassword) {
      enqueueSnackbar('Confirm password did not match.', { variant: 'error' });
    } else if (authData.password.length < 8) {
      enqueueSnackbar('Password length must be at least 8 chars', {
        variant: 'error',
      });
    } else if (authData.phoneNumber.length !== 10) {
      enqueueSnackbar(' Please provide a valid Phone Number ', {
        variant: 'error',
      });
    } else if (!ValidateEmail(authData.email)) {
      enqueueSnackbar('Please enter a valid Email address', { variant: 'error' });
    } else {
      err = false;
    }

    return err;
  };

  // Hande submit of the form
  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);

    const authData = {
      name: data.get('name'),
      field: data.get('field'),
      subject: data.get('subject'),
      email: data.get('email'),
      phoneNumber: data.get('phoneNumber'),
      password: data.get('password'),
      confirmPassword: data.get('confirmPassword'),
    };
    const res = validateData(authData);
    if (!res) {
      createEducatorUser(authData)
        .unwrap()
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          enqueueSnackbar('Registration Success. Please Verify Your Email.', {
            variant: 'success',
          });
        })
        .catch((err) => {
          if (err.data.errors.email) {
            enqueueSnackbar('Email already exists', {
              variant: 'error',
            });
          } else if (err.data.errors.phone_number) {
            enqueueSnackbar('Phone Number already exists', {
              variant: 'error',
            });
          } else {
            enqueueSnackbar('SignUp Error', {
              variant: 'error',
            });
          }
        });
    }
  };

  if (isSignedIn) {
    return <Navigate replace to="/" />;
  }
  const openLoginTab = (e) => changeView(e, 0);
  const handleChange = () => {
    setTermsAndConditions(!termsAndConditions);
  };
  return (
    <>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <>
              <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Sign Up
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 3 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      autoComplete="given-name"
                      name="name"
                      required
                      fullWidth
                      id="name"
                      label="Name"
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="phoneNumber"
                      label="phone Number"
                      name="phoneNumber"
                      autoComplete="phoneNumber"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="field"
                      label="Field of Teaching"
                      name="field"
                      autoComplete="field"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="subject"
                      label="Subject"
                      name="subject"
                      autoComplete="subject"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="new-password"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="confirmPassword"
                      label="ConfirmPassword"
                      type="password"
                      id="confirmPassword"
                      autoComplete="new-password"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Checkbox
                      id="termsAndConditions"
                      color="primary"
                      onChange={handleChange}
                    />
                    <span>
                      I agree with&nbsp;
                      <Link to="/terms-and-conditions">Terms and Conditions</Link>
                    </span>
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={result.isLoading || result.isSuccess}
                >
                  Sign Up
                </Button>

                <Grid container justify="flex-end">
                  <Grid item>
                    <Button onClick={openLoginTab}>Go to Sign In</Button>
                  </Grid>
                </Grid>
              </Box>
            </>
          </Box>
        </Container>
      </ThemeProvider>
    </>
  );
}

export default EducatorSignUpPage;
