/* eslint-disable no-unused-vars */
import { Link, Toolbar, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import Box from '@mui/material/Box';
import { usePublishedCategoriesQuery } from '../app/services/CategoriesApi';

function MenuToolBar() {
  const { data, isLoading, isSuccess } = usePublishedCategoriesQuery();
  const [selectedPublishedCategory, setSelectedPublishedCategoryPublished] =
    React.useState(false);

  const navigate = useNavigate();

  const onSubMenuClick = (id) => {
    navigate(`/${selectedPublishedCategory.title}/${id}`);
  };

  return (
    <>
      {isSuccess && data.categories.length ? (
        <>
          <Box>
            <Toolbar
              component="nav"
              variant="dense"
              sx={{
                // justifyContent: 'flex-start',
                overflowX: 'auto',
                backgroundColor: 'teal',
              }}
            >
              {data.categories.map((singlePublishedCategory) => (
                <>
                  <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                      setSelectedPublishedCategoryPublished(singlePublishedCategory);
                    }}
                    color="inherit"
                    noWrap
                    key={singlePublishedCategory.id}
                    underline="none"
                    sx={{ p: 1, flexShrink: 0 }}
                  >
                    {singlePublishedCategory.title.charAt(0).toUpperCase() +
                      singlePublishedCategory.title.slice(1)}
                  </Link>
                </>
              ))}
            </Toolbar>
            {selectedPublishedCategory &&
            selectedPublishedCategory.published_sub_categories.length ? (
              <Toolbar
                component="nav"
                variant="dense"
                sx={{
                  justifyContent: 'flex-start',
                  overflowX: 'auto',
                  backgroundColor: '#ffffff',
                  color: '#000000',
                }}
              >
                {selectedPublishedCategory.published_sub_categories.map(
                  (singlePublishedCategorySubCategory) => (
                    <Link
                      component="button"
                      variant="body2"
                      color="inherit"
                      noWrap
                      key={singlePublishedCategorySubCategory.id.toString()}
                      underline="none"
                      sx={{ p: 1, flexShrink: 0 }}
                      onClick={() =>
                        onSubMenuClick(singlePublishedCategorySubCategory.id)
                      }
                    >
                      {singlePublishedCategorySubCategory.title
                        .charAt(0)
                        .toUpperCase() +
                        singlePublishedCategorySubCategory.title.slice(1)}
                    </Link>
                  )
                )}
              </Toolbar>
            ) : null}
          </Box>
        </>
      ) : null}
    </>
  );
}

export default MenuToolBar;
