/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BaseApi } from './BaseApi';

const API_URL = `${process.env.REACT_APP_BACKEND_API_PATH}/api`;
const LOCALSTORAGE_APP_KEY = `${process.env.REACT_APP_LOCALSTORAGE_KEY}`;

export const homePageApi = BaseApi.injectEndpoints({
  reducerPath: 'homePageApi',
  endpoints: (builder) => ({
    homeData: builder.query({
      query: () => ({
        url: '/home/page',
        method: 'GET',
      }),
      providesTags: ['Home'],
    }),
  }),
});

export const { useHomeDataQuery } = homePageApi;
