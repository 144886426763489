/* eslint-disable no-unused-vars */
import { Avatar, Button, Grid, Paper, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useSelector } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { resendEmailVerificationRequest } from '../../app/services/AuthRequests';
import { useState } from 'react';

function ResendEmailVerification() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { isSignedIn } = useSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();

  const ValidateEmail = (mail) => {
    // eslint-disable-next-line no-useless-escape
    const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (mail.match(mailFormat)) {
      return true;
    }
    return false;
  };
  const validateData = (authData) => {
    let err = true;

    if (!authData.email) {
      enqueueSnackbar('Email is required', { variant: 'error' });
    } else if (!ValidateEmail(authData.email)) {
      enqueueSnackbar('Please enter a valid Email address', { variant: 'error' });
    } else {
      err = false;
    }

    return err;
  };

  // Handle Submit of the form
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const Authdata = {
      email: data.get('resendEmail'),
    };
    const res = validateData(Authdata);
    if (!res) {
      resendEmailVerificationRequest(Authdata).then((res) => {
        if (res.message === 'VERIFICATION_SENT') {
          setLoading(false);
          enqueueSnackbar('Email Verification Link Sent. Please Check Your Email.', {
            variant: 'success',
          });
          navigate('/student-account');
        } else {
          setLoading(false);

          enqueueSnackbar('Error Sending Email Verification Link', {
            variant: 'error',
          });
        }
      });
    }
  };

  if (isSignedIn) {
    return <Navigate replace to="/" />;
  }

  return (
    <div>
      <Grid container>
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}></Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box
            sx={{
              marginTop: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Paper elevation={3} sx={{ p: 2 }}>
              <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockResetOutlinedIcon />
              </Avatar>
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <Typography variant="h5" component="h5">
                  Generate Email Verification Link
                </Typography>
                <Typography>
                  Enter your email and we&apos;ll send you a link to verify your
                  email.
                </Typography>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="resendEmail"
                  label="Email Address"
                  name="resendEmail"
                  autoComplete="email"
                  autoFocus
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  data-cy-submit-button
                  // disabled={result.isLoading || result.isSuccess}
                >
                  GET VERIFICATION LINK
                </Button>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Link to="/student-account">Go back</Link>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
            <Box mt={5}></Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}></Grid>
      </Grid>
    </div>
  );
}

export default ResendEmailVerification;
