/* eslint-disable no-unused-vars */
import { ArrowForwardIos, ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  Card,
  CardContent,
  Collapse,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Box,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import moment from 'moment';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import styled from '@emotion/styled';
const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIos />} {...props} />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  // '& .MuiAccordionSummary-content': {
  //   marginLeft: theme.spacing(1),
  // },
}));
function DisplayCourseCurriculum(props) {
  const { subjects, height } = props;

  const displayVideoDuration = (videos) => {
    let videoDuration = 0;
    if (videos.length >= 1) {
      videos.forEach(
        (singleVideo) => (videoDuration += Number(singleVideo.duration))
      );
      return videoDuration;
    } else {
      return videoDuration;
    }
  };
  return (
    <Paper
      sx={{
        m: 1,
        height: subjects.length > 12 && height,
        overflowY: subjects.length > 12 && height && 'scroll',
      }}
    >
      {subjects.length >= 1 ? (
        <>
          {subjects.map((singleSubject, index) => (
            <>
              <Accordion key={singleSubject.id}>
                <AccordionSummary
                  expandIcon={<KeyboardArrowDownIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography sx={{ width: '90%' }}>
                    {index + 1}. {singleSubject.title}
                  </Typography>

                  <Typography sx={{ color: 'text.secondary' }}>
                    {moment
                      .utc(
                        displayVideoDuration(singleSubject.published_videos) * 1000
                      )
                      .format('HH:mm:ss')}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {singleSubject.topics.length >= 1 &&
                    singleSubject.topics.map((singleTopic, i) => (
                      <>
                        <Accordion key={singleTopic.id}>
                          <AccordionSummary
                            expandIcon={<KeyboardArrowDownIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography sx={{ width: '90%' }}>
                              {index + 1}. {i + 1}. {singleTopic.title}
                            </Typography>
                            <Typography sx={{ color: 'text.secondary' }}>
                              {moment
                                .utc(
                                  displayVideoDuration(
                                    singleTopic.published_videos
                                  ) * 1000
                                )
                                .format('HH:mm:ss')}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {singleTopic.sub_topics.length >= 1 &&
                              singleTopic.sub_topics.map((singleSubTopic, ii) => (
                                <Accordion key={singleSubTopic.id}>
                                  <AccordionSummary
                                    // expandIcon={<KeyboardArrowDownIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >
                                    <Typography sx={{ width: '90%' }}>
                                      {index + 1}. {i + 1}. {ii + 1}.{' '}
                                      {singleSubTopic.title}
                                    </Typography>
                                    <Typography sx={{ color: 'text.secondary' }}>
                                      {moment
                                        .utc(
                                          displayVideoDuration(
                                            singleSubTopic.published_videos
                                          ) * 1000
                                        )
                                        .format('HH:mm:ss')}
                                    </Typography>
                                  </AccordionSummary>
                                </Accordion>
                              ))}
                          </AccordionDetails>
                        </Accordion>
                      </>
                    ))}
                </AccordionDetails>
              </Accordion>
            </>
          ))}
        </>
      ) : null}
    </Paper>
  );
}

export default DisplayCourseCurriculum;
