/* eslint-disable no-unused-vars */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import ChangeVideoDuration from '../../ChangeVideoDuration';
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDeleteVideoMutation } from '../../../../../app/services/CoursesApi';
import { useSnackbar } from 'notistack';

function DisplayTopicVideos(props) {
  const { videos } = props;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(false);
  const [deleteVideo, deletionResult] = useDeleteVideoMutation();

  const viewVideo = (id) => {
    navigate(`/topic/video/${id}`);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const changeDuration = (e, video) => {
    e.stopPropagation();
    setSelectedVideo(video);
    setIsOpen(true);
  };

  const onDelete = (id) => {
    const res = deleteVideo({ id });
  };

  if (deletionResult.isError) {
    enqueueSnackbar(`${deletionResult.error.data.message}`, {
      variant: 'error',
    });
  }
  if (deletionResult.isSuccess) {
    enqueueSnackbar(`Video Deleted Successfully`, {
      variant: 'success',
    });
  }
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Update Duration</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {videos &&
              videos.length &&
              videos.map((singleVideo) => (
                <TableRow
                  key={singleVideo.id}
                  sx={{
                    '&:last-child td, &:last-child th': {
                      border: 0,
                    },
                  }}
                >
                  <TableCell component="th" scope="row">
                    <Button
                      variant="contained"
                      onClick={() => viewVideo(singleVideo.id)}
                    >
                      {singleVideo.title}&nbsp;
                      {<PlayCircleOutlineIcon />}
                    </Button>{' '}
                  </TableCell>

                  <TableCell>{singleVideo.status}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      onClick={(e) => changeDuration(e, singleVideo)}
                    >
                      {singleVideo.duration
                        ? `${moment
                            .utc(singleVideo.duration * 1000)
                            .format('HH:mm:ss')}`
                        : 'No duration set'}
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      // sx={{ pl: 4 }}
                      onClick={() => onDelete(singleVideo.id)}
                      endIcon={<DeleteIcon />}
                      variant="contained"
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <>
        {selectedVideo ? (
          <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle> Change {selectedVideo.title} Status</DialogTitle>
            <DialogContent>
              <ChangeVideoDuration video={selectedVideo} />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </Dialog>
        ) : null}
      </>
    </>
  );
}

export default DisplayTopicVideos;
