/* eslint-disable no-unused-vars */
import {
  Button,
  Box,
  TextField,
  MenuItem,
  InputAdornment,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  DateTimePicker,
  LocalizationProvider,
  MobileTimePicker,
} from '@mui/x-date-pickers';
import { useSnackbar } from 'notistack';
import MomentUtils from '@date-io/moment';
import {
  useCreateSubjectMutation,
  useUpdateSubjectMutation,
} from '../../../../app/services/CoursesApi';

function UpdateSubject(props) {
  const { subject } = props;

  const { enqueueSnackbar } = useSnackbar();

  const [updateSubject, result] = useUpdateSubjectMutation();
  // const [weekDays, setWeekDays] = React.useState([
  //   { title: 'Monday', id: 0, checked: false },
  //   { title: 'Tuesday', id: 1, checked: false },
  //   { title: 'Wednesday', id: 2, checked: false },
  //   { title: 'Thursday', id: 3, checked: false },
  //   { title: 'Friday', id: 4, checked: false },
  //   { title: 'Saturday', id: 5, checked: false },
  // ]);

  // const [startTime, setstartTime] = useState(moment());
  // const [endTime, setendTime] = useState(moment());

  // const handlestartTimeChange = (newValue) => {
  //   setstartTime(newValue);
  // };
  // const handleEndTimeChange = (newValue) => {
  //   setendTime(newValue);
  // };

  // const [checked, setChecked] = React.useState([true, false]);

  // const handleChange1 = (event) => {
  //   const weekDaysToUpdate = weekDays.map((singleDay) => {
  //     return { ...singleDay, checked: event.target.checked };
  //   });
  //   setWeekDays(weekDaysToUpdate);
  // };

  // const handleChange2 = (event) => {
  //   const weekDaysToUpdate = weekDays.map((singleDay) => {
  //     if (singleDay.id === parseInt(event.target.id)) {
  //       return {
  //         ...singleDay,
  //         checked: event.target.checked,
  //       };
  //     }
  //     return singleDay;
  //   });
  //   setWeekDays(weekDaysToUpdate);
  // };

  // const children = (
  //   <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
  //     {weekDays.map((singleDay) => {
  //       return (
  //         <FormControlLabel
  //           control={<Checkbox checked={singleDay.checked} id={singleDay.id} />}
  //           label={singleDay.title}
  //           key={singleDay.title}
  //           onChange={handleChange2}
  //         />
  //       );
  //     })}
  //   </Box>
  // );

  const validateData = (subjectData) => {
    let err = true;

    if (!subjectData.title) {
      enqueueSnackbar('Please provide all the required fields', {
        variant: 'error',
      });
    } else {
      err = false;
    }

    return err;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const subjectData = {
      id: subject.id,
      title: data.get('title'),
      description: data.get('description'),
      // language: data.get('language'),
      // start_time: startTime.format(),
      // end_time: endTime.format(),
    };

    const res = validateData(subjectData);
    if (!res) {
      updateSubject(subjectData);
    }
  };

  // const nand = (a, b, c, d, e, f) => {
  //   return !(a && b && c && d && e && f);
  // };

  return result.data ? (
    result.data.message
  ) : (
    <>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="title"
          label=" Title"
          name="title"
          autoComplete="title"
          defaultValue={subject.title}
          autoFocus
        />
        <TextField
          margin="normal"
          fullWidth
          id="description"
          label="Description"
          name="description"
          autoComplete="description"
          autoFocus
          multiline
          defaultValue={subject.description}
          rows="2"
        />

        {/* <div>
          <FormControlLabel
            label="Week Days"
            control={
              <Checkbox
                checked={
                  weekDays[0].checked &&
                  weekDays[1].checked &&
                  weekDays[2].checked &&
                  weekDays[3].checked &&
                  weekDays[4].checked &&
                  weekDays[5].checked
                }
                indeterminate={nand(
                  weekDays[0].checked,
                  weekDays[1].checked,
                  weekDays[2].checked,
                  weekDays[3].checked,
                  weekDays[4].checked,
                  weekDays[5].checked
                )}
                onChange={handleChange1}
              />
            }
          />
          {children}
        </div>

        <Box sx={{ mt: 2 }}>
          <LocalizationProvider dateAdapter={MomentUtils}>
            <MobileTimePicker
              label="Start Time"
              value={startTime}
              onChange={handlestartTimeChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={MomentUtils}>
            <MobileTimePicker
              label="End Time"
              value={endTime}
              onChange={handleEndTimeChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Box> */}

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={result.isLoading || result.isSuccess}
        >
          Update Subject
        </Button>
      </Box>
    </>
  );
}

export default UpdateSubject;
