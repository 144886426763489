/* eslint-disable no-unused-vars */
import { Button, Rating, TextField, Typography } from '@mui/material';
import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useSnackbar } from 'notistack';
import { useCreateTestReviewMutation } from '../../../app/services/StudentTestsApi';

function TestReview(props) {
  const { test_id } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [rating, setRating] = React.useState(0);
  const [createTestReview, result] = useCreateTestReviewMutation();

  const validateData = (reviewData) => {
    let err = true;

    if (!rating) {
      enqueueSnackbar('Please provide rating', {
        variant: 'error',
      });
    } else if (!reviewData.reviewText) {
      enqueueSnackbar('Please provide your review of the Test in some words', {
        variant: 'error',
      });
    } else {
      err = false;
    }

    return err;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const reviewData = {
      reviewText: data.get('reviewText'),
      rating: rating,
      test_id: test_id,
    };

    const res = validateData(reviewData);
    if (!res) {
      createTestReview(reviewData);
    }
  };
  return result.data ? (
    result.data.message
  ) : (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <Box sx={{ display: 'flex' }}>
        <Rating
          name="simple-controlled"
          value={rating}
          size="large"
          precision={0.5}
          onChange={(event, newValue) => {
            setRating(newValue);
          }}
        />
        {rating !== 0 && <Box sx={{ ml: 2, marginTop: 1 }}>{rating}</Box>}
      </Box>
      <TextField
        margin="normal"
        required
        fullWidth
        id="reviewText"
        label=" Your opinion on the Test"
        name="reviewText"
        autoComplete="reviewText"
        multiline
        rows="2"
        autoFocus
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        disabled={result.isLoading || result.isSuccess}
      >
        Submit Review
      </Button>
    </Box>
  );
}

export default TestReview;
