import { Grid } from '@mui/material';
import React from 'react';
import { useEducatorsQuery } from '../../../app/services/AdminUsersApi';
import DataLoader from '../../../components/DataLoader';
import UsersList from './UsersList';

function EducatorsManager() {
  const { data, isLoading, isSuccess } = useEducatorsQuery();

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
          {isSuccess && data.educators.length ? (
            <UsersList users={data.educators} role="Educator" />
          ) : (
            <p>No Educators found</p>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default EducatorsManager;
