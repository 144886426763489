/* eslint-disable react/prop-types */
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      <Box>{children}</Box>
    </Typography>
  );
}

export default TabPanel;
