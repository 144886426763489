/* eslint-disable no-unused-vars */
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import TopicVideoProgressRequest from './subjects/topics/TopicVideoProgressRequest';
// eslint-disable-next-line no-undef
const API_URL = `${process.env.REACT_APP_BACKEND_API_PATH}/api`;

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

function DisplayAndUploadVideo(props) {
  const { enqueueSnackbar } = useSnackbar();

  const [duration, setSetDuration] = useState(0);
  const [progress, setProgress] = useState(0);
  const { sind, tind, topic, subject, handleSelectVideo } = props;
  const videoRef = useRef();
  const { token } = useSelector((state) => state.auth);
  const [videoStatus, setVideoStatus] = useState(null);
  const [processProgress, setProcessProgress] = useState(0);
  const [video, setVideo] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [vidUrl, setVideoUrl] = useState(null);

  const loadedMetaData = (event) => {
    const video = videoRef.current;
    if (!video) return;

    setSetDuration(Math.floor(video.duration));
  };

  useEffect(() => {
    if (selectedVideo) {
      setVideoUrl(URL.createObjectURL(selectedVideo));
    }
    videoRef.current?.load();
  }, [selectedVideo]);

  const fileUploadRequest = (uploadData) => {
    return axios.post(
      `${API_URL}/course/subject/topic/video/upload/${uploadData.topicId}`,
      {
        file: uploadData.file,
        file_type: 'topic',
        title: uploadData.title,
        description: uploadData.description,
        duration: uploadData.duration,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${uploadData.token}`,
        },

        onUploadProgress: (progressEvent) => {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          setProgress(percentCompleted);
        },
      }
    );
  };

  const UploadVideo = (sind, tind, subjectId, topicId) => {
    if (subject && topic) {
      const videoUploadData = {
        file: topic.videoFile,
        token: token,
        title: topic.title,
        description: topic.title,
        duration: duration,
        topicId: topicId,
      };

      setVideoStatus('PROCESSING');

      fileUploadRequest(videoUploadData)
        .then((res) => {
          if (res.data.video.id) {
            setVideo(res.data.video);
            setVideoStatus('SUCCESS');
            setProcessProgress(100);

            enqueueSnackbar('Video Uploaded Successfully.', {
              variant: 'success',
            });
          } else {
            setVideoStatus('ERROR');
            enqueueSnackbar('Error uploading Video. ', {
              variant: 'error',
            });
          }
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar(`Error uploading Video. ${topic.title}`, {
            variant: 'error',
          });
        });
    }
  };

  return (
    <>
      <Grid item xl="10" lg="10" md="12" sm="12" xs="12">
        <form
          className="form"
          style={{
            marginTop: '1rem',
          }}
        >
          <div
            className="file-upload-wrapper"
            data-text={topic.videoFile ? topic.videoFile.name : 'No File Selected'}
            data-button-text={topic.videoFile ? 'Change video' : 'Select video'}
          >
            <input
              name="file-upload-field"
              type="file"
              accept="video/*"
              className="file-upload-field"
              value=""
              onChange={(e) => {
                setSelectedVideo(e.target.files[0]);
                handleSelectVideo(sind, tind, e.target.files[0]);
              }}
              disabled={progress > 0}
            />
          </div>
        </form>
        {progress > 0 ? (
          <Box sx={{ width: '100%', mt: '4px' }}>
            <LinearProgressWithLabel value={progress} color="secondary" />
          </Box>
        ) : null}
        {/* {processProgress > 39 ? (
          <TopicVideoProgressRequest
            uploadProcessProgress={processProgress}
            setUploadProcessProgress={setProcessProgress}
            id={video.media_convert_job_id}
          />
        ) : null} */}

        {videoStatus === 'PROCESSING' ? (
          <Alert severity="info">Your video is being uploaded. Please Wait</Alert>
        ) : null}
        {videoStatus === 'ERROR' ? (
          <Alert severity="error">Error Uploading Video</Alert>
        ) : null}
        {processProgress === 100 ? (
          <Alert severity="success">Your video Uploaded Successfully</Alert>
        ) : null}
      </Grid>
      <Grid item xl="2" lg="2" md="12" sm="12" xs="12">
        {topic.videoFile ? (
          <>
            <video
              controls
              ref={videoRef}
              controlsList="nodownload"
              onLoadedMetadata={loadedMetaData}
            >
              <source
                src={topic.videoFile ? URL.createObjectURL(topic.videoFile) : null}
                type="video/mp4"
              />
              Your browser does not support HTML video.
            </video>
            <Button
              variant="contained"
              onClick={() => UploadVideo(sind, tind, subject.id, topic.id)}
              disabled={progress > 0}
            >
              Upload Video
            </Button>
          </>
        ) : // <img
        //   src={null}
        //   style={{
        //     border: '1px solid #c7c7c7',
        //     width: '100%',
        //     height: '100%',
        //   }}
        // />
        null}
      </Grid>
    </>
  );
}

export default DisplayAndUploadVideo;
