/* eslint-disable react/prop-types */
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

function EnrolledCourseTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3, overflowWrap: 'anywhere' }}>
          <Typography variant="subtitle1">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default EnrolledCourseTabPanel;
