import {
  Typography,
  Drawer,
  IconButton,
  Divider,
  Toolbar,
  Button,
  Link,
  Box,
  Avatar,
} from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import { styled, useTheme } from '@mui/material/styles';
import React from 'react';
import { Outlet, useNavigate } from 'react-router';
import Copyright from '../components/Copyright';
import SideBarList from './SideBarList';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SignOut from '../components/authentication/SignOut';
import { useSelector } from 'react-redux';
import SearchBar from './SearchBar';
import MenuToolBar from './MenuToolBar';
import useScrollTrigger from '@mui/material/useScrollTrigger';

const drawerWidth = 240;

const APP_NAME = `${process.env.REACT_APP_NAME}`;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: 0,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: drawerWidth,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

function AppLayout() {
  const { isSignedIn, user } = useSelector((state) => state.auth);
  const trigger = useScrollTrigger();

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();

  const onClick = () => {
    navigate('/select-account');
  };

  return (
    <>
      <AppBar
        position="fixed"
        open={window.screen.width > 600 && open}
        sx={{
          backgroundColor: 'transparent',
        }}
      >
        <Toolbar
          variant="dense"
          sx={{
            backgroundColor: 'darkcyan',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h6" noWrap component="div">
            <Link color="inherit" href="/" underline="none">
              {APP_NAME}
            </Link>
          </Typography>
          {window.screen.width > 600 ? <SearchBar /> : null}

          {isSignedIn ? (
            <Box sx={{ justifyContent: 'flex-end' }}>
              <SignOut />
              {/* {user.role === 'student' && free && free === true ? (
                <Button sx={{ color: 'lawngreen' }}>In free trial</Button>
              ) : null} */}
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerOpen}
                sx={{ ...(open && { display: 'none' }) }}
              >
                <Avatar sx={{ width: 32, height: 32 }}>
                  {user.name.charAt(0).toUpperCase()}
                </Avatar>
              </IconButton>
            </Box>
          ) : (
            <Box sx={{ justifyContent: 'flex-end' }}>
              <Button onClick={onClick} variant="" sx={{ my: 1, mx: 1.5 }}>
                Sign In
              </Button>
            </Box>
          )}
        </Toolbar>
        {trigger ? null : (
          <>
            {window.screen.width < 600 ? (
              <Toolbar
                sx={{
                  // backgroundColor: 'darkcyan',
                  margin: 0,
                  padding: 0,
                }}
              >
                <SearchBar />
              </Toolbar>
            ) : null}
            <MenuToolBar />
          </>
        )}
      </AppBar>

      <Main open={window.screen.width > 600 && open} sx={{ paddingTop: 12 }}>
        <DrawerHeader />
        <Outlet />
        <Copyright />
      </Main>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
        variant="temporary"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
          {isSignedIn && user ? (
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                padding: '0 8px',
              }}
            >
              {user.role.toUpperCase()}
            </Typography>
          ) : null}
        </DrawerHeader>
        <Divider />
        <SideBarList />
      </Drawer>
    </>
  );
}

export default AppLayout;
