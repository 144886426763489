/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BaseApi } from './BaseApi';

const API_URL = `${process.env.REACT_APP_BACKEND_API_PATH}/api`;
const LOCALSTORAGE_APP_KEY = `${process.env.REACT_APP_LOCALSTORAGE_KEY}`;

export const studentExamsApi = BaseApi.injectEndpoints({
  reducerPath: 'studentExamsApi',
  endpoints: (builder) => ({
    examEnrol: builder.mutation({
      query: (details) => ({
        url: '/exam/enrol/create',
        method: 'POST',
        body: {
          exam_id: details.exam_id,
        },
      }),
    }),
    enroledExams: builder.query({
      query: (token) => ({
        url: '/exam/enrols',
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    enroledExam: builder.query({
      query: (details) => ({
        url: `/exam/enrol/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['Exam Enrol'],
    }),
    createExamResult: builder.mutation({
      query: (details) => ({
        url: `/exam/result/create`,
        method: 'POST',
        body: {
          answers: details.answers,
          exam_id: details.examId,
        },
      }),
    }),
    createExamReview: builder.mutation({
      query: (details) => ({
        url: '/exam/review/create',
        method: 'POST',
        body: {
          rating: details.rating,
          review_text: details.reviewText,
          exam_id: details.exam_id,
        },
      }),
      invalidatesTags: ['Exam Enrol'],
    }),
  }),
});

export const {
  useExamEnrolMutation,
  useEnroledExamQuery,
  useEnroledExamsQuery,
  useCreateExamResultMutation,
  useCreateExamReviewMutation,
} = studentExamsApi;
