/* eslint-disable no-unused-vars */
import {
  Accordion,
  AccordionDetails,
  // AccordionSummary,
  Divider,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { Paper, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DisplayEnroledSubjectVideos from './DisplayEnroledSubjectVideos';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import DisplayEnroledSubTopicVideos from './DisplayEnroledSubTopicVideos';
import DisplayEnroledTopicVideos from './DisplayEnroledTopicVideos';
import moment from 'moment';

const style = {
  width: '100%',
  // maxWidth: 360,
  bgcolor: 'background.paper',
};

function DisplayEnroledCourseCurriculum(props) {
  const { subjects, enrolId, selectedIndex, progressData } = props;
  const navigate = useNavigate();

  const viewVideo = (videoId) => {
    navigate(`/enrols/${enrolId}/video/${videoId}`);
  };

  const findVideoProgress = (videoId, duration, videoProgressArr) => {
    const found =
      videoProgressArr &&
      videoProgressArr.find((singleProgress) => singleProgress.vid === videoId);
    if (found) {
      return (found.timeStamp * 100) / duration;
    } else {
      return 0;
    }
  };

  return (
    <>
      {subjects.length >= 1
        ? subjects.map((singleSubject, index) => (
            <List
              sx={style}
              component="nav"
              aria-label="mailbox folders"
              key={singleSubject.id}
            >
              <ListItem sx={{ display: 'block' }}>
                {singleSubject.published_videos.length >= 1 ? (
                  <>
                    <ListItemButton
                      selected={
                        selectedIndex && selectedIndex.subjectId === singleSubject.id
                      }
                      onClick={() => viewVideo(singleSubject.published_videos[0].id)}
                      sx={{ display: 'block' }}
                    >
                      <ListItemText
                        primary={`${index + 1}. ${singleSubject.title}`}
                      />
                      <Box sx={{ width: '100%' }}>
                        <ListItemIcon>
                          <OndemandVideoIcon />
                          &nbsp;
                          {/* {Math.abs(
                            singleSubject.published_videos[0].duration / 60
                          ).toFixed(2)}
                          min */}
                          {moment
                            .utc(singleSubject.published_videos[0].duration * 1000)
                            .format('HH:mm:ss')}
                        </ListItemIcon>
                        <LinearProgress
                          value={findVideoProgress(
                            singleSubject.published_videos[0].id,
                            singleSubject.published_videos[0].duration,
                            progressData
                          )}
                          color="secondary"
                          variant="determinate"
                        />
                      </Box>
                    </ListItemButton>
                  </>
                ) : (
                  <>
                    <ListItemText primary={`${index + 1}. ${singleSubject.title}`} />
                  </>
                )}
              </ListItem>

              <Divider />
              {singleSubject.topics.length >= 1
                ? singleSubject.topics.map((singleTopic, i) => (
                    <List
                      // sx={style}
                      component="nav"
                      aria-label="mailbox folders"
                      key={singleTopic.id}
                      sx={{ pl: 2 }}
                    >
                      <ListItem sx={{ display: 'block' }}>
                        {singleTopic.published_videos.length >= 1 ? (
                          <>
                            <ListItemButton
                              selected={
                                selectedIndex &&
                                selectedIndex.topicId === singleTopic.id
                              }
                              onClick={() =>
                                viewVideo(singleTopic.published_videos[0].id)
                              }
                              sx={{ display: 'block' }}
                            >
                              <ListItemText
                                primary={`${index + 1}.${i + 1}. ${
                                  singleTopic.title
                                }`}
                              />
                              <Box sx={{ width: '100%' }}>
                                <ListItemIcon>
                                  <OndemandVideoIcon />
                                  &nbsp;{' '}
                                  {/* {Math.abs(
                                    singleTopic.published_videos[0].duration / 60
                                  ).toFixed(2)}
                                  min{' '} */}
                                  {moment
                                    .utc(
                                      singleTopic.published_videos[0].duration * 1000
                                    )
                                    .format('HH:mm:ss')}
                                </ListItemIcon>
                                <LinearProgress
                                  value={findVideoProgress(
                                    singleTopic.published_videos[0].id,
                                    singleTopic.published_videos[0].duration,
                                    progressData
                                  )}
                                  color="secondary"
                                  variant="determinate"
                                />
                              </Box>
                            </ListItemButton>
                          </>
                        ) : (
                          <ListItemText
                            primary={`${index + 1}.${i + 1}. ${singleTopic.title}`}
                          />
                        )}
                      </ListItem>

                      <Divider />
                      {singleTopic.sub_topics.length >= 1
                        ? singleTopic.sub_topics.map((singleSubTopic, ii) => (
                            <List
                              // sx={style}
                              sx={{ pl: 2 }}
                              component="nav"
                              aria-label="mailbox folders"
                              key={singleSubTopic.id}
                            >
                              <ListItem sx={{ display: 'block' }}>
                                {singleSubTopic.published_videos.length >= 1 ? (
                                  <>
                                    <ListItemButton
                                      selected={
                                        selectedIndex &&
                                        selectedIndex.subTopicId ===
                                          singleSubTopic.id
                                      }
                                      onClick={() =>
                                        viewVideo(
                                          singleSubTopic.published_videos[0].id
                                        )
                                      }
                                      sx={{ display: 'block' }}
                                    >
                                      <ListItemText
                                        primary={`${index + 1}. ${i + 1}. ${
                                          ii + 1
                                        }. ${singleSubTopic.title}`}
                                      />

                                      <Box sx={{ width: '100%' }}>
                                        <ListItemIcon>
                                          <OndemandVideoIcon />
                                          &nbsp;{' '}
                                          {/* {Math.abs(
                                            singleSubTopic.published_videos[0]
                                              .duration / 60
                                          ).toFixed(2)}
                                          min */}
                                          {moment
                                            .utc(
                                              singleSubTopic.published_videos[0]
                                                .duration * 1000
                                            )
                                            .format('HH:mm:ss')}
                                        </ListItemIcon>
                                        <LinearProgress
                                          value={findVideoProgress(
                                            singleSubTopic.published_videos[0].id,
                                            singleSubTopic.published_videos[0]
                                              .duration,
                                            progressData
                                          )}
                                          color="secondary"
                                          variant="determinate"
                                        />
                                      </Box>
                                    </ListItemButton>
                                  </>
                                ) : (
                                  <ListItemText
                                    primary={`${index + 1}. ${i + 1}. ${ii + 1}. ${
                                      singleSubTopic.title
                                    }`}
                                  />
                                )}
                              </ListItem>

                              <Divider />
                            </List>
                          ))
                        : null}
                    </List>
                  ))
                : null}
            </List>
          ))
        : null}
    </>
  );
}

export default DisplayEnroledCourseCurriculum;
