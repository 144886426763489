import { Button, Box, TextField } from '@mui/material';
import { useCreateCategoryMutation } from '../../../app/services/CategoriesApi';

function CreateNewCategory() {
  const [createCategory, result] = useCreateCategoryMutation();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const categoryData = {
      title: data.get('title'),
    };

    await createCategory(categoryData);
  };
  return result.data ? (
    result.data.message
  ) : (
    <>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="title"
          label="Category Title"
          name="title"
          autoComplete="title"
          autoFocus
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={result.isLoading || result.isSuccess}
        >
          Add Category
        </Button>
      </Box>
    </>
  );
}

export default CreateNewCategory;
