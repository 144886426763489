/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
import {
  ArrowDropDown,
  CheckBox,
  ExpandMore,
  FormatListBulleted,
} from '@mui/icons-material';
import {
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  TablePagination,
  Menu,
  MenuItem,
  Button,
  ClickAwayListener,
  Grow,
  Popper,
  ButtonGroup,
  MenuList,
} from '@mui/material';
import moment from 'moment';
import React, { useRef } from 'react';
import { useNavigate } from 'react-router';
import {
  useDeleteCourseMutation,
  useUpdateCourseSatusMutation,
} from '../../../app/services/CoursesApi';

import { styled, alpha } from '@mui/material/styles';
import { groupBy, toUpper } from 'lodash';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { TableVirtuoso } from 'react-virtuoso';

const columns = [
  {
    width: 20,
    label: '#',
  },
  {
    width: 200,
    label: 'Title',
  },
  {
    width: 120,
    label: 'Status',
  },
  {
    width: 120,
    label: 'Category',
  },
  {
    width: 120,
    label: 'Sub Category',
  },
  {
    width: 120,
    label: 'Created On',
  },
  {
    width: 120,
    label: 'Action',
  },
];

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
  ),
  TableHead,
  TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
  TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
function fixedHeaderContent() {
  return (
    <TableRow>
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          variant="head"
          align={column.numeric || false ? 'right' : 'left'}
          style={{ width: column.width }}
          sx={{
            // backgroundColor: 'background.paper',
            background: 'lightgray',
          }}
        >
          {column.label}
        </TableCell>
      ))}
    </TableRow>
  );
}

function generateGroupedVideos(courses) {
  const groupedUsers = groupBy(courses, (course) => course.title[0]);
  const groupCounts = Object.values(groupedUsers).map((users) => users.length);
  const groups = Object.keys(groupedUsers);

  return { groupCounts, groups };
}

function CoursesList(props) {
  const { courses } = props;
  const navigate = useNavigate();
  const virtuoso = useRef(null);
  const [selectedCourse, setSelectedCourse] = React.useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleMenuClick = (event, course) => {
    setSelectedCourse(course);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [expandedId, setExpandedId] = React.useState(-1);

  const handleClick = (i) => {
    setExpandedId(expandedId === i ? -1 : i);
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [updateCourseStatus, result] = useUpdateCourseSatusMutation();

  const [deleteCourse, deletionResult] = useDeleteCourseMutation();

  const viewCourse = (id) => {
    navigate(`/courses/${id}`);
  };

  const onStatusChange = (id, status) => {
    const res = updateCourseStatus({ id, status });
  };

  const onDelete = (id) => {
    const res = deleteCourse({ id });
  };

  const { groups, groupCounts } = generateGroupedVideos(courses);
  const renderMenuItems = (selectedCourse) => {
    return selectedCourse ? (
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            viewCourse(selectedCourse.id);
            handleClose();
          }}
        >
          View
        </MenuItem>
        <MenuItem
          onClick={() => {
            onStatusChange(
              selectedCourse.id,
              selectedCourse.status === 'draft' || !selectedCourse.status
                ? 'published'
                : 'draft'
            );
            handleClose();
          }}
        >
          {selectedCourse.status === 'draft' || !selectedCourse.status
            ? 'Publish'
            : 'Draft'}
        </MenuItem>
        <MenuItem
          onClick={() => {
            onDelete(selectedCourse.id);
            handleClose();
          }}
        >
          Delete
        </MenuItem>
      </StyledMenu>
    ) : null;
  };
  return (
    <>
      <Typography
        component="div"
        variant="h5"
        sx={{ color: '#ef3768', fontSize: '18px', fontWeight: 600 }}
      >
        My Courses
      </Typography>
      <ul
        style={{
          marginLeft: '0.5rem',
          paddingLeft: '0',
          listStyle: 'none',
          fontSize: '0.8rem',
          display: 'flex',
        }}
      >
        {groupCounts
          .reduce(
            ({ firstItemsIndexes, offset }, count) => {
              return {
                firstItemsIndexes: [...firstItemsIndexes, offset],
                offset: offset + count,
              };
            },
            { firstItemsIndexes: [], offset: 0 }
          )
          .firstItemsIndexes.map((itemIndex, index) => (
            <li key={index}>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  virtuoso.current.scrollToIndex({
                    index: itemIndex,
                  });
                }}
              >
                {toUpper(groups[index])}
              </a>
              &nbsp;
            </li>
          ))}
      </ul>{' '}
      <Paper style={{ height: 400, width: '100%' }}>
        <TableVirtuoso
          data={courses}
          ref={virtuoso}
          components={VirtuosoTableComponents}
          fixedHeaderContent={fixedHeaderContent}
          checkboxSelection
          itemContent={(index, singleCourse) => (
            <>
              {' '}
              <TableCell component="th" scope="row">
                {/* <CheckBox {...{ inputProps: { 'aria-label': 'Checkbox demo' } }} />{' '} */}
                {index + 1}
              </TableCell>
              <TableCell>{singleCourse.title}</TableCell>
              <TableCell sx={{ color: '#7793bd' }}>
                {singleCourse.status || 'draft'}
              </TableCell>
              <TableCell sx={{ color: '#7793bd' }}>
                {singleCourse.sub_category
                  ? singleCourse.sub_category.category.title
                  : null}
              </TableCell>
              <TableCell sx={{ color: '#7793bd' }}>
                {singleCourse.sub_category ? singleCourse.sub_category.title : null}
              </TableCell>
              <TableCell sx={{ color: '#7793bd' }}>
                {moment(singleCourse.created_at).format('Do MMM YY hh:mm A')}
              </TableCell>
              <TableCell>
                <Button
                  id="demo-customized-button"
                  aria-controls={open ? 'demo-customized-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  variant="contained"
                  disableElevation
                  onClick={(e) => handleMenuClick(e, singleCourse)}
                  endIcon={<KeyboardArrowDownIcon />}
                  startIcon={<FormatListBulleted />}
                >
                  Action
                </Button>
                {renderMenuItems(selectedCourse)}
              </TableCell>
            </>
          )}
        />
      </Paper>
    </>
  );
}

export default CoursesList;
