import { Typography } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router';
import { useLearningObjectiveQuery } from '../../../../app/services/LearningObjectivesApi';
import DataLoader from '../../../../components/DataLoader';
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';

function SingleLearningObjective() {
  const params = useParams();

  const { data, isLoading, isSuccess } = useLearningObjectiveQuery({
    id: parseInt(params.Id, 10),
  });

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  return (
    <>
      {isSuccess && data.learningObjective ? (
        <>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{ padding: 4, color: '#233c8d' }}
          >
            Title: {data.learningObjective.title}
          </Typography>
          <Typography
            component="div"
            variant="h5"
            sx={{ padding: 4, color: '#233c8d' }}
          >
            Objectives
          </Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.learningObjective.objectives &&
                  data.learningObjective.objectives.map((singleObjective, index) => (
                    <TableRow
                      key={singleObjective.id}
                      sx={{
                        '&:last-child td, &:last-child th': {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell>{singleObjective.title}</TableCell>
                      <TableCell>{singleObjective.description}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <p>No Learning Objective found</p>
      )}
    </>
  );
}

export default SingleLearningObjective;
