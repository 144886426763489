/* eslint-disable no-unused-vars */
import { ExpandMore } from '@mui/icons-material';
import {
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  TablePagination,
  Button,
} from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router';

/* eslint-disable no-unused-vars */
function CourseViolationsList(props) {
  const navigate = useNavigate();

  const { courseViolations } = props;
  const viewViolation = (id) => {
    navigate(`/course/violations/${id}`);
  };

  return (
    <>
      <Typography component="div" variant="h5" sx={{ padding: 4, color: '#233c8d' }}>
        Course Violations
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Course</TableCell>
              <TableCell>Reported By </TableCell>
              <TableCell>Issue Type</TableCell>

              <TableCell>Reported On</TableCell>

              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {courseViolations.length &&
              courseViolations.map((singleViolation, index) => (
                <TableRow
                  key={singleViolation.id}
                  sx={{
                    '&:last-child td, &:last-child th': {
                      border: 0,
                    },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell>{singleViolation.course.title}</TableCell>
                  <TableCell>{singleViolation.reporter.email}</TableCell>
                  <TableCell>{singleViolation.issue_type}</TableCell>

                  <TableCell>
                    {moment(singleViolation.created_at).format('Do MMM YY hh:mm A')}
                  </TableCell>
                  <TableCell>
                    <Button
                      sx={{ pl: 4 }}
                      onClick={() => viewViolation(singleViolation.id)}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default CourseViolationsList;
