import { Grid } from '@mui/material';
import { useVideoViolationsQuery } from '../../../../app/services/CoursesApi';
import DataLoader from '../../../../components/DataLoader';
import VideoViolationsList from './VideoViolationsList';

function VideoViolationManager() {
  const { data, isLoading, isSuccess } = useVideoViolationsQuery();

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
          {isSuccess && data.videoViolations ? (
            <VideoViolationsList videoViolations={data.videoViolations} />
          ) : (
            <p>No Video Violations found</p>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default VideoViolationManager;
