/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Card, CardActions, CardHeader, Button, Alert } from '@mui/material';

const QuestionTimer = ({
  duration,
  resetCountDown,
  questionNumber,
  submitAnswers,
  lastQuestion,
  setQuestionsFinished,
}) => {
  const [counter, setCounter] = useState(duration);
  let interval = null;
  useEffect(() => {
    const myInterval = () => {
      if (counter > 1) {
        setCounter((state) => state - 1);
      } else if (counter !== 0 && lastQuestion) {
        setCounter(0);
        clearInterval(interval);
      } else if (counter !== 0) {
        resetCountDown(questionNumber + 1);
      }
    };

    if (lastQuestion && counter === 0) {
      setQuestionsFinished();
    }
    interval = setInterval(myInterval, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [counter]);

  useEffect(() => {
    if (!interval) {
      setCounter(duration);
    }
  }, [questionNumber]);

  return (
    <div>
      <Card sx={{ textAlign: 'center' }}>
        <CardHeader
          title="Time Remaining"
          subheader={moment('00:00:00.000', 'hh:mm:ss.SSS')
            .add(counter, 's')
            .format('mm:ss')}
        />
        <CardActions sx={{ textAlign: 'center', justifyContent: 'center' }}>
          {lastQuestion && counter === 0 ? (
            <Button onClick={() => submitAnswers()} variant="contained">
              Submit Answers
            </Button>
          ) : lastQuestion ? (
            <>
              <Alert severity="info">Last Question </Alert>
              <Button onClick={() => submitAnswers()} variant="contained">
                Submit Answers
              </Button>
            </>
          ) : (
            <Button
              onClick={() => resetCountDown(questionNumber + 1)}
              variant="contained"
            >
              Next
            </Button>
          )}
        </CardActions>
      </Card>
    </div>
  );
};

export default QuestionTimer;
