/* eslint-disable no-unused-vars */
import {
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
} from '@mui/material';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router';
import {
  useGetCourseViolationQuery,
  useGetVideoViolationQuery,
} from '../../../../app/services/CoursesApi';
import DataLoader from '../../../../components/DataLoader';

function SingleCourseViolation() {
  const params = useParams();
  const navigate = useNavigate();

  const { data, isLoading, isSuccess } = useGetCourseViolationQuery({
    id: parseInt(params.Id, 10),
  });

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  const onCourseClick = (course) => {
    navigate(`/courses/${course.id}`);
  };
  return (
    <>
      {isSuccess && data.courseViolation ? (
        <Grid container spacing={1}>
          <Grid item xs="12" sm="12" md="1" lg="1" xl="1" align="left"></Grid>

          <Grid item xs="12" sm="12" md="6" lg="6" xl="6" align="left">
            <Card>
              <CardMedia
                component="img"
                sx={{ width: 340, display: { xs: 'none', sm: 'block' } }}
                image={
                  data.courseViolation.course.image_url ||
                  `https://images.unsplash.com/photo-1516397281156-ca07cf9746fc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80`
                }
              />
              <CardContent sx={{ overflowWrap: 'anywhere' }}>
                <Typography component="h2" variant="h5">
                  Reported Course Violation
                </Typography>
                <Button
                  onClick={() => onCourseClick(data.courseViolation.course)}
                  sx={{ textAlign: 'start' }}
                >
                  {data.courseViolation.course.title}
                </Button>

                <Typography variant="subtitle1" paragraph>
                  Issue: {data.courseViolation.issue_type}
                </Typography>
                <Typography variant="subtitle1" paragraph>
                  Issue Details: {data.courseViolation.issue_details}
                </Typography>

                <Typography variant="subtitle1" paragraph>
                  Reported By: {data.courseViolation.reporter.email}
                </Typography>

                <Typography variant="subtitle1" paragraph>
                  {moment(data.courseViolation.created_at).format(
                    'Do MMM YY hh:mm A'
                  )}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      ) : (
        <p>No course violation found</p>
      )}
    </>
  );
}

export default SingleCourseViolation;
