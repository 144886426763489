import { ThemeProvider } from '@emotion/react';
import {
  Button,
  Checkbox,
  Container,
  CssBaseline,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  Box,
  createTheme,
  Avatar,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';

import { signIn, toggleforgotPasswordButton } from '../../../app/slices/authSlice';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

const theme = createTheme();

// Sign In Page with ui form
function InstitutionSignInPage(props) {
  const { changeView } = props;
  const dispatch = useDispatch();
  const { isSignedIn } = useSelector((state) => state.auth);
  const [verifyButton, setVerifyButton] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const ValidateEmail = (mail) => {
    // eslint-disable-next-line no-useless-escape
    const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (mail.match(mailFormat)) {
      return true;
    }
    return false;
  };
  const validateData = (authData) => {
    let err = true;

    if (!authData.email || !authData.password) {
      enqueueSnackbar('Both Email and Password are required', { variant: 'error' });
    } else if (!ValidateEmail(authData.email)) {
      enqueueSnackbar('Please enter a valid Email address', { variant: 'error' });
    } else if (authData.password.length < 8) {
      enqueueSnackbar('Password length must be at least 8 chars', {
        variant: 'error',
      });
    } else {
      err = false;
    }

    return err;
  };

  // Handle Submit of the form
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const Authdata = {
      email: data.get('email'),
      password: data.get('password'),
    };

    const res = validateData(Authdata);
    if (!res) {
      // Dispatch with signin parameters
      dispatch(signIn(Authdata))
        .unwrap()
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          enqueueSnackbar('SignIn Success', {
            variant: 'success',
          });
        })
        .catch((error) => {
          const errRes = Object.values(error.data);
          errRes.forEach((singleError) => {
            singleError.forEach((message) => {
              if (message === 'EMAIL_NOT_VERIFIED') {
                setVerifyButton(true);
              }
              enqueueSnackbar(message, { variant: 'error' });
            });
          });
        });
    }
  };

  if (isSignedIn) {
    return <Navigate replace to="/" />;
  }

  const openRegisterTab = (e) => changeView(e, 1);

  const toggleForgotPassword = () => {
    dispatch(toggleforgotPasswordButton());
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <>
              <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Sign In
              </Typography>
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign In
                </Button>
                {verifyButton ? (
                  <div>
                    <Link to="/resend/email/verification">Verify Your Email</Link>
                  </div>
                ) : null}
                <Grid container>
                  <Grid item xs>
                    <Button onClick={toggleForgotPassword}>Forgot password?</Button>
                  </Grid>
                  <Grid item xs>
                    <Typography>
                      <Button onClick={openRegisterTab}>Create New Account</Button>
                    </Typography>
                  </Grid>
                </Grid>
                {/* <Typography style={{ color: '#9799A3', font: '800 250%' }}>
                  or
                </Typography> */}
              </Box>
            </>
          </Box>
        </Container>
      </ThemeProvider>
    </>
  );
}

export default InstitutionSignInPage;
