import { Button, Box, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCreatePublicationMutation } from '../../../app/services/CategoriesApi';

function CreateNewPublication() {
  const [createPublication, result] = useCreatePublicationMutation();
  const { enqueueSnackbar } = useSnackbar();

  const validateData = (publicationData) => {
    let err = true;

    if (!publicationData.title) {
      enqueueSnackbar('Please provide all the required fields', {
        variant: 'error',
      });
    } else {
      err = false;
    }

    return err;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const publicationData = {
      title: data.get('title'),
      edition: data.get('edition'),
    };

    const res = validateData(publicationData);
    if (!res) {
      createPublication(publicationData);
    }
  };
  return result.data ? (
    result.data.message
  ) : (
    <>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="title"
          label="Publication Title"
          name="title"
          autoComplete="title"
          autoFocus
        />
        <TextField
          margin="normal"
          // required
          fullWidth
          id="edition"
          label="Edition"
          name="edition"
          autoComplete="edition"
          type="number"
          autoFocus
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={result.isLoading || result.isSuccess}
        >
          Add Publication
        </Button>
      </Box>
    </>
  );
}

export default CreateNewPublication;
