/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
import { ExpandMore } from '@mui/icons-material';
import {
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  TablePagination,
} from '@mui/material';
import moment from 'moment';
import React, { useRef } from 'react';
import { useNavigate } from 'react-router';
import {
  useDeleteExamMutation,
  useUpdateExamSatusMutation,
} from '../../../app/services/ExamsApi';

import { groupBy, toUpper } from 'lodash';

import { TableVirtuoso } from 'react-virtuoso';

const columns = [
  {
    width: 20,
    label: '#',
  },
  {
    width: 120,
    label: 'Title',
  },
  {
    width: 120,
    label: 'Status',
  },
  {
    width: 120,
    label: 'Category',
  },
  {
    width: 120,
    label: 'Sub Category',
  },
  {
    width: 120,
    label: 'Created On',
  },
  {
    width: 120,
    label: 'Action',
  },
];

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
  ),
  TableHead,
  TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
  TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};

function fixedHeaderContent() {
  return (
    <TableRow>
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          variant="head"
          align={column.numeric || false ? 'right' : 'left'}
          style={{ width: column.width }}
          sx={{
            backgroundColor: 'background.paper',
          }}
        >
          {column.label}
        </TableCell>
      ))}
    </TableRow>
  );
}

function generateGroupedVideos(exams) {
  const groupedUsers = groupBy(exams, (exam) => exam.title[0]);
  const groupCounts = Object.values(groupedUsers).map((users) => users.length);
  const groups = Object.keys(groupedUsers);

  return { groupCounts, groups };
}

function ExamsList(props) {
  const { exams } = props;
  const navigate = useNavigate();
  const virtuoso = useRef(null);

  const [expandedId, setExpandedId] = React.useState(-1);

  const handleClick = (i) => {
    setExpandedId(expandedId === i ? -1 : i);
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [updateExamStatus, result] = useUpdateExamSatusMutation();

  const [deleteExam, deletionResult] = useDeleteExamMutation();

  const viewExam = (id) => {
    navigate(`/exams/${id}`);
  };

  const onStatusChange = (id, status) => {
    const res = updateExamStatus({ id, status });
  };

  const onDelete = (id) => {
    const res = deleteExam({ id });
  };
  const { groups, groupCounts } = generateGroupedVideos(exams);
  return (
    <>
      <Typography component="div" variant="h5" sx={{ padding: 4, color: '#233c8d' }}>
        Exams
      </Typography>
      <ul
        style={{
          marginLeft: '0.5rem',
          paddingLeft: '0',
          listStyle: 'none',
          fontSize: '0.8rem',
          display: 'flex',
        }}
      >
        {groupCounts
          .reduce(
            ({ firstItemsIndexes, offset }, count) => {
              return {
                firstItemsIndexes: [...firstItemsIndexes, offset],
                offset: offset + count,
              };
            },
            { firstItemsIndexes: [], offset: 0 }
          )
          .firstItemsIndexes.map((itemIndex, index) => (
            <li key={index}>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  virtuoso.current.scrollToIndex({
                    index: itemIndex,
                  });
                }}
              >
                {toUpper(groups[index])}
              </a>
              &nbsp;
            </li>
          ))}
      </ul>{' '}
      <Paper style={{ height: 400, width: '100%' }}>
        <TableVirtuoso
          data={exams}
          ref={virtuoso}
          components={VirtuosoTableComponents}
          fixedHeaderContent={fixedHeaderContent}
          itemContent={(index, singleExam) => (
            <>
              <TableCell component="th" scope="row">
                {index + 1}
              </TableCell>
              <TableCell>{singleExam.title}</TableCell>
              <TableCell>{singleExam.status || 'draft'}</TableCell>
              <TableCell>
                {singleExam.sub_category
                  ? singleExam.sub_category.category.title
                  : null}
              </TableCell>
              <TableCell>
                {singleExam.sub_category ? singleExam.sub_category.title : null}
              </TableCell>
              <TableCell>
                {moment(singleExam.created_at).format('Do MMM YY hh:mm A')}
              </TableCell>
              <TableCell>
                <List
                  sx={{
                    width: '100%',
                    maxWidth: 200,
                    bgcolor: 'background.paper',
                  }}
                >
                  <ListItemButton
                    onClick={() => handleClick(index)}
                    aria-expanded={expandedId === index}
                  >
                    <ListItemText primary="Options" />
                    <ExpandMore />
                  </ListItemButton>
                  <Collapse in={expandedId === index} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        onClick={() => viewExam(singleExam.id)}
                      >
                        <ListItemText primary="View" />
                      </ListItemButton>
                    </List>
                    <List component="div" disablePadding>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        onClick={() =>
                          onStatusChange(
                            singleExam.id,
                            singleExam.status === 'draft' || !singleExam.status
                              ? 'published'
                              : 'draft'
                          )
                        }
                      >
                        <ListItemText
                          primary={
                            singleExam.status === 'draft' || !singleExam.status
                              ? 'Publish'
                              : 'Draft'
                          }
                        />
                      </ListItemButton>
                    </List>
                    <List component="div" disablePadding>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        onClick={() => onDelete(singleExam.id)}
                      >
                        <ListItemText primary="Delete" />
                      </ListItemButton>
                    </List>
                  </Collapse>
                </List>
              </TableCell>
            </>
          )}
        />
      </Paper>
    </>
  );
}

export default ExamsList;
