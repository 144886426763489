/* eslint-disable no-unused-vars */
import { Button, Box, TextField, MenuItem, InputAdornment } from '@mui/material';

import { useSnackbar } from 'notistack';
import { useCreateSubscriptionPlanMutation } from '../../../app/services/SunscriptionPlansApi';

function CreateNewSubscriptionPlan() {
  const { enqueueSnackbar } = useSnackbar();
  const [createSubscriptionPlan, result] = useCreateSubscriptionPlanMutation();

  const validateData = (subscriptionPlanData) => {
    let err = true;

    if (!subscriptionPlanData.title || !subscriptionPlanData.cost) {
      enqueueSnackbar('Please provide all the required fields', {
        variant: 'error',
      });
    } else {
      err = false;
    }

    return err;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const subscriptionPlanData = {
      title: data.get('title'),
      description: data.get('description'),
      cost: data.get('cost'),
    };

    const res = validateData(subscriptionPlanData);
    if (!res) {
      createSubscriptionPlan(subscriptionPlanData);
    }
  };

  return result.data ? (
    result.data.message
  ) : (
    <>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="title"
          label=" Title"
          name="title"
          autoComplete="title"
          autoFocus
        />
        <TextField
          margin="normal"
          fullWidth
          id="description"
          label="Description"
          name="description"
          autoComplete="description"
          autoFocus
          multiline
          rows="2"
        />

        <TextField
          margin="normal"
          // required
          fullWidth
          id="cost"
          label="Cost"
          name="cost"
          autoComplete="cost"
          type="number"
          autoFocus
          InputProps={{
            startAdornment: <InputAdornment position="start">₹</InputAdornment>,
          }}
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={result.isLoading || result.isSuccess}
        >
          Add Subscription Plan
        </Button>
      </Box>
    </>
  );
}

export default CreateNewSubscriptionPlan;
