/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Avatar,
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';
import { styled as Style } from '@mui/material/styles';
import EnrolledCourseTabPanel from '../../courses/student/EnrolledCourseTabPanel';
import {
  useEducatorProfileQuery,
  useUpdateEducatorProfileMutation,
} from '../../../app/services/EducatorApi';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import UploadEducatorProfilePicture from './UploadEducatorProfilePicture';
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
import moment from 'moment';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { OpenInNew } from '@mui/icons-material';
import UpdateEducatorProfile from './UpdateEducatorProfile';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import MomentUtils from '@date-io/moment';

const BorderLinearProgress = Style(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#02b2af' : '#308fe8',
    borderColor: theme.palette.mode === 'light' ? '#02b2af' : '#308fe8',
    border: '0.5px solid 1a90ff',
  },
}));
export const EducatorProfile = () => {
  const { token, user, isSignedIn } = useSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();

  const [value, setValue] = React.useState(0);
  const educator = useEducatorProfileQuery();
  const [updateEducatorProfile, result] = useUpdateEducatorProfileMutation();
  const [open, setOpen] = React.useState(false);
  const [updateProfile, setUpdateProfile] = useState(false);
  const [updateProfilePic, setUpdateProfilePic] = useState(false);
  const [updateProfileMain, setUpdateProfileMain] = useState(false);
  const [gender, setGender] = useState(
    educator?.data?.profile?.gender ? educator.data.profile.gender : ''
  );
  const [dob, setDob] = useState(
    educator?.data?.profile?.date_of_birth
      ? moment(educator.data.profile.date_of_birth)
      : moment()
  );
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }
  const blue = {
    100: '#DAECFF',
    200: '#b6daff',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    900: '#003A75',
  };

  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };

  const TextareaAutosize = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    width: 98%;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
  
    &:hover {
      border-color: ${blue[400]};
    }
  
    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
    }
  
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const profileData = {
      id: educator.data.profile.id,
      first_name: data.get('firstName'),
      last_name: data.get('lastName'),
      qualification: data.get('qualification'),
      language: data.get('language'),
      location: data.get('location'),
      headline: data.get('headline'),
      bio: data.get('bio'),
      subject: data.get('subject'),
      dob: dob.format(),
      gender: gender,
      address: data.get('address'),
      pincode: data.get('pincode'),
      gstno: data.get('gstno'),
    };

    updateEducatorProfile(profileData)
      .unwrap()
      .then(() => {
        enqueueSnackbar('Profile Update Success', {
          variant: 'success',
        });
      })
      .catch((error) => {
        enqueueSnackbar('Error Updating Profile', {
          variant: 'error',
        });
      });

    // setUpdateProfile(false);
  };

  const profileProgress = (profileData) => {
    let initialProgress = 0;
    if (profileData.profile.first_name) {
      initialProgress += 10;
    }

    if (profileData.profile.date_of_birth) {
      initialProgress += 10;
    }
    if (profileData.profile.location) {
      initialProgress += 10;
    }
    if (profileData.profile.address) {
      initialProgress += 5;
    }
    if (profileData.profile.qualification) {
      initialProgress += 5;
    }
    if (profileData.profile.gender) {
      initialProgress += 10;
    }
    if (profileData.profile.pin_code) {
      initialProgress += 5;
    }
    if (profileData.profile.preferred_languages) {
      initialProgress += 5;
    }
    if (profileData.profile.teaching_interests) {
      initialProgress += 5;
    }
    if (profileData.profile.gst_number) {
      initialProgress += 5;
    }
    if (profileData.profile.last_name) {
      initialProgress += 5;
    }
    if (profileData.profile.bio) {
      initialProgress += 10;
    }
    if (profileData.profile.subject) {
      initialProgress += 5;
    }
    if (profileData.profile.headline) {
      initialProgress += 5;
    }
    if (profileData.profile.language) {
      initialProgress += 5;
    }

    return initialProgress;
  };
  return (
    <>
      {educator?.isSuccess && educator.data?.profile ? (
        <Grid container sx={{ justifyContent: 'center' }}>
          <Grid item xs="12" sm="12" md="10" lg="10" xl="10">
            <Box>
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
                variant={window.screen.width < 600 ? `scrollable` : `standard`}
              >
                <Tab
                  id="one"
                  label="My Profile"
                  {...a11yProps(0)}
                  sx={{
                    justifyContent: 'flex-start',
                    fontSize: 14,
                    alignItems: 'flex-start',
                    textAlign: 'start',
                    textTransform: 'capitalize',
                  }}
                ></Tab>
              </Tabs>

              <EnrolledCourseTabPanel value={value} index={0}>
                <Grid container spacing={2}>
                  <Grid item xl="3" lg="3" md="12" sm="12" xs="12">
                    <Box sx={{ border: '0.5px solid', padding: '0.5rem' }}>
                      <div>
                        <div>
                          <Typography
                            component={'h1'}
                            variant="h6"
                            sx={{
                              color: '#397abb',
                              fontWeight: 600,
                              fontSize: '14px',
                              display: 'flex',
                              gap: '0.3rem',
                              alignItems: 'center',
                            }}
                          >
                            Personal Profile
                            <OpenInNew
                              onClick={() => {
                                setUpdateProfile(true);
                              }}
                              fontSize="1rem"
                              sx={{ cursor: 'pointer' }}
                            />
                          </Typography>
                          <Typography
                            sx={{
                              color: '#397abb',
                              fontWeight: 400,
                              fontSize: '12px',
                            }}
                          >
                            Registered on&nbsp;
                            {educator.data
                              ? educator.data.user
                                ? moment(educator.data.user.created_at).format(
                                    'Do MMM YY hh:mm A'
                                  )
                                : null
                              : null}
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: 'grid',
                            justifyItems: 'center',
                            marginTop: '2rem',
                          }}
                        >
                          <Tooltip
                            title={
                              educator.data.profile.profile_picture_url
                                ? 'Change Profile Picture'
                                : 'Upload Profile Picture'
                            }
                            placement="right"
                          >
                            <IconButton
                              variant="contained"
                              onClick={() => setUpdateProfilePic(true)}
                            >
                              <Badge
                                // badgeContent={<PhotoCameraOutlinedIcon />}
                                overlap="circular"
                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right',
                                }}
                              >
                                <Avatar
                                  sx={{
                                    height: '150px',
                                    width: '150px',
                                    border: '1px solid',
                                    // marginLeft: '4rem',
                                    '&:hover ': {
                                      opacity: 1,
                                    },
                                  }}
                                  alt={
                                    isSignedIn
                                      ? educator.data.profile.profile_picture_url
                                        ? null
                                        : user.name.charAt(0).toUpperCase()
                                      : null
                                  }
                                  src={
                                    educator.data.profile.profile_picture_url
                                      ? educator.data.profile.profile_picture_url
                                      : null
                                  }
                                ></Avatar>
                              </Badge>
                            </IconButton>
                          </Tooltip>
                        </div>
                        <div
                          style={{
                            display: 'grid',
                            justifyItems: 'center',
                            marginTop: '1rem',
                          }}
                        >
                          <Typography
                            component={'h1'}
                            variant="h6"
                            sx={{
                              color: '#397abb',
                              fontWeight: 600,
                              fontSize: '16px',
                            }}
                          >
                            {educator.data
                              ? educator.data.profile.first_name
                                ? educator.data.profile.first_name
                                : educator.data.user.name
                              : '#'}
                          </Typography>
                          <Typography
                            component={'h1'}
                            variant="h6"
                            sx={{
                              color: '#397abb',
                              fontWeight: 600,
                              fontSize: '16px',
                            }}
                          >
                            {educator.data
                              ? educator.data.profile.last_name
                                ? educator.data.profile.last_name
                                : null
                              : '#'}
                          </Typography>
                          {/* <Typography
                          component={'h1'}
                          variant="h6"
                          sx={{
                            color: '#397abb',
                            fontWeight: 500,
                            fontSize: '11px',
                          }}
                        >
                          CLASS 6 | CBSE | English Medium
                        </Typography> */}
                        </div>
                        <div style={{ marginTop: '1rem' }}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ width: '100%', mr: 1 }}>
                              <BorderLinearProgress
                                variant="determinate"
                                value={
                                  educator.data
                                    ? profileProgress(educator.data)
                                    : null
                                }
                                sx={{ border: '0.5px solid #02b2af' }}
                              />
                            </Box>
                            <Box sx={{ minWidth: 48 }}>
                              <Typography variant="body2" color="text.secondary">
                                {educator.data
                                  ? `${Math.round(profileProgress(educator.data))}%`
                                  : null}
                              </Typography>
                            </Box>
                          </Box>
                        </div>
                        <div style={{ marginTop: '1rem' }}>
                          <>
                            <Dialog
                              open={updateProfile}
                              onClose={() => {
                                setUpdateProfile(false);
                              }}
                            >
                              <DialogTitle> Update Profile</DialogTitle>

                              <Box
                                component="form"
                                onSubmit={handleSubmit}
                                noValidate
                              >
                                <DialogContent>
                                  <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
                                    <Grid container spacing={2}>
                                      <Grid
                                        item
                                        xs="12"
                                        sm="12"
                                        md="6"
                                        lg="6"
                                        xl="6"
                                      >
                                        <Typography
                                          sx={{
                                            fontSize: '14px',
                                            marginBottom: '0.3rem',
                                          }}
                                        >
                                          First Name
                                        </Typography>
                                        <TextField
                                          required
                                          fullWidth
                                          id="firstName"
                                          placeholder="First Name"
                                          name="firstName"
                                          autoComplete="firstName"
                                          autoFocus
                                          size="small"
                                          defaultValue={
                                            educator.data.profile.first_name
                                          }
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        xs="12"
                                        sm="12"
                                        md="6"
                                        lg="6"
                                        xl="6"
                                      >
                                        <Typography
                                          sx={{
                                            fontSize: '14px',
                                            marginBottom: '0.3rem',
                                          }}
                                        >
                                          Last Name
                                        </Typography>
                                        <TextField
                                          required
                                          fullWidth
                                          id="lastName"
                                          placeholder="Last Name"
                                          name="lastName"
                                          autoComplete="lastName"
                                          autoFocus
                                          size="small"
                                          defaultValue={
                                            educator.data.profile.last_name
                                          }
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        xs="12"
                                        sm="12"
                                        md="6"
                                        lg="6"
                                        xl="6"
                                      >
                                        <Typography
                                          sx={{
                                            fontSize: '14px',
                                            marginBottom: '0.3rem',
                                          }}
                                        >
                                          Language
                                        </Typography>
                                        <TextField
                                          required
                                          fullWidth
                                          id="Language"
                                          placeholder="Language"
                                          name="language"
                                          autoComplete="Language"
                                          autoFocus
                                          size="small"
                                          defaultValue={
                                            educator.data.profile.language
                                          }
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        xs="12"
                                        sm="12"
                                        md="6"
                                        lg="6"
                                        xl="6"
                                      >
                                        <Typography
                                          sx={{
                                            fontSize: '14px',
                                            marginBottom: '0.3rem',
                                          }}
                                        >
                                          Location
                                        </Typography>
                                        <TextField
                                          required
                                          fullWidth
                                          id="Location"
                                          placeholder="Location"
                                          name="location"
                                          autoComplete="Location"
                                          autoFocus
                                          size="small"
                                          defaultValue={
                                            educator.data.profile.location
                                          }
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        xs="12"
                                        sm="12"
                                        md="6"
                                        lg="6"
                                        xl="6"
                                      >
                                        <Typography
                                          sx={{
                                            fontSize: '14px',
                                            marginBottom: '0.3rem',
                                          }}
                                        >
                                          Gender
                                        </Typography>
                                        <FormControl fullWidth size="small">
                                          <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={gender}
                                            onChange={(e) => {
                                              setGender(e.target.value);
                                            }}
                                          >
                                            <MenuItem value={'male'}>male</MenuItem>
                                            <MenuItem value={'female'}>
                                              female
                                            </MenuItem>
                                            <MenuItem value={'other'}>
                                              other
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                      </Grid>
                                      <Grid
                                        item
                                        xs="12"
                                        sm="12"
                                        md="6"
                                        lg="6"
                                        xl="6"
                                      >
                                        <Typography
                                          sx={{
                                            fontSize: '14px',
                                            marginBottom: '0.3rem',
                                          }}
                                        >
                                          Date Of Birth
                                        </Typography>
                                        <LocalizationProvider
                                          dateAdapter={MomentUtils}
                                        >
                                          <DatePicker
                                            value={dob}
                                            onChange={(newValue) => {
                                              setDob(newValue);
                                            }}
                                            renderInput={(params) => (
                                              <TextField {...params} size="small" />
                                            )}
                                          />
                                        </LocalizationProvider>
                                      </Grid>
                                      <Grid
                                        item
                                        xs="12"
                                        sm="12"
                                        md="6"
                                        lg="6"
                                        xl="6"
                                      >
                                        <Typography
                                          sx={{
                                            fontSize: '14px',
                                            marginBottom: '0.3rem',
                                          }}
                                        >
                                          Qualification
                                        </Typography>
                                        <TextField
                                          required
                                          fullWidth
                                          id="Qualification"
                                          placeholder="Qualification"
                                          name="qualification"
                                          autoComplete="Qualification"
                                          autoFocus
                                          size="small"
                                          defaultValue={
                                            educator.data.profile.qualification
                                          }
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        xs="12"
                                        sm="12"
                                        md="6"
                                        lg="6"
                                        xl="6"
                                      >
                                        <Typography
                                          sx={{
                                            fontSize: '14px',
                                            marginBottom: '0.3rem',
                                          }}
                                        >
                                          Subject Expert / Area of Expert
                                        </Typography>
                                        <TextField
                                          required
                                          fullWidth
                                          id="subject"
                                          placeholder="Subject Expert / Area of Expert"
                                          name="subject"
                                          autoComplete="subject"
                                          autoFocus
                                          size="small"
                                          defaultValue={
                                            educator.data.profile.subject
                                          }
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        xs="12"
                                        sm="12"
                                        md="12"
                                        lg="12"
                                        xl="12"
                                      >
                                        <Typography
                                          sx={{
                                            fontSize: '14px',
                                            marginBottom: '0.3rem',
                                          }}
                                        >
                                          Address
                                        </Typography>
                                        <TextField
                                          required
                                          fullWidth
                                          id="Address"
                                          placeholder="Address"
                                          name="address"
                                          autoComplete="Address"
                                          autoFocus
                                          size="small"
                                          defaultValue={
                                            educator.data.profile.address
                                          }
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        xs="12"
                                        sm="12"
                                        md="6"
                                        lg="6"
                                        xl="6"
                                      >
                                        <Typography
                                          sx={{
                                            fontSize: '14px',
                                            marginBottom: '0.3rem',
                                          }}
                                        >
                                          Pin Code
                                        </Typography>
                                        <TextField
                                          required
                                          fullWidth
                                          id="pincode"
                                          placeholder="Pin Code"
                                          name="pincode"
                                          autoComplete="pincode"
                                          autoFocus
                                          size="small"
                                          defaultValue={
                                            educator.data.profile.pin_code
                                          }
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        xs="12"
                                        sm="12"
                                        md="6"
                                        lg="6"
                                        xl="6"
                                      >
                                        <Typography
                                          sx={{
                                            fontSize: '14px',
                                            marginBottom: '0.3rem',
                                          }}
                                        >
                                          GST No
                                        </Typography>
                                        <TextField
                                          required
                                          fullWidth
                                          id="gstno"
                                          placeholder="GST No"
                                          name="gstno"
                                          autoComplete="gstno"
                                          autoFocus
                                          size="small"
                                          defaultValue={
                                            educator.data.profile.gst_number
                                          }
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        xs="12"
                                        sm="12"
                                        md="12"
                                        lg="12"
                                        xl="12"
                                      >
                                        <Typography
                                          sx={{
                                            fontSize: '14px',
                                            marginBottom: '0.3rem',
                                          }}
                                        >
                                          Headline
                                        </Typography>
                                        <TextField
                                          required
                                          fullWidth
                                          id="Headline"
                                          placeholder="Headline"
                                          name="headline"
                                          autoComplete="Headline"
                                          autoFocus
                                          size="small"
                                          defaultValue={
                                            educator.data.profile.headline
                                          }
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        xs="12"
                                        sm="12"
                                        md="12"
                                        lg="12"
                                        xl="12"
                                      >
                                        <Typography
                                          sx={{
                                            fontSize: '14px',
                                            marginBottom: '0.3rem',
                                          }}
                                        >
                                          Bio
                                        </Typography>
                                        <TextareaAutosize
                                          required
                                          fullWidth
                                          id="Bio"
                                          placeholder="Bio"
                                          name="bio"
                                          autoComplete="Bio"
                                          autoFocus
                                          size="small"
                                          defaultValue={educator.data.profile.bio}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </DialogContent>
                                <DialogActions>
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    disabled={result.isLoading}
                                  >
                                    Update Profile
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      setUpdateProfile(false);
                                    }}
                                  >
                                    Close
                                  </Button>
                                </DialogActions>
                              </Box>
                            </Dialog>
                          </>
                        </div>
                        <div
                          style={{
                            marginTop: '1rem',
                            display: 'grid',
                            gap: '0.5rem',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              background: '#d8d8d8',
                              padding: '0.5rem',
                              height: '20px',
                              alignItems: 'center',
                              borderRadius: '10px',
                            }}
                          >
                            <h6 style={{ fontSize: '13px', fontWeight: '600' }}>
                              Teacher ID
                            </h6>
                            <h6 style={{ fontSize: '13px', fontWeight: '600' }}>
                              {educator.data
                                ? educator.data.profile.id
                                  ? educator.data.profile.id
                                  : null
                                : '#'}
                            </h6>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              background: '#d8d8d8',
                              padding: '0.5rem',
                              height: '20px',
                              alignItems: 'center',
                              borderRadius: '10px',
                            }}
                          >
                            <h6 style={{ fontSize: '13px', fontWeight: '600' }}>
                              Gender
                            </h6>
                            <h6 style={{ fontSize: '13px', fontWeight: '600' }}>
                              {educator.data
                                ? educator.data.profile.gender
                                  ? educator.data.profile.gender
                                  : null
                                : '#'}
                            </h6>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              background: '#d8d8d8',
                              padding: '0.5rem',
                              height: '20px',
                              alignItems: 'center',
                              borderRadius: '10px',
                            }}
                          >
                            <h6 style={{ fontSize: '13px', fontWeight: '600' }}>
                              DOB
                            </h6>
                            <h6 style={{ fontSize: '13px', fontWeight: '600' }}>
                              {educator.data
                                ? educator.data.profile.date_of_birth
                                  ? educator.data.profile.date_of_birth
                                  : null
                                : '#'}
                            </h6>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              background: '#d8d8d8',
                              padding: '0.5rem',
                              height: '20px',
                              alignItems: 'center',
                              borderRadius: '10px',
                            }}
                          >
                            <h6 style={{ fontSize: '13px', fontWeight: '600' }}>
                              Qualification
                            </h6>
                            <h6 style={{ fontSize: '13px', fontWeight: '600' }}>
                              {educator.data
                                ? educator.data.profile.qualification
                                  ? educator.data.profile.qualification
                                  : null
                                : '#'}
                            </h6>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              background: '#d8d8d8',
                              padding: '0.5rem',
                              height: '20px',
                              alignItems: 'center',
                              borderRadius: '10px',
                            }}
                          >
                            <h6 style={{ fontSize: '13px', fontWeight: '600' }}>
                              Location
                            </h6>
                            <h6 style={{ fontSize: '13px', fontWeight: '600' }}>
                              {educator.data
                                ? educator.data.profile.location
                                  ? educator.data.profile.location
                                  : null
                                : '#'}
                            </h6>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              background: '#d8d8d8',
                              padding: '0.5rem',
                              height: '20px',
                              alignItems: 'center',
                              borderRadius: '10px',
                            }}
                          >
                            <h6 style={{ fontSize: '13px', fontWeight: '600' }}>
                              Mobile
                            </h6>
                            <h6 style={{ fontSize: '13px', fontWeight: '600' }}>
                              {educator.data
                                ? educator.data.user.phone_number
                                  ? educator.data.user.phone_number
                                  : null
                                : '#'}
                            </h6>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              background: '#d8d8d8',
                              padding: '0.5rem',
                              height: '20px',
                              alignItems: 'center',
                              borderRadius: '10px',
                            }}
                          >
                            <h6 style={{ fontSize: '13px', fontWeight: '600' }}>
                              Email
                            </h6>
                            <h6 style={{ fontSize: '13px', fontWeight: '600' }}>
                              {educator.data
                                ? educator.data.user.email
                                  ? educator.data.user.email
                                  : null
                                : '#'}
                            </h6>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              background: '#d8d8d8',
                              padding: '0.5rem',
                              height: '20px',
                              alignItems: 'center',
                              borderRadius: '10px',
                            }}
                          >
                            <h6 style={{ fontSize: '13px', fontWeight: '600' }}>
                              Address
                            </h6>
                            <h6 style={{ fontSize: '13px', fontWeight: '600' }}>
                              {educator.data
                                ? educator.data.profile.address
                                  ? educator.data.profile.address
                                  : null
                                : '#'}
                            </h6>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              background: '#d8d8d8',
                              padding: '0.5rem',
                              height: '20px',
                              alignItems: 'center',
                              borderRadius: '10px',
                            }}
                          >
                            <h6 style={{ fontSize: '13px', fontWeight: '600' }}>
                              Pin Code
                            </h6>
                            <h6 style={{ fontSize: '13px', fontWeight: '600' }}>
                              {educator.data
                                ? educator.data.profile.pin_code
                                  ? educator.data.profile.pin_code
                                  : null
                                : '#'}
                            </h6>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              background: '#d8d8d8',
                              padding: '0.5rem',
                              height: '20px',
                              alignItems: 'center',
                              borderRadius: '10px',
                            }}
                          >
                            <h6 style={{ fontSize: '13px', fontWeight: '600' }}>
                              GST No
                            </h6>
                            <h6 style={{ fontSize: '13px', fontWeight: '600' }}>
                              {educator.data
                                ? educator.data.profile.gst_number
                                  ? educator.data.profile.gst_number
                                  : null
                                : '#'}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xl="6" lg="6" md="12" sm="12" xs="12 ">
                    <Box sx={{ border: '0.5px solid', padding: '0.5rem' }}>
                      <Typography
                        sx={{
                          color: '#397abb',
                          fontWeight: 600,
                          fontSize: '18px',
                          display: 'flex',
                          gap: '0.3rem',
                          alignItems: 'center',
                        }}
                      >
                        Short Bio
                        <OpenInNew
                          onClick={() => {
                            setUpdateProfileMain(true);
                          }}
                          fontSize="1rem"
                          sx={{ cursor: 'pointer' }}
                        />
                      </Typography>

                      <Typography
                        sx={{
                          color: '#397abb',
                          fontWeight: 500,
                          fontSize: '13px',
                        }}
                      >
                        {educator.data.profile.bio}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        border: '0.5px solid',
                        padding: '0.5rem',
                        marginTop: '1rem',
                      }}
                    >
                      <Typography
                        sx={{
                          color: '#397abb',
                          fontWeight: 600,
                          fontSize: '18px',
                          display: 'flex',
                          gap: '0.3rem',
                          alignItems: 'center',
                        }}
                      >
                        Preferred Languages of Teaching
                        <OpenInNew
                          onClick={() => {
                            setUpdateProfileMain(true);
                          }}
                          fontSize="1rem"
                          sx={{ cursor: 'pointer' }}
                        />
                      </Typography>
                      <Box>
                        <Grid container sx={{ padding: '0.5rem 1rem 0rem 1rem' }}>
                          {educator.data.profile.preferred_languages
                            ? educator.data.profile.preferred_languages.map(
                                (singleLanguage) => (
                                  <Grid
                                    item
                                    xl="2"
                                    lg="2"
                                    md="3"
                                    sm="4"
                                    xs="4"
                                    key={singleLanguage}
                                  >
                                    <Typography
                                      sx={{
                                        color: '#397abb',
                                        fontWeight: 500,
                                        fontSize: '13px',
                                      }}
                                    >
                                      {singleLanguage}
                                    </Typography>
                                  </Grid>
                                )
                              )
                            : null}
                        </Grid>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        border: '0.5px solid',
                        padding: '0.5rem',
                        marginTop: '1rem',
                      }}
                    >
                      <Typography
                        sx={{
                          color: '#397abb',
                          fontWeight: 600,
                          fontSize: '18px',
                          display: 'flex',
                          gap: '0.3rem',
                          alignItems: 'center',
                        }}
                      >
                        Choose your Profession
                        <OpenInNew
                          onClick={() => {
                            setUpdateProfileMain(true);
                          }}
                          fontSize="1rem"
                          sx={{ cursor: 'pointer' }}
                        />
                      </Typography>
                      <Box>
                        <Grid container sx={{ padding: '0.5rem 1rem 0rem 1rem' }}>
                          {educator.data.profile.teaching_interests
                            ? educator.data.profile.teaching_interests.map(
                                (singleInterest) => (
                                  <Grid
                                    item
                                    xl="2"
                                    lg="2"
                                    md="3"
                                    sm="4"
                                    xs="4"
                                    key={singleInterest}
                                  >
                                    <Typography
                                      sx={{
                                        color: '#397abb',
                                        fontWeight: 500,
                                        fontSize: '13px',
                                      }}
                                    >
                                      {singleInterest}
                                    </Typography>
                                  </Grid>
                                )
                              )
                            : null}
                        </Grid>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        border: '0.5px solid',
                        padding: '0.5rem',
                        marginTop: '1rem',
                      }}
                    >
                      <Typography
                        sx={{
                          color: '#397abb',
                          fontWeight: 600,
                          fontSize: '18px',
                          display: 'flex',
                          gap: '0.3rem',
                          alignItems: 'center',
                        }}
                      >
                        Update Bank Details
                        <OpenInNew fontSize="1rem" sx={{ cursor: 'pointer' }} />
                      </Typography>
                      <Box>
                        <Grid
                          container
                          spacing={1}
                          sx={{ padding: '0.5rem 1rem 0rem 1rem' }}
                        >
                          <Grid item xl="6" lg="6" md="6" sm="12" xs="12">
                            <TextField
                              required
                              fullWidth
                              id="Account Name"
                              placeholder="Account Name"
                              label="Account Name"
                              name="Account Name"
                              autoComplete="Account Name"
                              autoFocus
                              size="small"
                            />
                          </Grid>
                          <Grid item xl="6" lg="6" md="6" sm="12" xs="12">
                            <TextField
                              required
                              fullWidth
                              id="Bank Name"
                              placeholder="Bank Name"
                              label="Bank Name"
                              name="Bank Name"
                              autoComplete="Bank Name"
                              autoFocus
                              size="small"
                            />
                          </Grid>
                          <Grid item xl="6" lg="6" md="6" sm="12" xs="12">
                            <TextField
                              required
                              fullWidth
                              id="Account No"
                              placeholder="Account No"
                              label="Account No"
                              name="Account No"
                              autoComplete="Account No"
                              autoFocus
                              size="small"
                            />
                          </Grid>
                          <Grid item xl="6" lg="6" md="6" sm="12" xs="12">
                            <TextField
                              required
                              fullWidth
                              id="Account Type"
                              placeholder="Account Type"
                              label="Account Type"
                              name="Account Type"
                              autoComplete="Account Type"
                              autoFocus
                              size="small"
                            />
                          </Grid>
                          <Grid item xl="6" lg="6" md="6" sm="12" xs="12">
                            <TextField
                              required
                              fullWidth
                              id="IFSC Code"
                              placeholder="IFSC Code"
                              label="IFSC Code"
                              name="IFSC Code"
                              autoComplete="IFSC Code"
                              autoFocus
                              size="small"
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xl="3" lg="3" md="12" sm="12" xs="12">
                    <Box
                      sx={{
                        border: '0.5px solid',
                        padding: '0.5rem 1rem 2rem 1rem',
                      }}
                    >
                      <Typography
                        sx={{
                          color: '#397abb',
                          fontWeight: 500,
                          fontSize: '20px',
                          textAlign: 'center',
                        }}
                      >
                        Change Password
                      </Typography>
                      <Box sx={{ textAlign: 'center' }}>
                        <TextField
                          id="filled-textarea"
                          label="New Password"
                          placeholder="New Password"
                          variant="outlined"
                          size="small"
                          sx={{ background: '#8080804d', marginTop: '0.5rem' }}
                        />
                        <TextField
                          id="filled-textarea"
                          label="Confirm Password"
                          placeholder="Confirm Password"
                          variant="outlined"
                          size="small"
                          sx={{ background: '#8080804d', marginTop: '0.5rem' }}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        border: '0.5px solid',
                        padding: '0.5rem',
                        marginTop: '1rem',
                      }}
                    >
                      <Typography
                        sx={{
                          color: '#397abb',
                          fontWeight: 500,
                          fontSize: '20px',
                          textAlign: 'center',
                        }}
                      >
                        Saved Payment Method
                      </Typography>
                      <Box sx={{ textAlign: 'center' }}>
                        <Typography
                          sx={{
                            color: '#397abb',
                            fontWeight: 600,
                            fontSize: '16px',
                            textAlign: 'center',
                            marginBottom: '1rem',
                          }}
                        >
                          Recent Transction
                        </Typography>
                        <div style={{ display: 'grid', gap: '0.5rem' }}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Typography
                              sx={{
                                color: '#397abb',
                                fontWeight: 400,
                                fontSize: '11px',
                              }}
                            >
                              10-March-2023
                            </Typography>
                            <Typography
                              sx={{
                                color: '#397abb',
                                fontWeight: 400,
                                fontSize: '11px',
                              }}
                            >
                              Debit Card
                            </Typography>
                            <Typography
                              sx={{
                                color: '#397abb',
                                fontWeight: 400,
                                fontSize: '11px',
                              }}
                            >
                              TX1023312
                            </Typography>
                            <Typography
                              sx={{
                                color: '#397abb',
                                fontWeight: 400,
                                fontSize: '11px',
                              }}
                            >
                              RS 199.00
                            </Typography>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Typography
                              sx={{
                                color: '#397abb',
                                fontWeight: 400,
                                fontSize: '11px',
                              }}
                            >
                              10-March-2023
                            </Typography>
                            <Typography
                              sx={{
                                color: '#397abb',
                                fontWeight: 400,
                                fontSize: '11px',
                              }}
                            >
                              Debit Card
                            </Typography>
                            <Typography
                              sx={{
                                color: '#397abb',
                                fontWeight: 400,
                                fontSize: '11px',
                              }}
                            >
                              TX1023312
                            </Typography>
                            <Typography
                              sx={{
                                color: '#397abb',
                                fontWeight: 400,
                                fontSize: '11px',
                              }}
                            >
                              RS 199.00
                            </Typography>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Typography
                              sx={{
                                color: '#397abb',
                                fontWeight: 400,
                                fontSize: '11px',
                              }}
                            >
                              10-March-2023
                            </Typography>
                            <Typography
                              sx={{
                                color: '#397abb',
                                fontWeight: 400,
                                fontSize: '11px',
                              }}
                            >
                              Debit Card
                            </Typography>
                            <Typography
                              sx={{
                                color: '#397abb',
                                fontWeight: 400,
                                fontSize: '11px',
                              }}
                            >
                              TX1023312
                            </Typography>
                            <Typography
                              sx={{
                                color: '#397abb',
                                fontWeight: 400,
                                fontSize: '11px',
                              }}
                            >
                              RS 199.00
                            </Typography>
                          </div>
                        </div>
                      </Box>
                    </Box>
                  </Grid>
                  <>
                    <Dialog
                      open={updateProfileMain}
                      onClose={() => {
                        setUpdateProfileMain(false);
                      }}
                      fullWidth
                      maxWidth="md"
                    >
                      <DialogTitle> Update Profile</DialogTitle>
                      <DialogContent>
                        <UpdateEducatorProfile
                          teacherId={educator.data.profile.id}
                          profile={educator.data.profile}
                        />
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => {
                            setUpdateProfileMain(false);
                          }}
                        >
                          Close
                        </Button>
                      </DialogActions>
                    </Dialog>
                    <Dialog
                      open={updateProfilePic}
                      onClose={() => {
                        setUpdateProfilePic(false);
                      }}
                    >
                      <DialogTitle>
                        {educator.data.profile.profile_picture_url
                          ? 'Change Profile Picture'
                          : 'Upload Profile Picture'}
                      </DialogTitle>
                      <DialogContent>
                        <UploadEducatorProfilePicture
                          imgUrl={educator.data.profile.profile_picture_url}
                          educatorId={educator.data.profile.id}
                        />
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => {
                            setUpdateProfilePic(false);
                          }}
                        >
                          Close
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </>
                </Grid>
                {/* <Grid container spacing={2}>
                  <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
                    <Box
                      sx={{
                        width: '100%',
                        border: '2px solid',
                        borderRadius: '20px',
                        height: '200px',
                        background: '#bac8d3',
                      }}
                    ></Box>
                    <Tooltip
                      title={
                        educator.data.profile.profile_picture_url
                          ? 'Change Profile Picture'
                          : 'Upload Profile Picture'
                      }
                      placement="right"
                    >
                      <IconButton variant="contained" onClick={handleClickOpen}>
                        <Badge
                          badgeContent={<PhotoCameraOutlinedIcon />}
                          overlap="circular"
                          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        >
                          <Avatar
                            sx={{
                              height: '150px',
                              width: '150px',
                              marginTop: '-5rem',
                              border: '1px solid',
                              marginLeft: '4rem',
                              '&:hover ': {
                                opacity: 1,
                              },
                            }}
                            alt={
                              isSignedIn
                                ? educator.data.profile.profile_picture_url
                                  ? null
                                  : user.name.charAt(0).toUpperCase()
                                : null
                            }
                            src={
                              educator.data.profile.profile_picture_url
                                ? educator.data.profile.profile_picture_url
                                : null
                            }
                          ></Avatar>
                        </Badge>
                      </IconButton>
                    </Tooltip>

                    <Dialog open={open} onClose={handleClose}>
                      <DialogTitle>
                        {educator.data.profile.profile_picture_url
                          ? 'Change Profile Picture'
                          : 'Upload Profile Picture'}
                      </DialogTitle>
                      <DialogContent>
                        <UploadEducatorProfilePicture
                          imgUrl={educator.data.profile.profile_picture_url}
                          educatorId={educator.data.profile.id}
                        />
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                      </DialogActions>
                    </Dialog>
                  </Grid>
                  <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
                    <Box component="form" onSubmit={handleSubmit} noValidate>
                      <Grid container spacing={2}>
                        <Grid item xs="12" sm="12" md="6" lg="6" xl="6">
                          <Typography
                            sx={{ fontSize: '14px', marginBottom: '0.3rem' }}
                          >
                            First Name
                          </Typography>
                          <TextField
                            required
                            fullWidth
                            id="firstName"
                            placeholder="First Name"
                            name="firstName"
                            autoComplete="firstName"
                            autoFocus
                            size="small"
                            defaultValue={educator.data.profile.first_name}
                          />
                        </Grid>
                        <Grid item xs="12" sm="12" md="6" lg="6" xl="6">
                          <Typography
                            sx={{ fontSize: '14px', marginBottom: '0.3rem' }}
                          >
                            Last Name
                          </Typography>
                          <TextField
                            required
                            fullWidth
                            id="lastName"
                            placeholder="Last Name"
                            name="lastName"
                            autoComplete="lastName"
                            autoFocus
                            size="small"
                            defaultValue={educator.data.profile.last_name}
                          />
                        </Grid>
                        <Grid item xs="12" sm="12" md="6" lg="6" xl="6">
                          <Typography
                            sx={{ fontSize: '14px', marginBottom: '0.3rem' }}
                          >
                            Language
                          </Typography>
                          <TextField
                            required
                            fullWidth
                            id="Language"
                            placeholder="Language"
                            name="language"
                            autoComplete="Language"
                            autoFocus
                            size="small"
                            defaultValue={educator.data.profile.language}
                          />
                        </Grid>
                        <Grid item xs="12" sm="12" md="6" lg="6" xl="6">
                          <Typography
                            sx={{ fontSize: '14px', marginBottom: '0.3rem' }}
                          >
                            Location
                          </Typography>
                          <TextField
                            required
                            fullWidth
                            id="Location"
                            placeholder="Location"
                            name="location"
                            autoComplete="Location"
                            autoFocus
                            size="small"
                            defaultValue={educator.data.profile.location}
                          />
                        </Grid>
                        <Grid item xs="12" sm="12" md="6" lg="6" xl="6">
                          <Typography
                            sx={{ fontSize: '14px', marginBottom: '0.3rem' }}
                          >
                            Qualification
                          </Typography>
                          <TextField
                            required
                            fullWidth
                            id="Qualification"
                            placeholder="Qualification"
                            name="qualification"
                            autoComplete="Qualification"
                            autoFocus
                            size="small"
                            defaultValue={educator.data.profile.qualification}
                          />
                        </Grid>
                        <Grid item xs="12" sm="12" md="6" lg="6" xl="6">
                          <Typography
                            sx={{ fontSize: '14px', marginBottom: '0.3rem' }}
                          >
                            Subject Expert / Area of Expert
                          </Typography>
                          <TextField
                            required
                            fullWidth
                            id="subject"
                            placeholder="Subject Expert / Area of Expert"
                            name="subject"
                            autoComplete="subject"
                            autoFocus
                            size="small"
                            defaultValue={educator.data.profile.subject}
                          />
                        </Grid>
                        <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
                          <Typography
                            sx={{ fontSize: '14px', marginBottom: '0.3rem' }}
                          >
                            Headline
                          </Typography>
                          <TextField
                            required
                            fullWidth
                            id="Headline"
                            placeholder="Headline"
                            name="headline"
                            autoComplete="Headline"
                            autoFocus
                            size="small"
                            defaultValue={educator.data.profile.headline}
                          />
                        </Grid>
                        <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
                          <Typography
                            sx={{ fontSize: '14px', marginBottom: '0.3rem' }}
                          >
                            Bio
                          </Typography>
                          <TextareaAutosize
                            required
                            fullWidth
                            id="Bio"
                            placeholder="Bio"
                            name="bio"
                            autoComplete="Bio"
                            autoFocus
                            size="small"
                            defaultValue={educator.data.profile.bio}
                          />
                        </Grid>
                      </Grid>
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={result.isLoading}
                      >
                        Update Profile
                      </Button>
                    </Box>
                  </Grid>
                </Grid> */}
              </EnrolledCourseTabPanel>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Typography>No Educator Profile</Typography>
      )}
    </>
  );
};
