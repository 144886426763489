/* eslint-disable no-unused-vars */
import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  Grid,
  Box,
} from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useSubTopicQuery } from '../../../../../../app/services/CoursesApi';
import UpdateSubTopic from './UpdateSubTopic';
import UploadSubTopicVideo from './UploadSubTopicVideo';
import Slide from '@mui/material/Slide';

import DisplaySubTopicVideos from './DisplaySubTopicVideos';
import DataLoader from '../../../../../../components/DataLoader';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function SingleSubTopic() {
  const params = useParams();

  const { data, isLoading, isSuccess } = useSubTopicQuery({
    id: parseInt(params.subTopicId, 10),
  });

  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  return (
    <>
      {isSuccess && data.subTopic ? (
        <>
          <Grid container spacing={1}>
            <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
              <Box sx={{ textAlign: 'center' }}>
                <Button variant="contained" onClick={handleClickOpen1}>
                  Update Sub Topic
                </Button>
                <>
                  <Dialog open={open1} onClose={handleClose1}>
                    <DialogTitle> Update Sub Topic</DialogTitle>
                    <DialogContent>
                      <UpdateSubTopic subTopic={data.subTopic} />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose1}>Close</Button>
                    </DialogActions>
                  </Dialog>
                </>
                &nbsp;
                <Button variant="contained" onClick={handleClickOpen}>
                  Upload Sub Topic Video
                </Button>
                <>
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    fullWidth
                    maxWidth="sm"
                    TransitionComponent={Transition}
                    fullScreen
                  >
                    <DialogTitle>Upload Sub Topic Video</DialogTitle>
                    <DialogContent>
                      <UploadSubTopicVideo subTopicId={data.subTopic.id} />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose}>Close</Button>
                    </DialogActions>
                  </Dialog>
                </>
              </Box>
            </Grid>
            <Grid item xs="12" sm="12" md="8" lg="8" xl="8" align="left">
              <Card>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  sx={{ padding: 4, color: '#233c8d' }}
                >
                  Title: {data.subTopic.title}
                  <br />
                  Description: {data.subTopic.description}
                </Typography>
              </Card>
            </Grid>
            <Grid item xs="12" sm="12" md="4" lg="4" xl="4">
              {data.subTopic.videos && data.subTopic.videos.length >= 1 ? (
                <Card>
                  <Box mt={2} textAlign="center">
                    <Typography variant="subtitle1" color="text.secondary">
                      Sub Topic Videos
                    </Typography>
                    <DisplaySubTopicVideos videos={data.subTopic.videos} />
                  </Box>
                </Card>
              ) : (
                <Typography variant="subtitle1" color="text.secondary">
                  No Course Videos Found
                </Typography>
              )}
            </Grid>
          </Grid>
        </>
      ) : (
        <p>No topic found</p>
      )}
    </>
  );
}

export default SingleSubTopic;
