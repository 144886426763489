import { Button, Typography, Box } from '@mui/material';
import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { useSubCategoryQuery } from '../../../app/services/CategoriesApi';
import DataLoader from '../../../components/DataLoader';

function SingleSubCategory() {
  const params = useParams();
  const navigate = useNavigate();

  const { data, isLoading, isSuccess } = useSubCategoryQuery({
    id: parseInt(params.subcategoryId, 10),
  });

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }
  const manageCourses = () => {
    navigate('/courses');
  };
  const manageExams = () => {
    navigate('/exams');
  };
  const manageTests = () => {
    navigate('/tests');
  };
  return (
    <>
      {isSuccess && data.subCategory ? (
        <>
          <Box style={{ textAlign: 'center' }}>
            <Button variant="contained" onClick={manageCourses}>
              Manage Courses
            </Button>
            &nbsp;
            <Button variant="contained" onClick={manageExams}>
              Manage Exams
            </Button>
            &nbsp;
            <Button variant="contained" onClick={manageTests}>
              Manage Tests
            </Button>
          </Box>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{ padding: 4, color: '#233c8d' }}
          >
            Title: {data.subCategory.title}
            <br />
            Status: {data.subCategory.status}
          </Typography>
        </>
      ) : (
        <p>No Sub Category found</p>
      )}
    </>
  );
}

export default SingleSubCategory;
