/* eslint-disable no-unused-vars */
import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  Grid,
  Box,
} from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useTopicQuery } from '../../../../../app/services/CoursesApi';
import CreateNewSubTopic from './subTopics/CreateNewSubTopic';
import SubTopicList from './subTopics/SubTopicList';
import UpdateTopic from './UpdateTopic';
import Slide from '@mui/material/Slide';
import UploadTopicVideo from './UploadTopicVideo';
import DisplayTopicVideos from './DisplayTopicVideos';
import DataLoader from '../../../../../components/DataLoader';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function SingleTopic() {
  const params = useParams();

  const { data, isLoading, isSuccess } = useTopicQuery({
    id: parseInt(params.topicId, 10),
  });

  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  return (
    <>
      {isSuccess && data.topic ? (
        <>
          <Grid container spacing={1}>
            <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
              <Box sx={{ textAlign: 'center' }}>
                <Button variant="contained" onClick={handleClickOpen1}>
                  Update Topic
                </Button>
                <>
                  <Dialog open={open1} onClose={handleClose1}>
                    <DialogTitle> Update Topic</DialogTitle>
                    <DialogContent>
                      <UpdateTopic topic={data.topic} />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose1}>Close</Button>
                    </DialogActions>
                  </Dialog>
                </>
                &nbsp;
                <Button variant="contained" onClick={handleClickOpen2}>
                  Upload Topic Video
                </Button>
                <>
                  <Dialog
                    open={open2}
                    onClose={handleClose2}
                    fullWidth
                    maxWidth="sm"
                    TransitionComponent={Transition}
                    fullScreen
                  >
                    <DialogTitle>Upload Topic Video</DialogTitle>
                    <DialogContent>
                      <UploadTopicVideo topicId={data.topic.id} />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose2}>Close</Button>
                    </DialogActions>
                  </Dialog>
                </>
                &nbsp;
                <Button variant="contained" onClick={handleClickOpen}>
                  Create New Sub Topic
                </Button>
                <>
                  <Dialog open={open} onClose={handleClose}>
                    <DialogTitle> Create New Sub Topic</DialogTitle>
                    <DialogContent>
                      <CreateNewSubTopic topicId={data.topic.id} />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose}>Close</Button>
                    </DialogActions>
                  </Dialog>
                </>
              </Box>
            </Grid>
            <Grid item xs="12" sm="12" md="8" lg="8" xl="8" align="left">
              <Card>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  sx={{ padding: 4, color: '#233c8d' }}
                >
                  Title: {data.topic.title}
                  <br />
                  Description: {data.topic.description}
                </Typography>
              </Card>
            </Grid>
            <Grid item xs="12" sm="12" md="4" lg="4" xl="4" align="left">
              {data.topic.videos && data.topic.videos.length >= 1 ? (
                <Card>
                  <Box mt={2} textAlign="center">
                    <Typography variant="subtitle1" color="text.secondary">
                      Topic Videos
                    </Typography>
                    <DisplayTopicVideos videos={data.topic.videos} />
                  </Box>
                </Card>
              ) : (
                <Typography variant="subtitle1" color="text.secondary">
                  No Topic Videos Found
                </Typography>
              )}
              {data.topic.sub_topics && data.topic.sub_topics.length >= 1 ? (
                <SubTopicList subTopics={data.topic.sub_topics} />
              ) : (
                <p>No Sub Topics Found</p>
              )}
            </Grid>
          </Grid>
        </>
      ) : (
        <p>No topic found</p>
      )}
    </>
  );
}

export default SingleTopic;
