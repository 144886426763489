/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BaseApi } from './BaseApi';

const API_URL = `${process.env.REACT_APP_BACKEND_API_PATH}/api`;
const LOCALSTORAGE_APP_KEY = `${process.env.REACT_APP_LOCALSTORAGE_KEY}`;

export const studentApi = BaseApi.injectEndpoints({
  reducerPath: 'studentApi',
  endpoints: (builder) => ({
    studentProfile: builder.query({
      query: () => ({
        url: '/student/profile',
        method: 'GET',
      }),
      providesTags: ['Profile'],
    }),
    updateStudentProfile: builder.mutation({
      query: (details) => ({
        url: `/student/profile/update/${details.id}`,
        method: 'POST',

        body: {
          first_name: details.firstName,
          last_name: details.lastName,
          gender: details.gender,
          school: details.school,
          location: details.location,
          address: details.address,
          date_of_birth: details.dateOfBirth,
        },
      }),
      invalidatesTags: ['Profile'],
    }),
    updateLearningProfile: builder.mutation({
      query: (details) => ({
        url: '/student/learning/objective/create',
        method: 'POST',

        body: {
          student_id: details.student_id,
          learning_objective_id: details.learning_objective_id,
          preferred_languages: details.preferred_languages,
          learning_interests: details.learning_interests,
          hobbies: details.hobbies,
        },
      }),
      invalidatesTags: ['Profile'],
    }),
  }),
});

export const {
  useStudentProfileQuery,
  useUpdateStudentProfileMutation,
  useUpdateLearningProfileMutation,
} = studentApi;
