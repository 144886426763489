import { Paper, Rating, Typography } from '@mui/material';

function DisplayCourseReview(props) {
  const { courseReview } = props;
  return (
    <>
      <Paper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
        }}
      >
        <Typography>Your Review</Typography>
        <Rating
          name="read-only"
          value={courseReview.rating}
          readOnly
          precision={0.5}
        />
        <Typography>{courseReview.review_text}</Typography>
      </Paper>{' '}
    </>
  );
}
export default DisplayCourseReview;
