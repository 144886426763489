import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import React from 'react';
import { useLearningObjectivesQuery } from '../../../../app/services/LearningObjectivesApi';
import DataLoader from '../../../../components/DataLoader';
import CreateNewLearningObjective from './CreateNewLearningObjective';
import LearningObjectiveList from './LearningObjectiveList';

function LearningObjectivesManager() {
  const { data, isLoading, isSuccess } = useLearningObjectivesQuery();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
          <div style={{ textAlign: 'center' }}>
            <Button variant="contained" onClick={handleClickOpen}>
              Create New Learning Objective
            </Button>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle> Create New Learning Objective</DialogTitle>
              <DialogContent>
                <CreateNewLearningObjective />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Close</Button>
              </DialogActions>
            </Dialog>
          </div>
        </Grid>
        <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
          {isSuccess && data.learningObjectives.length ? (
            <LearningObjectiveList learningObjectives={data.learningObjectives} />
          ) : (
            <p>No Learning Objectives found</p>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default LearningObjectivesManager;
