/* eslint-disable no-unused-vars */
import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useNavigate, useParams } from 'react-router';
import { useEnroledExamQuery } from '../../../app/services/StudentExamsApi';
import moment from 'moment';
import ExamWithQuestions from './ExamWithQuestions';
import QuestionAndAnswersList from './QuestionAndAnswersList';
import ExamReview from './ExamReview';
import DisplayExamReview from './DisplayExamReview';
import DataLoader from '../../../components/DataLoader';

function EnroledExam() {
  const params = useParams();
  const navigate = useNavigate();
  const [started, setStarted] = React.useState(false);
  //   const { user, isSignedIn } = useSelector((state) => state.auth);
  const { data, isLoading, isSuccess } = useEnroledExamQuery({
    id: parseInt(params.enrolId, 10),
  });
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onStartClick = () => {
    setStarted(true);
  };

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  return isSuccess && data.enrol ? (
    <Box>
      <Grid container align="left">
        {started ? (
          <ExamWithQuestions questions={data.exam.questions} examId={data.exam.id} />
        ) : (
          <>
            {typeof data.exam.examResult === 'object' &&
            data.exam.examResult !== null &&
            data.exam.examResult.top_ten_ranks ? (
              <>
                <Grid item md={2}></Grid>
                <Grid item md={8}>
                  <Card>
                    <CardHeader
                      title={data.exam.title}
                      subheader={
                        moment().isSameOrAfter(data.exam.end_date)
                          ? null
                          : `Your rank may change as others are still writing the exam`
                      }
                    />
                    <CardContent>
                      <Typography>
                        Rank:&nbsp;
                        {data.exam.examResult.userRank}
                      </Typography>
                      <Typography>
                        Total Marks:&nbsp;
                        {data.exam.examResult.result.total_marks}
                      </Typography>
                      <div style={{ textAlign: 'center' }}>
                        {data.exam.examReview ? (
                          <DisplayExamReview examReview={data.exam.examReview} />
                        ) : (
                          <>
                            <Button variant="contained" onClick={handleClickOpen}>
                              Submit Exam Review
                            </Button>
                            <Dialog open={open} onClose={handleClose}>
                              <DialogTitle> Exam Review</DialogTitle>
                              <DialogContent>
                                <ExamReview exam_id={data.exam.id} />
                              </DialogContent>
                              <DialogActions>
                                <Button onClick={handleClose}>Close</Button>
                              </DialogActions>
                            </Dialog>
                          </>
                        )}
                      </div>
                    </CardContent>
                    {data.exam.examResult.top_ten_ranks.length ? (
                      <>
                        {/* <Typography>Top Ten Ranks</Typography> */}
                        {/* 
                      <Table
                        columns={columns}
                        GridKey="id"
                        dataSource={data.exam.examResult.top_ten_ranks}
                        loading={data.exam.examResult.top_ten_ranks.length}
                        scroll={{ x: 360 }}
                        pagination={false}
                      /> */}
                      </>
                    ) : null}
                  </Card>
                  {data.exam.examResult.result.answers ? (
                    <QuestionAndAnswersList
                      questions={data.exam.questions}
                      answers={data.exam.examResult.result.answers}
                    />
                  ) : null}
                </Grid>
                <Grid item md={2}></Grid>
              </>
            ) : (
              <>
                <Grid item md={2}></Grid>
                <Grid item md={8}>
                  <Card>
                    <CardHeader
                      title={data.exam.title}
                      subheader={
                        moment().isSameOrAfter(data.enrol.exam.end_date)
                          ? `Too late! This Exam has ended.`
                          : `
                    You will get the questions when the exam starts. Do not miss on
                    timings. Good Luck.
                  `
                      }
                    ></CardHeader>
                    {moment().isSameOrAfter(data.enrol.exam.start_date) &&
                    moment().isSameOrBefore(data.enrol.exam.end_date) &&
                    data.exam.questions ? (
                      <>
                        <Alert severity="info">
                          <>
                            <AlertTitle>Exam is Live. </AlertTitle>
                            <Button
                              variant="contained"
                              color="secondary"
                              size="small"
                              onClick={onStartClick}
                            >
                              Click here to start your Exam
                            </Button>
                          </>
                        </Alert>
                      </>
                    ) : (
                      <>
                        <CardMedia
                          component="img"
                          height="194"
                          image={
                            data.enrol.exam.image_url ||
                            `https://images.unsplash.com/photo-1516397281156-ca07cf9746fc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80`
                          }
                          alt="Paella dish"
                        />
                      </>
                    )}
                    <CardContent
                      sx={{ alignItems: 'center', overflowWrap: 'anywhere' }}
                    >
                      {/* <Typography variant="subtitle1">
                        Starts from&nbsp;
                        {moment(data.enrol.exam.start_date).format(
                          'Do MMM YY hh:mm A'
                        )}
                      </Typography>
                      <Typography variant="subtitle1">
                        Ends on&nbsp;
                        {moment(data.enrol.exam.end_date).format(
                          'Do MMM YY hh:mm A'
                        )}
                      </Typography> */}
                      <Typography variant="subtitle1">
                        Duration:&nbsp;
                        {moment(data.enrol.exam.end_date).diff(
                          moment(data.enrol.exam.start_date),
                          'minutes'
                        )}
                        &nbsp; minutes
                      </Typography>
                      <Typography variant="subtitle1">
                        In&nbsp;
                        {data.enrol.exam.language}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item md={2}></Grid>
                <Grid item xs="12" sm="12" md="2" lg="2" xl="2"></Grid>
                <Grid item xs="12" sm="12" md="8" lg="8" xl="8">
                  <Box
                    sx={{
                      position: 'relative',
                      p: { xs: 2, md: 2 },
                      pr: { md: 0 },
                      overflowWrap: 'anywhere',
                    }}
                  >
                    <Typography component="h2" variant="h5">
                      <strong>Exam Description</strong>
                    </Typography>
                    <Typography variant="subtitle1" paragraph>
                      {data.enrol.exam.description}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs="12" sm="12" md="2" lg="2" xl="2"></Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </Box>
  ) : (
    <Typography>Enroled Exam Not Found</Typography>
  );
}

export default EnroledExam;
