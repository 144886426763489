/* eslint-disable no-unused-vars */
import {
  Button,
  Box,
  TextField,
  MenuItem,
  InputAdornment,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  RadioGroup,
} from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  usePublicPublicationsQuery,
  usePublishedCategoriesQuery,
} from '../../../app/services/CategoriesApi';
import { useCreateCourseMutation } from '../../../app/services/CoursesApi';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useSnackbar } from 'notistack';
import MomentUtils from '@date-io/moment';

function CreateNewCourse() {
  const { enqueueSnackbar } = useSnackbar();

  const { data, isLoading, isSuccess } = usePublishedCategoriesQuery();
  const publicationsRes = usePublicPublicationsQuery();

  const [createCourse, result] = useCreateCourseMutation();

  const [categoryTitle, setCategoryTitle] = useState('');

  const [subCategoryTitle, setSubCategoryTitle] = useState('');
  const [gradeTitle, setGradeTitle] = useState('');
  const [gradeSubjectTitle, setGradeSubjectTitle] = useState('');

  const [publicationTitle, setPublicationTitle] = useState('');
  const [publicationEditionTitle, setPublicationEditionTitle] = useState('');

  const [selectedCategory, setSelectedCategory] = useState({
    title: '',
    id: null,
    published_sub_categories: [],
  });
  const [selectedSubCategory, setSelectedSubCategory] = useState({
    title: '',
    id: null,
  });

  const [selectedGrade, setSelectedGrade] = useState({
    title: '',
    id: null,
  });

  const [selectedGradeSubject, setSelectedGradeSubject] = useState({
    title: '',
    id: null,
  });

  const [selectedPublication, setSelectedPublication] = useState({
    title: '',
    id: null,
  });

  const [selectedPublicationEdition, setSelectedPublicationEdition] = useState({
    edition: '',
    id: null,
  });

  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());

  const [level, setLevel] = useState('beginner');

  const handleLevelChange = (event) => {
    setLevel(event.target.value);
  };
  const handleStartDateChange = (newValue) => {
    setStartDate(newValue);
  };
  const handleEndDateChange = (newValue) => {
    setEndDate(newValue);
  };

  const validateData = (courseData) => {
    let err = true;

    if (
      !courseData.title ||
      !courseData.sub_category_id ||
      !courseData.language ||
      !courseData.start_date ||
      !courseData.end_date
    ) {
      enqueueSnackbar('Please provide all the required fields', {
        variant: 'error',
      });
    } else {
      err = false;
    }

    return err;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const courseData = {
      title: data.get('title'),
      description: data.get('description'),
      sub_category_id: selectedSubCategory.id,
      language: data.get('language'),
      start_date: startDate.format(),
      end_date: endDate.format(),
      cost: data.get('cost'),
      grade_id: selectedGrade.id,
      publication_id: selectedPublication.id,
      grade_subject_id: selectedGradeSubject.id,
      publication_edition_id: selectedPublicationEdition.id,
      level: level,
    };

    const res = validateData(courseData);
    if (!res) {
      createCourse(courseData);
    }
  };

  const handleCategoryChange = (event) => {
    const tempSelectedCategory = data.categories.find(
      (singleCategory) => singleCategory.id === event.target.value
    );
    setSelectedCategory(tempSelectedCategory);
    setCategoryTitle(event.target.value);
  };

  const handleSubCategoryChange = (event) => {
    const tempSelectedSubCategory = selectedCategory.published_sub_categories.find(
      (singleSubCategory) => singleSubCategory.id === event.target.value
    );

    setSelectedSubCategory(tempSelectedSubCategory);
    setSubCategoryTitle(event.target.value);
  };

  const handleGradeChange = (event) => {
    const tempSelectedGrade = selectedSubCategory.grades.find(
      (singleGrade) => singleGrade.id === event.target.value
    );

    setSelectedGrade(tempSelectedGrade);
    setGradeTitle(event.target.value);
  };

  const handleGradeSubjectChange = (event) => {
    const tempSelectedGradeSubject = selectedGrade.grade_subjects.find(
      (singleGradeSubject) => singleGradeSubject.id === event.target.value
    );

    setSelectedGradeSubject(tempSelectedGradeSubject);
    setGradeSubjectTitle(event.target.value);
  };

  const handlePublicationChange = (event) => {
    const tempSelectedPublication = publicationsRes.data.publications.find(
      (singlePublication) => singlePublication.id === event.target.value
    );

    setSelectedPublication(tempSelectedPublication);
    setPublicationTitle(event.target.value);
  };

  const handlePublicationEditionChange = (event) => {
    const tempPublicationEdition = selectedPublication.publication_editions.find(
      (singlePublicationEdition) =>
        singlePublicationEdition.id === event.target.value
    );

    setSelectedPublicationEdition(tempPublicationEdition);
    setPublicationEditionTitle(event.target.value);
  };

  return result.data ? (
    result.data.message
  ) : (
    <>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="title"
          label=" Title"
          name="title"
          autoComplete="title"
          autoFocus
        />
        <TextField
          required
          margin="normal"
          fullWidth
          id="description"
          label="Description"
          name="description"
          autoComplete="description"
          autoFocus
          multiline
          rows="2"
        />

        <TextField
          margin="normal"
          select
          required
          fullWidth
          id="category"
          label="Category"
          onChange={handleCategoryChange}
          name="category"
          autoComplete="category"
          autoFocus
          value={categoryTitle}
        >
          {data.categories && data.categories.length
            ? data.categories.map((singleCategory, index) => (
                <MenuItem value={singleCategory.id} key={singleCategory.id}>
                  {singleCategory.title}
                </MenuItem>
              ))
            : null}
        </TextField>
        <TextField
          margin="normal"
          select
          required
          fullWidth
          id="sub_category"
          label="Sub Category"
          onChange={handleSubCategoryChange}
          name="sub_category"
          autoComplete="sub_category"
          autoFocus
          value={subCategoryTitle}
        >
          {selectedCategory && selectedCategory.published_sub_categories.length
            ? selectedCategory.published_sub_categories.map(
                (singleSubCategory, index) => (
                  <MenuItem value={singleSubCategory.id} key={singleSubCategory.id}>
                    {singleSubCategory.title}
                  </MenuItem>
                )
              )
            : null}
        </TextField>

        {selectedCategory.id &&
        selectedSubCategory.id &&
        selectedSubCategory.grades.length ? (
          <TextField
            margin="normal"
            select
            required
            fullWidth
            id="grade"
            label="Grade"
            onChange={handleGradeChange}
            name="grade"
            autoComplete="grade"
            autoFocus
            value={gradeTitle}
          >
            {selectedCategory &&
            selectedSubCategory &&
            selectedSubCategory.grades.length
              ? selectedSubCategory.grades.map((singleGrade, index) => (
                  <MenuItem value={singleGrade.id} key={singleGrade.id}>
                    {singleGrade.title}
                  </MenuItem>
                ))
              : null}
          </TextField>
        ) : null}

        {selectedCategory.id &&
        selectedSubCategory.id &&
        selectedGrade.id &&
        selectedGrade.grade_subjects.length ? (
          <TextField
            margin="normal"
            select
            required
            fullWidth
            id="grade_subject"
            label="Grade Subject"
            onChange={handleGradeSubjectChange}
            name="grade subject"
            autoComplete="grade subject"
            autoFocus
            value={gradeSubjectTitle}
          >
            {selectedGrade.grade_subjects.length
              ? selectedGrade.grade_subjects.map((singleGradeSubject, index) => (
                  <MenuItem
                    value={singleGradeSubject.id}
                    key={singleGradeSubject.id}
                  >
                    {singleGradeSubject.title}
                  </MenuItem>
                ))
              : null}
          </TextField>
        ) : null}

        {selectedCategory.id &&
        selectedSubCategory.id &&
        selectedGrade.id &&
        publicationsRes.data?.publications.length ? (
          <TextField
            margin="normal"
            select
            required
            fullWidth
            id="publication"
            label="Publication"
            onChange={handlePublicationChange}
            name="publication"
            autoComplete="publication"
            autoFocus
            value={publicationTitle}
          >
            {publicationsRes.data.publications.length
              ? publicationsRes.data.publications.map((singlePublication, index) => (
                  <MenuItem value={singlePublication.id} key={singlePublication.id}>
                    {singlePublication.title}
                  </MenuItem>
                ))
              : null}
          </TextField>
        ) : null}

        {selectedCategory.id &&
        selectedSubCategory.id &&
        selectedGrade.id &&
        publicationsRes.data?.publications.length &&
        selectedPublication.id &&
        selectedPublication.publication_editions.length ? (
          <TextField
            margin="normal"
            select
            required
            fullWidth
            id="publication_edition"
            label="Publication Edition"
            onChange={handlePublicationEditionChange}
            name="Publication Edition"
            autoComplete="Publication Edition"
            autoFocus
            value={publicationEditionTitle}
          >
            {selectedPublication.publication_editions.length
              ? selectedPublication.publication_editions.map(
                  (singlePublicationEdition, index) => (
                    <MenuItem
                      value={singlePublicationEdition.id}
                      key={singlePublicationEdition.id}
                    >
                      {singlePublicationEdition.edition}
                    </MenuItem>
                  )
                )
              : null}
          </TextField>
        ) : null}

        <TextField
          margin="normal"
          required
          fullWidth
          id="language"
          label="Language"
          name="language"
          autoComplete="language"
          autoFocus
        />

        <Box sx={{ mt: 2 }}>
          <LocalizationProvider dateAdapter={MomentUtils}>
            <DateTimePicker
              label="Start Date and Time"
              value={startDate}
              onChange={handleStartDateChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={MomentUtils}>
            <DateTimePicker
              label="End Date and Time"
              value={endDate}
              onChange={handleEndDateChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Box>

        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">Learning Level</FormLabel>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={level}
            onChange={handleLevelChange}
          >
            <FormControlLabel
              value="beginner"
              control={<Radio />}
              label="Beginner"
            />
            <FormControlLabel
              value="intermediate"
              control={<Radio />}
              label="Intermediate"
            />
            <FormControlLabel value="expert" control={<Radio />} label="Expert" />
          </RadioGroup>
        </FormControl>

        <TextField
          margin="normal"
          // required
          fullWidth
          id="cost"
          label="Cost"
          name="cost"
          autoComplete="cost"
          type="number"
          autoFocus
          InputProps={{
            startAdornment: <InputAdornment position="start">₹</InputAdornment>,
          }}
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={result.isLoading || result.isSuccess}
        >
          Add Course
        </Button>
      </Box>
    </>
  );
}

export default CreateNewCourse;
