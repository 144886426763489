/* eslint-disable no-unused-vars */
import { useSearchParams } from 'react-router-dom';
import {
  Grid,
  Typography,
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  Chip,
  Rating,
  Button,
  Checkbox,
  FormGroup,
  Stack,
  Pagination,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  useSearchMutation,
  useSearchOnFilterMutation,
  useSearchOnFilterWithPageMutation,
  useSearchQuery,
} from '../../../app/services/SearchApi';
import DisplaySearchCourseResults from './DisplaySearchCourseResults';
import DisplaySearchExamResults from './DisplaySearchExamResults';
import DisplaySearchTestResults from './DisplaySearchTestResults';
import { forEach } from 'lodash';
import { usePublicPublicationsQuery } from '../../../app/services/CategoriesApi';
import DataLoader from '../../../components/DataLoader';

const languages = [
  'Assamese',
  'Bengali',
  'Bodo',
  'Dogri',
  'English',
  'Gujarati',
  'Hindi',
  'Kannada',
  'Kashmiri',
  'Konkani',
  'Maithili',
  'Malayalam',
  'Marathi',
  'Meitei',
  'Nepali',
  'Odia',
  'Punjabi',
  'Sanskrit',
  'Santali',
  'Sindhi',
  'Tamil',
  'Telugu',
  'Urdu',
];

const grades = [
  'Class 10',
  'Class 9',
  'Class 8',
  'Class 7',
  'Class 6',
  'Class 5',
  'Class 4',
  'Class 3',
  'Class 2',
  'Class 1',
];

const locations = [
  'Amaravati',
  'Itanagar',
  'Dispur',
  'Patna',
  'Raipur',
  'Panaji',
  'Gandhinagar',
  'Chandigarh',
  'Shimla',
  'Srinagar',
  'Ranchi',
  'Bengaluru',
  'Trivandrum',
  'Bhopal',
  'Mumbai	',
  'Imphal',
  'Shillong',
  'Aizawl',
  'Kohima',
  'Bhubaneswar',
  'Chandigarh',
  'Jaipur',
  'Gangtok',
  'Chennai',
  'Hyderabad',
  'Agartala',
  'Lucknow',
  'Dehradun',
  'Kolkata',
];

const gradeSubjectsList = [
  'English',
  'Hindi',
  'Marathi',
  'Mathematics',
  'Science',
  'Social Studies',
  'History',
  'Geography',
  'Civics',
  'Political Science',
  'Economics',
  'Environmental Science',
  'General Knowledge',
  'Physical Education',
  'Art',
  'Music',
  'Computer Science',
  'Information Technology',
  'Health and Physical Education',
  'Business Studies',
  'Accountancy',
  'Physics',
  'Chemistry',
  'Biology',
  'Psychology',
  'Sociology',
  'Philosophy',
];

function SearchQueryPage() {
  const [searchParams, setSearchParams] = useSearchParams();

  // const [searchRequest, result] = useSearchOnFilterMutation();
  //   const [searchOnFilterRequest, result1] = useSearchOnFilterMutation();
  const publicationsRes = usePublicPublicationsQuery();

  const [searchRequestWithPage, result] = useSearchOnFilterWithPageMutation();

  const [openMore, setOpenMore] = useState(false);
  const [open, setOpen] = useState(false);
  const [openMore1, setOpenMore1] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [openMore2, setOpenMore2] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [openMore3, setOpenMore3] = useState(false);
  const [openMore4, setOpenMore4] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [offeringValue, setValue] = useState([]);
  const [levelValue, setLevelValue] = useState([]);
  const [priceValue, setPriceValue] = useState([]);
  const [ratingValue, setRatingValue] = useState([]);
  const [language, setLanguage] = useState([]);
  const [grade, setGrade] = useState([]);
  const [gradeSubject, setGradeSubject] = useState([]);
  const [publication, setPublication] = useState([]);
  const [locationValue, setLocationValue] = useState([]);

  const [page, setPage] = useState(1);
  const handlePageChange = (event, value) => {
    setPage(value);
    searchRequestWithPage({
      page: value,
      searchQuery: searchParams.get('q'),
      offering: offeringValue,
      price: priceValue,
      rating: ratingValue,
      level: levelValue,
      language: language,
      grade: grade,
      publication: publication,
      location: locationValue,
      gradeSubject: gradeSubject,
    });
  };

  useEffect(() => {
    if (searchParams.get('q').length >= 1) {
      searchRequestWithPage({
        page: page,
        searchQuery: searchParams.get('q'),
        offering: offeringValue,
        price: priceValue,
        rating: ratingValue,
        level: levelValue,
        language: language,
        grade: grade,
        publication: publication,
        location: locationValue,
        gradeSubject: gradeSubject,
      });
    }
  }, [
    searchParams.get('q'),
    offeringValue,
    priceValue,
    ratingValue,
    levelValue,
    language,
    grade,
    publication,
    locationValue,
    gradeSubject,
  ]);

  useEffect(() => {
    if (searchParams.get('offering')) {
      setValue(searchParams.getAll('offering'));
    } else {
      setValue([]);
    }
    if (searchParams.get('price')) {
      setPriceValue(searchParams.getAll('price'));
    } else {
      setPriceValue([]);
    }
    if (searchParams.get('rating')) {
      setRatingValue(searchParams.getAll('rating'));
    } else {
      setRatingValue([]);
    }
    if (searchParams.get('level')) {
      setLevelValue(searchParams.getAll('level'));
    } else {
      setLevelValue([]);
    }
    if (searchParams.get('lang')) {
      setLanguage(searchParams.getAll('lang'));
    } else {
      setLanguage([]);
    }
    if (searchParams.get('grade')) {
      setGrade(searchParams.getAll('grade'));
    } else {
      setGrade([]);
    }
    if (searchParams.get('gradeSubject')) {
      setGradeSubject(searchParams.getAll('gradeSubject'));
    } else {
      setGradeSubject([]);
    }
    if (searchParams.get('publication')) {
      setPublication(searchParams.getAll('publication'));
    } else {
      setPublication([]);
    }
    if (searchParams.get('location')) {
      setLocationValue(searchParams.getAll('location'));
    } else {
      setLocationValue([]);
    }
  }, []);

  // useEffect(() => {

  //   setSearchParams((searchParams) => {
  //     searchParams.delete('offering');
  //     searchParams.delete('level');
  //     searchParams.delete('price');
  //     searchParams.delete('grade');
  //     searchParams.delete('lang');
  //     searchParams.delete('publication');
  //     searchParams.delete('rating');
  //     searchParams.delete('location');
  //     return searchParams;
  //   });
  // }, [searchParams.get('q')]);

  const handleChipClick = () => {
    setValue([]);
    setLevelValue([]);
    setPriceValue([]);
    setRatingValue([]);
    setLanguage([]);
    setGrade([]);
    setGradeSubject([]);
    setPublication([]);
    setLocationValue([]);
    setSearchParams((searchParams) => {
      searchParams.delete('offering');
      searchParams.delete('level');
      searchParams.delete('price');
      searchParams.delete('grade');
      searchParams.delete('gradeSubject');
      searchParams.delete('lang');
      searchParams.delete('publication');
      searchParams.delete('rating');
      searchParams.delete('location');
      return searchParams;
    });
  };
  const handleClick = () => {
    setOpen(!open);
  };

  const handlePublicationChange = (event) => {
    if (!event.target.checked) {
      const index = publication.indexOf(event.target.name);
      if (index > -1) {
        publication.splice(index, 1);
      }

      const publicationsSet = searchParams.getAll('publication');
      const filterPublications = publicationsSet.filter(
        (singlePublication) => singlePublication != event.target.name
      );

      setPublication(filterPublications);

      if (filterPublications != null && filterPublications.length >= 1) {
        setSearchParams((searchParams) => {
          searchParams.delete('publication');
          return searchParams;
        });
        filterPublications.forEach((singlePublication) => {
          setSearchParams((searchParams) => {
            searchParams.append('publication', singlePublication);
            return searchParams;
          });
        });
      } else {
        setSearchParams((searchParams) => {
          searchParams.delete('publication');
          return searchParams;
        });
      }
    } else {
      setPublication([...publication, event.target.name]);
      setSearchParams((searchParams) => {
        searchParams.append('publication', event.target.name);
        return searchParams;
      });
    }
  };

  const handleGradeChange = (event) => {
    if (!event.target.checked) {
      const index = grade.indexOf(event.target.name);
      if (index > -1) {
        grade.splice(index, 1);
      }

      const gradesSet = searchParams.getAll('grade');
      const filteredGrades = gradesSet.filter(
        (singleGrade) => singleGrade != event.target.name
      );
      setGrade(filteredGrades);

      if (filteredGrades != null && filteredGrades.length >= 1) {
        setSearchParams((searchParams) => {
          searchParams.delete('grade');
          return searchParams;
        });
        filteredGrades.forEach((singleGrade) => {
          setSearchParams((searchParams) => {
            searchParams.append('grade', singleGrade);
            return searchParams;
          });
        });
      } else {
        setSearchParams((searchParams) => {
          searchParams.delete('grade');
          return searchParams;
        });
      }
    } else {
      setGrade([...grade, event.target.name]);
      setSearchParams((searchParams) => {
        searchParams.append('grade', event.target.name);
        return searchParams;
      });
    }
  };

  const handleGradeSubjectChange = (event) => {
    if (!event.target.checked) {
      const index = gradeSubject.indexOf(event.target.name);
      if (index > -1) {
        gradeSubject.splice(index, 1);
      }

      const gradeSubjectsSet = searchParams.getAll('gradeSubject');
      const filteredGradeSubjects = gradeSubjectsSet.filter(
        (singleGradeSubject) => singleGradeSubject != event.target.name
      );
      setGradeSubject(filteredGradeSubjects);

      if (filteredGradeSubjects != null && filteredGradeSubjects.length >= 1) {
        setSearchParams((searchParams) => {
          searchParams.delete('gradeSubject');
          return searchParams;
        });
        filteredGradeSubjects.forEach((singleGradeSubject) => {
          setSearchParams((searchParams) => {
            searchParams.append('gradeSubject', singleGradeSubject);
            return searchParams;
          });
        });
      } else {
        setSearchParams((searchParams) => {
          searchParams.delete('gradeSubject');
          return searchParams;
        });
      }
    } else {
      setGradeSubject([...gradeSubject, event.target.name]);
      setSearchParams((searchParams) => {
        searchParams.append('gradeSubject', event.target.name);
        return searchParams;
      });
    }
  };

  const handleLanguageChange = (event) => {
    if (!event.target.checked) {
      const index = language.indexOf(event.target.name);
      if (index > -1) {
        language.splice(index, 1);
      }

      const languagesSet = searchParams.getAll('lang');
      const filterLanguages = languagesSet.filter(
        (singleLanguage) => singleLanguage != event.target.name
      );
      setLanguage(filterLanguages);

      if (filterLanguages != null && filterLanguages.length >= 1) {
        setSearchParams((searchParams) => {
          searchParams.delete('lang');
          return searchParams;
        });
        filterLanguages.forEach((singleLanguage) => {
          setSearchParams((searchParams) => {
            searchParams.append('lang', singleLanguage);
            return searchParams;
          });
        });
      } else {
        setSearchParams((searchParams) => {
          searchParams.delete('lang');
          return searchParams;
        });
      }
    } else {
      setLanguage([...language, event.target.name]);
      setSearchParams((searchParams) => {
        searchParams.append('lang', event.target.name);
        return searchParams;
      });
    }
  };

  const handleLevelChange = (event) => {
    if (!event.target.checked) {
      const index = levelValue.indexOf(event.target.name);
      if (index > -1) {
        levelValue.splice(index, 1);
      }

      const levelsSet = searchParams.getAll('level');
      const filterLevels = levelsSet.filter(
        (singleLanguage) => singleLanguage != event.target.name
      );
      setLevelValue(filterLevels);

      if (filterLevels != null && filterLevels.length >= 1) {
        setSearchParams((searchParams) => {
          searchParams.delete('level');
          return searchParams;
        });
        filterLevels.forEach((singleLanguage) => {
          setSearchParams((searchParams) => {
            searchParams.append('level', singleLanguage);
            return searchParams;
          });
        });
      } else {
        setSearchParams((searchParams) => {
          searchParams.delete('level');
          return searchParams;
        });
      }
    } else {
      setLevelValue([...levelValue, event.target.name]);
      setSearchParams((searchParams) => {
        searchParams.append('level', event.target.name);
        return searchParams;
      });
    }
  };

  const handleLocationChange = (event) => {
    if (!event.target.checked) {
      const index = locationValue.indexOf(event.target.name);
      if (index > -1) {
        locationValue.splice(index, 1);
      }

      const locationsSet = searchParams.getAll('location');
      const filterLocations = locationsSet.filter(
        (singleLanguage) => singleLanguage != event.target.name
      );

      setLocationValue(filterLocations);

      if (filterLocations != null && filterLocations.length >= 1) {
        setSearchParams((searchParams) => {
          searchParams.delete('location');
          return searchParams;
        });
        filterLocations.forEach((singleLanguage) => {
          setSearchParams((searchParams) => {
            searchParams.append('location', singleLanguage);
            return searchParams;
          });
        });
      } else {
        setSearchParams((searchParams) => {
          searchParams.delete('location');
          return searchParams;
        });
      }
    } else {
      setLocationValue([...locationValue, event.target.name]);
      setSearchParams((searchParams) => {
        searchParams.append('location', event.target.name);
        return searchParams;
      });
    }
  };

  const handleChange = (event) => {
    if (!event.target.checked) {
      const index = offeringValue.indexOf(event.target.name);
      if (index > -1) {
        offeringValue.splice(index, 1);
      }

      const offeringsSet = searchParams.getAll('offering');
      const filteredOfferings = offeringsSet.filter(
        (singleOffering) => singleOffering != event.target.name
      );
      setValue(filteredOfferings);

      if (filteredOfferings != null && filteredOfferings.length >= 1) {
        setSearchParams((searchParams) => {
          searchParams.delete('offering');
          return searchParams;
        });
        filteredOfferings.forEach((singleOffering) => {
          setSearchParams((searchParams) => {
            searchParams.append('offering', singleOffering);
            return searchParams;
          });
        });
      } else {
        setSearchParams((searchParams) => {
          searchParams.delete('offering');
          return searchParams;
        });
      }
    } else {
      setValue([...offeringValue, event.target.name]);
      setSearchParams((searchParams) => {
        searchParams.append('offering', event.target.name);
        return searchParams;
      });
    }
  };

  const handlePriceChange = (event) => {
    if (!event.target.checked) {
      const index = priceValue.indexOf(event.target.name);
      if (index > -1) {
        priceValue.splice(index, 1);
      }

      const pricesSet = searchParams.getAll('price');
      const filteredPrices = pricesSet.filter(
        (singlePrice) => singlePrice != event.target.name
      );
      setPriceValue(filteredPrices);

      if (filteredPrices != null && filteredPrices.length >= 1) {
        setSearchParams((searchParams) => {
          searchParams.delete('price');
          return searchParams;
        });
        filteredPrices.forEach((singlePrice) => {
          setSearchParams((searchParams) => {
            searchParams.append('price', singlePrice);
            return searchParams;
          });
        });
      } else {
        setSearchParams((searchParams) => {
          searchParams.delete('price');
          return searchParams;
        });
      }
    } else {
      setPriceValue([...priceValue, event.target.name]);
      setSearchParams((searchParams) => {
        searchParams.append('price', event.target.name);
        return searchParams;
      });
    }
  };

  const handleRatingChange = (event) => {
    if (!event.target.checked) {
      const index = ratingValue.indexOf(event.target.name);
      if (index > -1) {
        ratingValue.splice(index, 1);
      }

      const ratingsSet = searchParams.getAll('rating');
      const filteredRatings = ratingsSet.filter(
        (singleRating) => singleRating != event.target.name
      );
      setRatingValue(filteredRatings);

      if (filteredRatings != null && filteredRatings.length >= 1) {
        setSearchParams((searchParams) => {
          searchParams.delete('rating');
          return searchParams;
        });
        filteredRatings.forEach((singleRating) => {
          setSearchParams((searchParams) => {
            searchParams.append('rating', singleRating);
            return searchParams;
          });
        });
      } else {
        setSearchParams((searchParams) => {
          searchParams.delete('rating');
          return searchParams;
        });
      }
    } else {
      setRatingValue([...ratingValue, event.target.name]);
      setSearchParams((searchParams) => {
        searchParams.append('rating', event.target.name);
        return searchParams;
      });
    }
  };

  return (
    <Grid container spacing={2} align="left">
      <Grid item xs="12" sm="12" md="3" lg="3" xl="3">
        <Box sx={{ m: 2, overflowWrap: 'anywhere' }}>
          <>
            <Typography color="text.secondary">
              Filters
              <Chip
                label="Clear"
                onClick={handleChipClick}
                deleteIcon={<DeleteIcon />}
                variant="outlined"
                size="small"
                sx={{ float: 'right' }}
              />
            </Typography>
            <Divider />
            <FormControl component="fieldset" sx={{ marginTop: 2 }}>
              <FormLabel component="legend" id="offerings">
                Types of offerings
              </FormLabel>
              <FormGroup aria-label="position">
                <FormControlLabel
                  label="Courses"
                  control={
                    <Checkbox
                      name="courses"
                      onChange={handleChange}
                      checked={offeringValue.includes('courses')}
                    />
                  }
                  key="courses"
                  size="small"
                />
                <FormControlLabel
                  label="Exams"
                  control={
                    <Checkbox
                      name="exams"
                      onChange={handleChange}
                      checked={offeringValue.includes('exams')}
                    />
                  }
                  key="exams"
                />
                <FormControlLabel
                  label="Practice Tests"
                  control={
                    <Checkbox
                      name="practiceTests"
                      onChange={handleChange}
                      checked={offeringValue.includes('practiceTests')}
                    />
                  }
                  key="practiceTests"
                  size="small"
                />
              </FormGroup>
            </FormControl>
            <Divider />
            <FormControl component="fieldset" sx={{ marginTop: 2 }}>
              <FormLabel component="legend" id="price">
                Price
              </FormLabel>
              <FormGroup aria-label="position">
                <FormControlLabel
                  label="Free"
                  control={
                    <Checkbox
                      name="free"
                      onChange={handlePriceChange}
                      checked={priceValue.includes('free')}
                    />
                  }
                  key="free"
                  size="small"
                />
                <FormControlLabel
                  label="Paid"
                  control={
                    <Checkbox
                      name="paid"
                      onChange={handlePriceChange}
                      checked={priceValue.includes('paid')}
                    />
                  }
                  key="paid"
                />
              </FormGroup>
            </FormControl>
            <Divider />
            <FormControl component="fieldset" sx={{ marginTop: 2 }}>
              <FormLabel component="legend" id="ratings">
                Ratings
              </FormLabel>
              <FormGroup aria-label="position">
                <FormControlLabel
                  label={
                    <Box sx={{ display: 'flex' }}>
                      <Rating
                        name="read-only"
                        value={4.5}
                        readOnly
                        precision={0.5}
                        size="small"
                      />
                      <Box sx={{ ml: 2, fontSize: 'x-small' }}>4.5 & up</Box>
                    </Box>
                  }
                  control={
                    <Checkbox
                      name="4.5"
                      onChange={handleRatingChange}
                      checked={ratingValue.includes('4.5')}
                    />
                  }
                  key="4.5"
                  size="small"
                />
                <FormControlLabel
                  label={
                    <Box sx={{ display: 'flex' }}>
                      <Rating
                        name="read-only"
                        value={4}
                        readOnly
                        precision={0.5}
                        size="small"
                      />
                      <Box sx={{ ml: 2, fontSize: 'x-small' }}>4 & up</Box>
                    </Box>
                  }
                  control={
                    <Checkbox
                      name="4"
                      onChange={handleRatingChange}
                      checked={ratingValue.includes('4')}
                    />
                  }
                  key="4"
                  size="small"
                />
                <FormControlLabel
                  label={
                    <Box sx={{ display: 'flex' }}>
                      <Rating
                        name="read-only"
                        value={3.5}
                        readOnly
                        precision={0.5}
                        size="small"
                      />
                      <Box sx={{ ml: 2, fontSize: 'x-small' }}>3.5 & up</Box>
                    </Box>
                  }
                  control={
                    <Checkbox
                      name="3.5"
                      onChange={handleRatingChange}
                      checked={ratingValue.includes('3.5')}
                    />
                  }
                  key="3.5"
                />
                <FormControlLabel
                  label={
                    <Box sx={{ display: 'flex' }}>
                      <Rating
                        name="read-only"
                        value={3}
                        readOnly
                        precision={0.5}
                        size="small"
                      />
                      <Box sx={{ ml: 2, fontSize: 'x-small' }}>3 & up</Box>
                    </Box>
                  }
                  control={
                    <Checkbox
                      name="3"
                      onChange={handleRatingChange}
                      checked={ratingValue.includes('3')}
                    />
                  }
                  key="3"
                  size="small"
                />
              </FormGroup>
            </FormControl>
            <Divider />
            <FormControl component="fieldset" sx={{ marginTop: 2 }}>
              <FormLabel component="legend" id="levels">
                Levels
              </FormLabel>
              <FormGroup aria-label="position">
                <FormControlLabel
                  label="Beginner"
                  control={
                    <Checkbox
                      name="beginner"
                      onChange={handleLevelChange}
                      checked={levelValue.includes('beginner')}
                    />
                  }
                  key="beginner"
                  size="small"
                />
                <FormControlLabel
                  label="Intermediate"
                  control={
                    <Checkbox
                      name="intermediate"
                      onChange={handleLevelChange}
                      checked={levelValue.includes('intermediate')}
                    />
                  }
                  key="intermediate"
                />
                <FormControlLabel
                  label="Expert"
                  control={
                    <Checkbox
                      name="expert"
                      onChange={handleLevelChange}
                      checked={levelValue.includes('expert')}
                    />
                  }
                  key="expert"
                  size="small"
                />
              </FormGroup>
            </FormControl>
            <Divider />
            <FormControl component="fieldset" sx={{ marginTop: 2 }}>
              <FormLabel component="legend" id="grades">
                Grade
              </FormLabel>

              <FormGroup aria-label="position">
                {openMore
                  ? grades.map((singleGrade, index) => (
                      <FormControlLabel
                        // value={singleGrade}
                        control={
                          <Checkbox
                            name={singleGrade}
                            onChange={handleGradeChange}
                            checked={grade.includes(singleGrade)}
                          />
                        }
                        label={singleGrade}
                        labelPlacement="end"
                        key={singleGrade}
                      />
                    ))
                  : grades.slice(0, 5).map((singleGrade, index) => (
                      <FormControlLabel
                        // value={singleGrade}
                        control={
                          <Checkbox
                            name={singleGrade}
                            onChange={handleGradeChange}
                            // checked={grade[singleGrade]}
                            checked={grade.includes(singleGrade)}
                          />
                        }
                        label={singleGrade}
                        labelPlacement="end"
                        key={singleGrade}
                      />
                    ))}
                {openMore ? (
                  <Button
                    onClick={() => {
                      setOpenMore(false);
                    }}
                    sx={{ zIndex: 'modal' }}
                  >
                    Less
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      setOpenMore(true);
                    }}
                    sx={{ zIndex: 'modal' }}
                  >
                    More
                  </Button>
                )}
              </FormGroup>
            </FormControl>
            <Divider />{' '}
            <FormControl component="fieldset" sx={{ marginTop: 2 }}>
              <FormLabel component="legend" id="Subjects">
                Grade Subject
              </FormLabel>

              <FormGroup aria-label="position">
                {openMore4
                  ? gradeSubjectsList.map((singleGradeSubject, index) => (
                      <FormControlLabel
                        // value={singleGradeSubject}
                        control={
                          <Checkbox
                            name={singleGradeSubject}
                            onChange={handleGradeSubjectChange}
                            checked={gradeSubject.includes(singleGradeSubject)}
                          />
                        }
                        label={singleGradeSubject}
                        labelPlacement="end"
                        key={singleGradeSubject}
                      />
                    ))
                  : gradeSubjectsList
                      .slice(0, 5)
                      .map((singleGradeSubject, index) => (
                        <FormControlLabel
                          // value={singleGradeSubject}
                          control={
                            <Checkbox
                              name={singleGradeSubject}
                              onChange={handleGradeSubjectChange}
                              // checked={grade[singleGradeSubject]}
                              checked={gradeSubject.includes(singleGradeSubject)}
                            />
                          }
                          label={singleGradeSubject}
                          labelPlacement="end"
                          key={singleGradeSubject}
                        />
                      ))}
                {openMore4 ? (
                  <Button
                    onClick={() => {
                      setOpenMore4(false);
                    }}
                    sx={{ zIndex: 'modal' }}
                  >
                    Less
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      setOpenMore4(true);
                    }}
                    sx={{ zIndex: 'modal' }}
                  >
                    More
                  </Button>
                )}
              </FormGroup>
            </FormControl>
            <Divider />
            <FormControl component="fieldset" sx={{ marginTop: 2 }}>
              <FormLabel component="legend" id="languages">
                Language
              </FormLabel>

              <FormGroup aria-label="position">
                {openMore1
                  ? languages.map((singleLanguage, index) => (
                      <FormControlLabel
                        value={singleLanguage}
                        control={
                          <Checkbox
                            name={singleLanguage}
                            onChange={handleLanguageChange}
                            // checked={language[singleLanguage]}
                            checked={language.includes(singleLanguage)}
                          />
                        }
                        label={singleLanguage}
                        labelPlacement="end"
                        key={singleLanguage}
                      />
                    ))
                  : languages.slice(0, 5).map((singleLanguage, index) => (
                      <FormControlLabel
                        value={singleLanguage}
                        control={
                          <Checkbox
                            name={singleLanguage}
                            onChange={handleLanguageChange}
                            // checked={language[singleLanguage]}
                            checked={language.includes(singleLanguage)}
                          />
                        }
                        label={singleLanguage}
                        labelPlacement="end"
                        key={singleLanguage}
                      />
                    ))}
                {openMore1 ? (
                  <Button
                    onClick={() => {
                      setOpenMore1(false);
                    }}
                    sx={{ zIndex: 'modal' }}
                  >
                    Less
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      setOpenMore1(true);
                    }}
                    sx={{ zIndex: 'modal' }}
                  >
                    More
                  </Button>
                )}
              </FormGroup>
            </FormControl>
            <Divider />
            {publicationsRes.data && publicationsRes.data.publications.length ? (
              <>
                <FormControl component="fieldset" sx={{ marginTop: 2 }}>
                  <FormLabel component="legend" id="publications">
                    Publication
                  </FormLabel>

                  <FormGroup aria-label="position">
                    {openMore2
                      ? publicationsRes.data.publications.map(
                          (singlePublication, index) => (
                            <FormControlLabel
                              value={singlePublication.title}
                              control={
                                <Checkbox
                                  name={singlePublication.title}
                                  onChange={handlePublicationChange}
                                  // checked={publication[singlePublication.title]}
                                  checked={publication.includes(
                                    singlePublication.title
                                  )}
                                />
                              }
                              label={singlePublication.title}
                              labelPlacement="end"
                              key={singlePublication.title}
                            />
                          )
                        )
                      : publicationsRes.data.publications
                          .slice(0, 5)
                          .map((singlePublication, index) => (
                            <FormControlLabel
                              value={singlePublication.title}
                              control={
                                <Checkbox
                                  name={singlePublication.title}
                                  onChange={handlePublicationChange}
                                  // checked={publication[singlePublication.title]}
                                  checked={publication.includes(
                                    singlePublication.title
                                  )}
                                />
                              }
                              label={singlePublication.title}
                              labelPlacement="end"
                              key={singlePublication.title}
                            />
                          ))}
                    {openMore2 ? (
                      <Button
                        onClick={() => {
                          setOpenMore2(false);
                        }}
                        sx={{ zIndex: 'modal' }}
                      >
                        Less
                      </Button>
                    ) : (
                      <Button
                        onClick={() => {
                          setOpenMore2(true);
                        }}
                        sx={{ zIndex: 'modal' }}
                      >
                        More
                      </Button>
                    )}
                  </FormGroup>
                </FormControl>
              </>
            ) : null}
            <FormControl component="fieldset" sx={{ marginTop: 2 }}>
              <FormLabel component="legend" id="locations">
                Locations
              </FormLabel>

              <FormGroup aria-label="position">
                {openMore3
                  ? locations.map((singleLocation, index) => (
                      <FormControlLabel
                        value={singleLocation}
                        control={
                          <Checkbox
                            name={singleLocation}
                            onChange={handleLocationChange}
                            // checked={locationValue[singleLocation]}
                            checked={locationValue.includes(singleLocation)}
                          />
                        }
                        label={singleLocation}
                        labelPlacement="end"
                        key={singleLocation}
                      />
                    ))
                  : locations.slice(0, 5).map((singleLocation, index) => (
                      <FormControlLabel
                        value={singleLocation}
                        control={
                          <Checkbox
                            name={singleLocation}
                            onChange={handleLocationChange}
                            // checked={locationValue[singleLocation]}
                            checked={locationValue.includes(singleLocation)}
                          />
                        }
                        label={singleLocation}
                        labelPlacement="end"
                        key={singleLocation}
                      />
                    ))}
                {openMore3 ? (
                  <Button
                    onClick={() => {
                      setOpenMore3(false);
                    }}
                    sx={{ zIndex: 'modal' }}
                  >
                    Less
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      setOpenMore3(true);
                    }}
                    sx={{ zIndex: 'modal' }}
                  >
                    More
                  </Button>
                )}
              </FormGroup>
            </FormControl>
            <Divider />
          </>
        </Box>
      </Grid>
      <Grid item xs="12" sm="12" md="9" lg="9" xl="9">
        <Grid container spacing={2}>
          <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
            <Box
              sx={{
                marginTop: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
              }}
            >
              <>
                {result.isLoading ? <DataLoader thickness={5} size={60} /> : null}

                {result && result.isSuccess ? (
                  <Typography variant="h4">
                    {result.data.courses.total}
                    &nbsp;results for &ldquo;{searchParams.get('q')}&rdquo;
                  </Typography>
                ) : null}

                {result && result.isSuccess ? (
                  <>
                    <Stack spacing={2}>
                      <Typography>Courses</Typography>
                      <DisplaySearchCourseResults
                        courses={result?.data?.courses?.data}
                      />
                      <Pagination
                        color="secondary"
                        count={result?.data?.courses?.last_page}
                        page={page}
                        onChange={handlePageChange}
                        size="large"
                        disabled={result?.data?.courses?.total === 0}
                      />
                    </Stack>
                  </>
                ) : null}

                {/* {result && result.isSuccess && result.data.exams.length ? (
                  <>
                    <Typography>Exams</Typography>
                    <DisplaySearchExamResults exams={result.data.exams} />
                  </>
                ) : null}
                {result && result.isSuccess && result.data.tests.length ? (
                  <>
                    <Typography>Tests</Typography>
                    <DisplaySearchTestResults tests={result.data.tests} />
                  </>
                ) : null} */}

                {/* <Typography>Courses</Typography>

                {result1 && result1.isSuccess ? (
                  <DisplaySearchCourseResults courses={result1.data.courses} />
                ) : null}
                <Typography>Exams</Typography>

                {result1 && result1.isSuccess ? (
                  <DisplaySearchExamResults exams={result1.data.exams} />
                ) : null}
                <Typography>Tests</Typography>
                {result1 && result1.isSuccess ? (
                  <DisplaySearchTestResults tests={result1.data.tests} />
                ) : null} */}
              </>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SearchQueryPage;
