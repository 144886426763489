/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BaseApi } from './BaseApi';

const API_URL = `${process.env.REACT_APP_BACKEND_API_PATH}/api`;
const LOCALSTORAGE_APP_KEY = `${process.env.REACT_APP_LOCALSTORAGE_KEY}`;

export const SearchApi = BaseApi.injectEndpoints({
  reducerPath: 'SearchApi',
  endpoints: (builder) => ({
    search: builder.mutation({
      query: (details) => ({
        url: '/search',
        method: 'POST',

        body: {
          searchQuery: details.searchQuery,
        },
      }),
    }),
    searchOnFilter: builder.mutation({
      query: (details) => ({
        url: '/search/filter',
        method: 'POST',

        body: {
          searchQuery: details.searchQuery,
          offering: details.offering,
          price: details.price,
          rating: details.rating,
          level: details.level,
          language: details.language,
          grade: details.grade,
          publisher: details.publication,
          location: details.location,
          gradeSubject: details.gradeSubject,
        },
      }),
    }),
    subCategoryFilter: builder.mutation({
      query: (details) => ({
        url: '/subCategory/filter',
        method: 'POST',

        body: {
          sub_category_id: details.sub_category_id,
          offering: details.offering,
          price: details.price,
          rating: details.rating,
          level: details.level,
          language: details.language,
          grade: details.grade,
          publisher: details.publication,
          location: details.location,
          gradeSubject: details.gradeSubject,
        },
      }),
    }),

    searchOnFilterWithPage: builder.mutation({
      query: (details) => ({
        url: `/search/filter?page=${details.page}`,
        method: 'POST',

        body: {
          searchQuery: details.searchQuery,
          offering: details.offering,
          price: details.price,
          rating: details.rating,
          level: details.level,
          language: details.language,
          grade: details.grade,
          publisher: details.publication,
          location: details.location,
          gradeSubject: details.gradeSubject,
        },
      }),
    }),
  }),
});

export const {
  useSearchMutation,
  useSearchOnFilterMutation,
  useSubCategoryFilterMutation,
  useSearchOnFilterWithPageMutation,
} = SearchApi;
