/* eslint-disable no-unused-vars */
import { Button, Box, TextField, MenuItem } from '@mui/material';
import React from 'react';
import { useSnackbar } from 'notistack';
import { useCreateTestSubjectMutation } from '../../../../app/services/TestsApi';

function CreateNewTestSubject(props) {
  const { testId } = props;

  const { enqueueSnackbar } = useSnackbar();

  const [createTestSubject, result] = useCreateTestSubjectMutation();

  const validateData = (testSubjectData) => {
    let err = true;

    if (!testSubjectData.title) {
      enqueueSnackbar('Please provide all the required fields', {
        variant: 'error',
      });
    } else {
      err = false;
    }

    return err;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const testSubjectData = {
      test_id: testId,
      title: data.get('title'),
      description: data.get('description'),
    };

    const res = validateData(testSubjectData);
    if (!res) {
      createTestSubject(testSubjectData);
    }
  };

  return result.data ? (
    result.data.message
  ) : (
    <>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="title"
          label=" Title"
          name="title"
          autoComplete="title"
          autoFocus
        />
        <TextField
          margin="normal"
          fullWidth
          id="description"
          label="Description"
          name="description"
          autoComplete="description"
          autoFocus
          multiline
          rows="2"
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={result.isLoading || result.isSuccess}
        >
          Add Subject
        </Button>
      </Box>
    </>
  );
}
export default CreateNewTestSubject;
