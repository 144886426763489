/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BaseApi } from './BaseApi';

const API_URL = `${process.env.REACT_APP_BACKEND_API_PATH}/api`;
const LOCALSTORAGE_APP_KEY = `${process.env.REACT_APP_LOCALSTORAGE_KEY}`;

export const categoriesApi = BaseApi.injectEndpoints({
  reducerPath: 'categoriesApi',
  endpoints: (builder) => ({
    categories: builder.query({
      query: () => ({
        url: '/categories',
        method: 'GET',
      }),
      providesTags: ['Category'],
    }),
    publishedCategories: builder.query({
      query: () => ({
        url: '/public/categories',
        method: 'GET',
      }),
    }),
    category: builder.query({
      query: (details) => ({
        url: `/category/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['Category'],
    }),
    createCategory: builder.mutation({
      query: (details) => ({
        url: '/category/create',
        method: 'POST',

        body: {
          title: details.title,
        },
      }),
      invalidatesTags: ['Category'],
    }),
    updateCategorySatus: builder.mutation({
      query: (details) => ({
        url: '/category/status/update',
        method: 'POST',

        body: {
          status: details.status,
          category_id: details.id,
        },
      }),
      invalidatesTags: ['Category'],
    }),
    deleteCategory: builder.mutation({
      query: (details) => ({
        url: `/category/delete/${details.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Category'],
    }),
    createSubCategory: builder.mutation({
      query: (details) => ({
        url: '/subcategory/create',
        method: 'POST',

        body: {
          title: details.title,
          category_id: details.categoryId,
        },
      }),
      invalidatesTags: ['Category'],
    }),
    subCategory: builder.query({
      query: (details) => ({
        url: `/subcategory/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['SubCategory'],
    }),
    updateSubCategorySatus: builder.mutation({
      query: (details) => ({
        url: '/subcategory/status/update',
        method: 'POST',

        body: {
          status: details.status,
          sub_category_id: details.id,
        },
      }),
      invalidatesTags: ['Category'],
    }),
    deleteSubCategory: builder.mutation({
      query: (details) => ({
        url: `/subcategory/delete/${details.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Category'],
    }),
    publicCategory: builder.query({
      query: (details) => ({
        url: `/public/category/show/${details.id}`,
        method: 'GET',
      }),
    }),
    publicSubCategory: builder.query({
      query: (details) => ({
        url: `/public/category/subCategory/show/${details.id}`,
        method: 'GET',
      }),
    }),
    grades: builder.query({
      query: () => ({
        url: '/grades',
        method: 'GET',
      }),
      providesTags: ['Grade'],
    }),
    gradeSubjects: builder.query({
      query: () => ({
        url: '/gradeSubjects',
        method: 'GET',
      }),
      providesTags: ['Grade Subject'],
    }),
    publications: builder.query({
      query: () => ({
        url: '/publications',
        method: 'GET',
      }),
      providesTags: ['Publication'],
    }),

    createGrade: builder.mutation({
      query: (details) => ({
        url: '/grade/create',
        method: 'POST',

        body: {
          title: details.title,
          sub_category_id: details.sub_category_id,
        },
      }),
      invalidatesTags: ['Grade'],
    }),
    createGradeSubject: builder.mutation({
      query: (details) => ({
        url: '/grade/subject/create',
        method: 'POST',

        body: {
          title: details.title,
          grade_id: details.gradeId,
        },
      }),
      invalidatesTags: ['Grade'],
    }),
    createPublication: builder.mutation({
      query: (details) => ({
        url: '/publication/create',
        method: 'POST',

        body: {
          title: details.title,
        },
      }),
      invalidatesTags: ['Publication'],
    }),
    deleteGrade: builder.mutation({
      query: (details) => ({
        url: `/grade/delete/${details.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Grade'],
    }),
    deleteGradeSubject: builder.mutation({
      query: (details) => ({
        url: `/grade/subject/delete/${details.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Grade'],
    }),
    deletePublication: builder.mutation({
      query: (details) => ({
        url: `/publication/delete/${details.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Publication'],
    }),
    grade: builder.query({
      query: (details) => ({
        url: `/grade/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['Grade'],
    }),
    gradeSubject: builder.query({
      query: (details) => ({
        url: `/grade/subject/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['Grade Subject'],
    }),
    publication: builder.query({
      query: (details) => ({
        url: `/publication/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['Publication'],
    }),

    publicPublications: builder.query({
      query: () => ({
        url: '/public/publications',
        method: 'GET',
      }),
      providesTags: ['Publication'],
    }),

    publicationEdition: builder.query({
      query: (details) => ({
        url: `/publication/edition/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['Publication Edition'],
    }),
    publicationEditions: builder.query({
      query: () => ({
        url: '/publication/editions',
        method: 'GET',
      }),
      providesTags: ['Publication Edition'],
    }),
    createPublicationEdition: builder.mutation({
      query: (details) => ({
        url: '/publication/edition/create',
        method: 'POST',

        body: {
          edition: details.edition,
          publication_id: details.publicationId,
        },
      }),
      invalidatesTags: ['Publication'],
    }),
    deletePublicationEdition: builder.mutation({
      query: (details) => ({
        url: `/publication/edition/delete/${details.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Publication'],
    }),
  }),
});

export const {
  useCategoriesQuery,
  usePublishedCategoriesQuery,
  useCategoryQuery,
  useCreateCategoryMutation,
  useUpdateCategorySatusMutation,
  useDeleteCategoryMutation,
  useCreateSubCategoryMutation,
  useSubCategoryQuery,
  useUpdateSubCategorySatusMutation,
  useDeleteSubCategoryMutation,
  usePublicCategoryQuery,
  usePublicSubCategoryQuery,
  useGradesQuery,
  useGradeQuery,
  useCreateGradeMutation,
  useDeleteGradeMutation,
  useGradeSubjectsQuery,
  useGradeSubjectQuery,
  useCreateGradeSubjectMutation,
  useDeleteGradeSubjectMutation,
  usePublicationsQuery,
  usePublicationQuery,
  useCreatePublicationMutation,
  useDeletePublicationMutation,
  usePublicPublicationsQuery,
  usePublicationEditionsQuery,
  usePublicationEditionQuery,
  useCreatePublicationEditionMutation,
  useDeletePublicationEditionMutation,
} = categoriesApi;
