/* eslint-disable no-unused-vars */
import {
  Button,
  Box,
  TextField,
  MenuItem,
  InputAdornment,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import moment from 'moment';
import { useState } from 'react';

import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useSnackbar } from 'notistack';
import MomentUtils from '@date-io/moment';
import { usePublishedCategoriesQuery } from '../../../app/services/CategoriesApi';
import { useUpdateTestMutation } from '../../../app/services/TestsApi';
function UpdateTest(props) {
  const { test } = props;
  const { data, isLoading, isSuccess } = usePublishedCategoriesQuery();

  const { enqueueSnackbar } = useSnackbar();

  const [updateTest, result] = useUpdateTestMutation();

  const [categoryTitle, setCategoryTitle] = useState(
    test.sub_category.category.id || ''
  );

  const [subCategoryTitle, setSubCategoryTitle] = useState(
    test.sub_category.id || ''
  );

  const [selectedCategory, setSelectedCategory] = useState(
    test.sub_category.category || {
      title: '',
      id: null,
      published_sub_categories: [],
    }
  );
  const [selectedSubCategory, setSelectedSubCategory] = useState(
    test.sub_category.category || {
      title: '',
      id: null,
    }
  );

  const [level, setLevel] = useState(test.level);

  const handleLevelChange = (event) => {
    setLevel(event.target.value);
  };

  const validateData = (testData) => {
    let err = true;

    if (!testData.title || !testData.sub_category_id || !testData.language) {
      enqueueSnackbar('Please provide all the required fields', {
        variant: 'error',
      });
    } else {
      err = false;
    }

    return err;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const testData = {
      id: test.id,
      title: data.get('title'),
      description: data.get('description'),
      language: data.get('language'),
      sub_category_id: selectedSubCategory.id,
      cost: data.get('cost'),
      level: level,
    };
    const res = validateData(testData);
    if (!res) {
      updateTest(testData);
    }
  };
  const handleCategoryChange = (event) => {
    const tempSelectedCategory = data.categories.find(
      (singleCategory) => singleCategory.id === event.target.value
    );
    setSelectedCategory(tempSelectedCategory);
    setCategoryTitle(event.target.value);
  };

  const handleSubCategoryChange = (event) => {
    const tempSelectedSubCategory = selectedCategory.published_sub_categories.find(
      (singleSubCategory) => singleSubCategory.id === event.target.value
    );

    setSelectedSubCategory(tempSelectedSubCategory);
    setSubCategoryTitle(event.target.value);
  };

  return result.data ? (
    result.data.message
  ) : (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="title"
        label=" Title"
        name="title"
        autoComplete="title"
        autoFocus
        defaultValue={test.title}
      />
      <TextField
        margin="normal"
        fullWidth
        id="description"
        label="Description"
        name="description"
        autoComplete="description"
        autoFocus
        multiline
        rows="2"
        defaultValue={test.description}
      />

      <TextField
        margin="normal"
        select
        required
        fullWidth
        id="category"
        label="Category"
        onChange={handleCategoryChange}
        name="category"
        autoComplete="category"
        autoFocus
        value={categoryTitle}
      >
        {data.categories && data.categories.length
          ? data.categories.map((singleCategory) => (
              <MenuItem value={singleCategory.id} key={singleCategory.id}>
                {singleCategory.title}
              </MenuItem>
            ))
          : null}
      </TextField>
      <TextField
        margin="normal"
        select
        required
        fullWidth
        id="sub_category"
        label="Sub Category"
        onChange={handleSubCategoryChange}
        name="sub_category"
        autoComplete="sub_category"
        autoFocus
        value={subCategoryTitle}
      >
        {selectedCategory && selectedCategory.published_sub_categories.length
          ? selectedCategory.published_sub_categories.map((singleSubCategory) => (
              <MenuItem value={singleSubCategory.id} key={singleSubCategory.id}>
                {singleSubCategory.title}
              </MenuItem>
            ))
          : null}
      </TextField>

      <TextField
        margin="normal"
        required
        fullWidth
        id="language"
        label="Language"
        name="language"
        autoComplete="language"
        defaultValue={test.language}
        autoFocus
      />

      <FormControl>
        <FormLabel id="demo-radio-buttons-group-label">Learning Level</FormLabel>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={level}
          onChange={handleLevelChange}
        >
          <FormControlLabel value="beginner" control={<Radio />} label="Beginner" />
          <FormControlLabel
            value="intermediate"
            control={<Radio />}
            label="Intermediate"
          />
          <FormControlLabel value="expert" control={<Radio />} label="Expert" />
        </RadioGroup>
      </FormControl>

      <TextField
        margin="normal"
        // required
        fullWidth
        id="cost"
        label="Cost"
        name="cost"
        autoComplete="cost"
        type="number"
        autoFocus
        InputProps={{
          startAdornment: <InputAdornment position="start">₹</InputAdornment>,
        }}
        defaultValue={test.cost}
      />

      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        disabled={result.isLoading || result.isSuccess}
      >
        Update test
      </Button>
    </Box>
  );
}

export default UpdateTest;
