import { Button, Box, TextField, MenuItem } from '@mui/material';
import { useState } from 'react';
import {
  useCreateGradeMutation,
  usePublishedCategoriesQuery,
} from '../../../app/services/CategoriesApi';

function CreateNewGrade() {
  const [createGrade, result] = useCreateGradeMutation();

  // eslint-disable-next-line no-unused-vars
  const { data, isLoading, isSuccess } = usePublishedCategoriesQuery();

  const [categoryTitle, setCategoryTitle] = useState('');

  const [subCategoryTitle, setSubCategoryTitle] = useState('');

  const [selectedCategory, setSelectedCategory] = useState({
    title: '',
    id: null,
    published_sub_categories: [],
  });
  const [selectedSubCategory, setSelectedSubCategory] = useState({
    title: '',
    id: null,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const gradeData = {
      title: data.get('title'),
      sub_category_id: selectedSubCategory.id,
    };

    await createGrade(gradeData);
  };

  const handleCategoryChange = (event) => {
    const tempSelectedCategory = data.categories.find(
      (singleCategory) => singleCategory.id === event.target.value
    );
    setSelectedCategory(tempSelectedCategory);
    setCategoryTitle(event.target.value);
  };

  const handleSubCategoryChange = (event) => {
    const tempSelectedSubCategory = selectedCategory.published_sub_categories.find(
      (singleSubCategory) => singleSubCategory.id === event.target.value
    );

    setSelectedSubCategory(tempSelectedSubCategory);
    setSubCategoryTitle(event.target.value);
  };

  return result.data ? (
    result.data.message
  ) : (
    <>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="title"
          label="Grade Title"
          name="title"
          autoComplete="title"
          autoFocus
        />

        <TextField
          margin="normal"
          select
          required
          fullWidth
          id="category"
          label="Category"
          onChange={handleCategoryChange}
          name="category"
          autoComplete="category"
          autoFocus
          value={categoryTitle}
        >
          {data.categories && data.categories.length
            ? data.categories.map((singleCategory) => (
                <MenuItem value={singleCategory.id} key={singleCategory.id}>
                  {singleCategory.title}
                </MenuItem>
              ))
            : null}
        </TextField>
        <TextField
          margin="normal"
          select
          required
          fullWidth
          id="sub_category"
          label="Sub Category"
          onChange={handleSubCategoryChange}
          name="sub_category"
          autoComplete="sub_category"
          autoFocus
          value={subCategoryTitle}
        >
          {selectedCategory && selectedCategory.published_sub_categories.length
            ? selectedCategory.published_sub_categories.map((singleSubCategory) => (
                <MenuItem value={singleSubCategory.id} key={singleSubCategory.id}>
                  {singleSubCategory.title}
                </MenuItem>
              ))
            : null}
        </TextField>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={result.isLoading || result.isSuccess}
        >
          Add Grade
        </Button>
      </Box>
    </>
  );
}

export default CreateNewGrade;
