/* eslint-disable no-unused-vars */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Collapse,
  Divider,
  IconButton,
  InputAdornment,
  Link,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Rating,
  TextField,
  Typography,
} from '@mui/material';
import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useSnackbar } from 'notistack';
import { useCreateVideoCommentMutation } from '../../../app/services/StudentCoursesApi';
import CommentReply from './CommentReply';
import QuickreplyOutlinedIcon from '@mui/icons-material/QuickreplyOutlined';

function DisplayVideoComments(props) {
  const { comments, video_id } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [createComment, result] = useCreateVideoCommentMutation();
  const [commentBox, setCommentBox] = React.useState(false);

  const [expandedId, setExpandedId] = React.useState(-1);

  const handleClick = (i) => {
    setExpandedId(expandedId === i ? -1 : i);
  };

  const validateData = (commentData) => {
    let err = true;

    if (!commentData.commentText) {
      enqueueSnackbar('Please provide comment in some words', {
        variant: 'error',
      });
    } else {
      err = false;
    }

    return err;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const commentData = {
      commentText: data.get('commentText'),
      video_id: video_id,
    };

    const res = validateData(commentData);
    if (!res) {
      createComment(commentData);
      setCommentBox(false);
    }
  };

  const recursiveComments = (comment) => {
    if (comment.reply_comments.length >= 1) {
      return comment.reply_comments.map((singleReplyComment, index) => (
        <Box
          sx={{
            ml: 2,
            // my: 1,
            // mx: 'auto',
            pt: 2,
          }}
          key={singleReplyComment.id}
        >
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item>
              <Avatar>
                {singleReplyComment.comment_creator.name.charAt(0).toUpperCase()}
              </Avatar>
            </Grid>
            <Grid item xs>
              <Link>{singleReplyComment.comment_creator.name}</Link>

              <Typography>{singleReplyComment.comment}</Typography>
            </Grid>
            <Grid
              item
              sx={{
                justifyContent: 'right',
                // display: 'block',
              }}
            >
              <InputAdornment position="end">
                <IconButton onClick={() => handleClick(singleReplyComment.id)}>
                  <QuickreplyOutlinedIcon />
                </IconButton>
              </InputAdornment>
            </Grid>
          </Grid>
          <Divider sx={{ border: '1px solid yellow' }} />
          <Collapse
            in={expandedId === singleReplyComment.id}
            timeout="auto"
            unmountOnExit
            sx={{ width: ' -webkit-fill-available' }}
          >
            <CommentReply commentId={singleReplyComment.id} />
          </Collapse>
          {recursiveComments(singleReplyComment)}
        </Box>
      ));
    } else {
      return null;
    }
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs="12" sm="12" md="12" lg="12" xl="12" sx={{ display: 'flex' }}>
          <Button onClick={() => setCommentBox(false)}>Comments</Button>
          <Button onClick={() => setCommentBox(true)}>New Comment</Button>
        </Grid>
      </Grid>
      {commentBox ? (
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="commentText"
            label=" Your Comment"
            name="commentText"
            autoComplete="commentText"
            multiline
            rows="2"
            autoFocus
          />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            Submit Comment
          </Button>
        </Box>
      ) : null}
      {comments.length >= 1
        ? comments.map((singleComment, index) => (
            <Paper
              sx={{
                my: 1,
                mx: 'auto',
                p: 2,
              }}
              key={singleComment.id}
            >
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item>
                  <Avatar>
                    {singleComment.comment_creator.name.charAt(0).toUpperCase()}
                  </Avatar>
                </Grid>
                <Grid item xs>
                  <Link>{singleComment.comment_creator.name}</Link>

                  <Typography>{singleComment.comment}</Typography>
                </Grid>
                <Grid
                  item
                  sx={{
                    justifyContent: 'right',
                    display: 'block',
                  }}
                >
                  {/* <Box
                    sx={{
                      justifyContent: 'flex-end',
                      display: 'flex',
                    }}
                  > */}

                  <InputAdornment
                    position="end"
                    sx={{
                      textTransform: 'capitalize',
                      // display: expandedId === singleComment.id ? 'none' : 'block',
                    }}
                  >
                    <IconButton onClick={() => handleClick(singleComment.id)}>
                      <QuickreplyOutlinedIcon />
                    </IconButton>
                  </InputAdornment>
                </Grid>
              </Grid>

              <Collapse
                in={expandedId === singleComment.id}
                timeout="auto"
                unmountOnExit
                sx={{ width: ' -webkit-fill-available' }}
              >
                <CommentReply commentId={singleComment.id} />
              </Collapse>
              {recursiveComments(singleComment)}
            </Paper>
          ))
        : 'No comments found'}
    </>
  );
}

export default DisplayVideoComments;
