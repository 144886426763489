/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BaseApi } from './BaseApi';

const API_URL = `${process.env.REACT_APP_BACKEND_API_PATH}/api`;
const LOCALSTORAGE_APP_KEY = `${process.env.REACT_APP_LOCALSTORAGE_KEY}`;

export const studentCoursesApi = BaseApi.injectEndpoints({
  reducerPath: 'studentCoursesApi',
  endpoints: (builder) => ({
    courseEnrol: builder.mutation({
      query: (details) => ({
        url: '/course/enrol/create',
        method: 'POST',
        body: {
          course_id: details.course_id,
        },
      }),
    }),
    enroledCourses: builder.query({
      query: (token) => ({
        url: '/course/enrols',
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    enroledCourse: builder.query({
      query: (details) => ({
        url: `/course/enrol/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['Course Enrol'],
    }),
    createCourseReview: builder.mutation({
      query: (details) => ({
        url: '/course/review/create',
        method: 'POST',
        body: {
          rating: details.rating,
          review_text: details.reviewText,
          course_id: details.course_id,
        },
      }),
      invalidatesTags: ['Course Enrol'],
    }),
    getEnroledVideoUrl: builder.query({
      query: (details) => ({
        // url: `/enroled/course/video/show/${details.id}`,
        url: `/enroled/course/${details.id}/video/show/${details.videoId}`,
        method: 'GET',
      }),
      providesTags: ['Course Enrol'],
    }),
    getEnroledSubTopicVideoUrl: builder.query({
      query: (details) => ({
        url: `/enroled/course/subTopic/video/show/${details.id}`,
        method: 'GET',
      }),
    }),
    getEnroledSubjectVideoUrl: builder.query({
      query: (details) => ({
        url: `/enroled/course/subject/video/show/${details.id}`,
        method: 'GET',
      }),
    }),
    getEnroledTopicVideoUrl: builder.query({
      query: (details) => ({
        url: `/enroled/course/topic/video/show/${details.id}`,
        method: 'GET',
      }),
    }),
    followUser: builder.mutation({
      query: (details) => ({
        url: '/follow/user',
        method: 'POST',
        body: {
          id_to_follow: details.id,
        },
      }),
      invalidatesTags: ['Course Enrol'],
    }),
    unFollowCreatorUser: builder.mutation({
      query: (details) => ({
        url: `/unfollow/user/${details.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Course Enrol'],
    }),
    createVideoComment: builder.mutation({
      query: (details) => ({
        url: '/video/comment/create',
        method: 'POST',
        body: {
          comment: details.commentText,
          video_id: details.video_id,
          comment_id: details.comment_id,
        },
      }),
      invalidatesTags: ['Course Enrol'],
    }),
    updateVideoProgress: builder.mutation({
      query: (details) => ({
        url: '/course/enrol/video/progress',
        method: 'POST',
        body: {
          enrol_id: details.enrol_id,
          video_progress: details.vidProgress,
        },
      }),
      invalidatesTags: ['Course Enrol'],
    }),
    createCourseViolation: builder.mutation({
      query: (details) => ({
        url: '/course/violation/create',
        method: 'POST',
        body: {
          course_id: details.course_id,
          issue_type: details.issue_type,
          issue_details: details.issue_details,
        },
      }),
      invalidatesTags: ['Course Violation'],
    }),
    createVideoViolation: builder.mutation({
      query: (details) => ({
        url: '/video/violation/create',
        method: 'POST',
        body: {
          video_id: details.video_id,
          issue_type: details.issue_type,
          issue_details: details.issue_details,
        },
      }),
      invalidatesTags: ['Video Violation'],
    }),
  }),
});

export const {
  useCourseEnrolMutation,
  useEnroledCoursesQuery,
  useEnroledCourseQuery,
  useCreateCourseReviewMutation,
  useGetEnroledVideoUrlQuery,
  useGetEnroledSubTopicVideoUrlQuery,
  useGetEnroledSubjectVideoUrlQuery,
  useGetEnroledTopicVideoUrlQuery,
  useFollowUserMutation,
  useUnFollowCreatorUserMutation,
  useCreateVideoCommentMutation,
  useUpdateVideoProgressMutation,
  useCreateCourseViolationMutation,
  useCreateVideoViolationMutation,
} = studentCoursesApi;
