/* eslint-disable no-unused-vars */
import { Box, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useGetTopicVideoJobStatusMutation } from '../../../../../app/services/CoursesApi';

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

function TopicVideoProgressRequest(props) {
  const { uploadProcessProgress, setUploadProcessProgress, id } = props;
  let interval = null;
  const [progress, setProgress] = useState(uploadProcessProgress);
  const [getVideoJobStatus, result] = useGetTopicVideoJobStatusMutation();

  useEffect(() => {
    if (uploadProcessProgress === 100 && interval) {
      clearInterval(interval);
    }
  }, [progress]);

  useEffect(() => {
    interval = setInterval(function () {
      getVideoJobStatus({
        id: id,
      }).then((res) => {
        if (res.data.video.status === 'COMPLETED') {
          clearInterval(interval);
          setProgress(100);
          setUploadProcessProgress(100);
        }
        if (res.data.video.status === 'SUBMITTED') {
          setProgress(60);
          setUploadProcessProgress(60);
        }
        if (res.data.video.status === 'PROGRESSING') {
          setProgress(80);
          setUploadProcessProgress(80);
        }
      });
    }, 1 * 30 * 1000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  return (
    <>
      {uploadProcessProgress > 0 ? (
        <Box sx={{ width: '100%' }}>
          <LinearProgressWithLabel value={progress} color="secondary" />
        </Box>
      ) : null}
    </>
  );
}

export default TopicVideoProgressRequest;
