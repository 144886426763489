import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  Grid,
  CardContent,
} from '@mui/material';
import React from 'react';
import { useParams } from 'react-router';
import { useGradeQuery } from '../../../app/services/CategoriesApi';
import DataLoader from '../../../components/DataLoader';
import CreateNewGradeSubject from './CreateNewGradeSubject';
import GradeSubjectsList from './GradeSubjectsList';

function SingleGrade() {
  const params = useParams();

  const { data, isLoading, isSuccess } = useGradeQuery({
    id: parseInt(params.gradeId, 10),
  });
  const [open, setOpen] = React.useState(false);

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {isSuccess && data.grade ? (
        <>
          <div style={{ textAlign: 'center' }}>
            <Button variant="contained" onClick={handleClickOpen}>
              Create New Grade Subject
            </Button>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle> Create New Grade Subject</DialogTitle>
              <DialogContent>
                <CreateNewGradeSubject gradeId={data.grade.id} />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Close</Button>
              </DialogActions>
            </Dialog>
          </div>
          <Grid container spacing={1}>
            <Grid item xs="12" sm="12" md="1" lg="1" xl="1"></Grid>
            <Grid item xs="12" sm="12" md="8" lg="8" xl="8">
              <Card>
                <CardContent>
                  <Typography component="h2" variant="h5">
                    Title: {data.grade.title}
                  </Typography>
                  <Typography>
                    Category: {data.grade.sub_category.category.title}
                  </Typography>
                  <Typography>
                    Sub Category: {data.grade.sub_category.title}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          {data.grade.grade_subjects && data.grade.grade_subjects.length ? (
            <GradeSubjectsList gradeSubjects={data.grade.grade_subjects} />
          ) : (
            <p>No Grade Subjects Found</p>
          )}
        </>
      ) : (
        <p>No Grade found</p>
      )}
    </>
  );
}

export default SingleGrade;
