/* eslint-disable no-unused-vars */
import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  Grid,
  Box,
} from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useTestTopicQuery } from '../../../../../app/services/TestsApi';
import DataLoader from '../../../../../components/DataLoader';
import CreateNewTestQuestion from './testQuestions/CreateNewTestQuestion';
import TestQuestionsList from './testQuestions/TestQuestionsList';
import UpdateTestTopic from './UpdateTestTopic';

function SingleTestTopic() {
  const params = useParams();

  const { data, isLoading, isSuccess } = useTestTopicQuery({
    id: parseInt(params.testTopicId, 10),
  });

  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  return (
    <>
      {isSuccess && data.testTopic ? (
        <>
          <Grid container spacing={1}>
            <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
              <Box sx={{ textAlign: 'center' }}>
                <Button variant="contained" onClick={handleClickOpen1}>
                  Update Test Topic
                </Button>
                <>
                  <Dialog
                    open={open1}
                    onClose={handleClose1}
                    fullWidth
                    maxWidth="sm"
                  >
                    <DialogTitle> Update Test Topic</DialogTitle>
                    <DialogContent>
                      <UpdateTestTopic testTopic={data.testTopic} />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose1}>Close</Button>
                    </DialogActions>
                  </Dialog>
                </>
                &nbsp;
                <Button variant="contained" onClick={handleClickOpen}>
                  Create New Test Question
                </Button>
                <>
                  <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                    <DialogTitle> Create New Test Question</DialogTitle>
                    <DialogContent>
                      <CreateNewTestQuestion testTopicId={data.testTopic.id} />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose}>Close</Button>
                    </DialogActions>
                  </Dialog>
                </>
              </Box>
            </Grid>
            <Grid item xs="12" sm="12" md="8" lg="8" xl="8" align="left">
              <Card>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  sx={{ padding: 4, color: '#233c8d' }}
                >
                  Title: {data.testTopic.title}
                  <br />
                  Description: {data.testTopic.description}
                </Typography>
              </Card>
            </Grid>
            <Grid item xs="12" sm="12" md="4" lg="4" xl="4" align="left">
              {data.testTopic.test_questions &&
              data.testTopic.test_questions.length ? (
                <TestQuestionsList testQuestions={data.testTopic.test_questions} />
              ) : (
                <p>No Test Questions Found</p>
              )}
            </Grid>
          </Grid>
        </>
      ) : (
        <p>No Test Topic found</p>
      )}
    </>
  );
}

export default SingleTestTopic;
