// /* eslint-disable no-unused-vars */
// import { styled } from '@mui/material/styles';
// import { alpha } from '@mui/material/styles';
// import SearchIcon from '@mui/icons-material/Search';
// import {
//   Autocomplete,
//   IconButton,
//   InputAdornment,
//   InputBase,
//   TextField,
// } from '@mui/material';
// import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router';
// import { createSearchParams } from 'react-router-dom';
// import { useState } from 'react';
// import { usePublishedCategoriesQuery } from '../app/services/CategoriesApi';
// import { usePubliCoursesQuery } from '../app/services/PublicCoursesApi';
// import { usePubliExamsQuery } from '../app/services/PublicExamsApi';
// import { usePubliTestsQuery } from '../app/services/PublicTestsApi';

// const Search = styled('div')(({ theme }) => ({
//   flex: 1,
//   position: 'relative',
//   borderRadius: theme.shape.borderRadius,
//   backgroundColor: alpha(theme.palette.common.white, 0.65),
//   '&:hover': {
//     backgroundColor: alpha(theme.palette.common.white, 0.75),
//   },
//   // marginRight: theme.spacing(2),
//   marginLeft: 0,
//   width: '100%',
//   [theme.breakpoints.up('sm')]: {
//     marginLeft: theme.spacing(3),
//     width: 'auto',
//     maxWidth: '800px',
//   },
// }));

// function SearchBar() {
//   const { data, isLoading, isSuccess } = usePubliCoursesQuery();
//   const response = usePubliExamsQuery();
//   const testsResponse = usePubliTestsQuery();

//   const [selectedCourse, setSelectedCourse] = useState({});
//   const navigate = useNavigate();
//   const [inputValue, setInputValue] = useState('');

//   const onSearch = (selected) => {
//     if (selected.type === 'course') {
//       navigate(
//         `/${selected.sub_category.category.title}/${selected.sub_category.title}/course/${selected.id}`
//       );
//     } else if (selected.type === 'exam') {
//       navigate(
//         `/${selected.sub_category.category.title}/${selected.sub_category.title}/exam/${selected.id}`
//       );
//     } else {
//       navigate(
//         `/${selected.sub_category.category.title}/${selected.sub_category.title}/test/${selected.id}`
//       );
//     }
//   };

//   const onSearchClick = () => {
//     navigate({
//       pathname: 'search',
//       search: createSearchParams({
//         q: inputValue,
//       }).toString(),
//     });
//   };

//   const searchOptions = () => {};

//   return (
//     <Search>
//       <Autocomplete
//         freeSolo
//         size="small"
//         open={inputValue.length >= 1}
//         disableClearable
//         options={
//           isSuccess && response.isSuccess && testsResponse.isSuccess
//             ? data.courses.concat(response.data.exams, testsResponse.data.tests)
//             : []
//         }
//         onChange={(_event, newValue) => {
//           onSearch(newValue);
//         }}
//         onInputChange={(event, newInputValue) => {
//           if (event.type === 'keydown' || event.type === 'change') {
//             setInputValue(newInputValue);
//           }
//         }}
//         getOptionLabel={(option) => `${option.title} ${option.type}`}
//         onKeyDown={(event) => {
//           if (event.key === 'Enter') {
//             event.defaultMuiPrevented = true;
//             selectedCourse ? onSearch(setSelectedCourse) : onSearchClick();
//           }
//         }}
//         onHighlightChange={(event, option, reason) => {
//           setSelectedCourse(option);
//         }}
//         renderInput={(params) => (
//           <TextField
//             {...params}
//             placeholder="Search courses… exams... practice tests..."
//             required
//             InputProps={{
//               ...params.InputProps,
//               type: 'search',
//               endAdornment: (
//                 <InputAdornment position="end">
//                   <IconButton onClick={() => onSearchClick()}>
//                     <SearchIcon />
//                   </IconButton>
//                 </InputAdornment>
//               ),
//             }}
//           />
//         )}
//       />
//     </Search>
//   );
// }

// export default SearchBar;

/* eslint-disable no-unused-vars */
import { styled } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import {
  Autocomplete,
  IconButton,
  InputAdornment,
  InputBase,
  TextField,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { usePublishedCategoriesQuery } from '../app/services/CategoriesApi';
import { usePubliCoursesQuery } from '../app/services/PublicCoursesApi';
import { usePubliExamsQuery } from '../app/services/PublicExamsApi';
import { usePubliTestsQuery } from '../app/services/PublicTestsApi';
import { useKeywordsQuery } from '../app/services/KeywordsApi';

const Search = styled('div')(({ theme }) => ({
  flex: 1,
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.65),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.75),
  },
  // marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
    maxWidth: '800px',
  },
}));

function SearchBar() {
  const { data, isLoading, isSuccess } = useKeywordsQuery();
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    searchParams.get('q') ? setInputValue(searchParams.get('q')) : null;
  }, [searchParams.get('q')]);

  const onSearchClick = (inputSearchValue) => {
    navigate({
      pathname: 'search',
      search: createSearchParams({
        q: inputSearchValue,
      }).toString(),
    });
  };

  const searchOptions = () => {};

  return (
    <Search>
      <Autocomplete
        freeSolo
        size="small"
        inputValue={inputValue}
        disableClearable
        // options={isSuccess ? data.keywords : []}
        options={isSuccess ? data.keywords.map((option) => option.title) : []}
        onChange={(_event, newValue) => {
          onSearchClick(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        // getOptionLabel={(option) => `${option.title} `}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search courses… exams... practice tests..."
            required
            InputProps={{
              ...params.InputProps,
              type: 'search',
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => onSearchClick(inputValue)}
                    disabled={!inputValue.length}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </Search>
  );
}

export default SearchBar;
