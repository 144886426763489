import { Button, Box, TextField } from '@mui/material';
import { useCreateGradeSubjectMutation } from '../../../app/services/CategoriesApi';

function CreateNewGradeSubject(props) {
  const { gradeId } = props;
  const [createGradeSubject, result] = useCreateGradeSubjectMutation();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const gradeSubjectData = {
      title: data.get('title'),
      gradeId: gradeId,
    };

    await createGradeSubject(gradeSubjectData);
  };
  return result.data ? (
    result.data.message
  ) : (
    <>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="title"
          label="Grade Subject Title"
          name="title"
          autoComplete="title"
          autoFocus
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={result.isLoading || result.isSuccess}
        >
          Add Grade Subject
        </Button>
      </Box>
    </>
  );
}

export default CreateNewGradeSubject;
