/* eslint-disable no-unused-vars */
import { ExpandMore } from '@mui/icons-material';
import {
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  TablePagination,
} from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router';

function UsersList(props) {
  const { users, role } = props;
  const navigate = useNavigate();

  const [expandedId, setExpandedId] = React.useState(-1);

  const handleClick = (i) => {
    setExpandedId(expandedId === i ? -1 : i);
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const viewUser = (id) => {
    navigate(`/users/${id}`);
  };

  return (
    <>
      <Typography component="div" variant="h5" sx={{ padding: 4, color: '#233c8d' }}>
        {role}s
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone Numer</TableCell>
              <TableCell>Created On</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users &&
              users.length &&
              users
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((singleUser, index) => (
                  <TableRow
                    key={singleUser.id}
                    sx={{
                      '&:last-child td, &:last-child th': {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell>{singleUser.name}</TableCell>
                    <TableCell>{singleUser.email}</TableCell>
                    <TableCell>{singleUser.phone_number}</TableCell>
                    <TableCell>
                      {moment(singleUser.created_at).format('Do MMM YY hh:mm A')}
                    </TableCell>
                    <TableCell>
                      <List
                        sx={{
                          width: '100%',
                          maxWidth: 200,
                          bgcolor: 'background.paper',
                        }}
                      >
                        <ListItemButton
                          onClick={() => handleClick(index)}
                          aria-expanded={expandedId === index}
                        >
                          <ListItemText primary="Options" />
                          <ExpandMore />
                        </ListItemButton>
                        <Collapse
                          in={expandedId === index}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            <ListItemButton
                              sx={{ pl: 4 }}
                              onClick={() => viewUser(singleUser.id)}
                              disabled
                            >
                              <ListItemText primary="View" />
                            </ListItemButton>
                          </List>
                        </Collapse>
                      </List>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={users && users.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}

export default UsersList;
