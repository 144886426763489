import { Button, Box, TextField } from '@mui/material';
import { useCreatePublicationEditionMutation } from '../../../app/services/CategoriesApi';

function CreateNewPublicationEdition(props) {
  const { publicationId } = props;
  const [createPublicationEdition, result] = useCreatePublicationEditionMutation();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const publicationEditionData = {
      edition: data.get('edition'),
      publicationId: publicationId,
    };

    await createPublicationEdition(publicationEditionData);
  };
  return result.data ? (
    result.data.message
  ) : (
    <>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          // required
          fullWidth
          id="edition"
          label="Edition"
          name="edition"
          autoComplete="edition"
          type="number"
          autoFocus
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={result.isLoading || result.isSuccess}
        >
          Add Publication Edition
        </Button>
      </Box>
    </>
  );
}

export default CreateNewPublicationEdition;
